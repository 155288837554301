.capcha {
    display: block;
    border-radius: 8px;
    background-color: $primary;
    width: 100%;
    height: 56px;

    .main--registration & {
        order: 2;
        margin-top: 8px;
    }
}

@include media-breakpoint-up(sm) { 
    .capcha {
        height: 56px;
        width: 195px;
        

        .main--registration & {
            order: 1;
            margin-top: 0;
        }
        // height: 100%;
    }
}