@use "sass:color";
@use "sass:math";

//== foundational variables ==//
$black: hsl(0, 0%, 4%) !default;
$white: hsl(0, 0%, 100%) !default;
$light: hsl(0, 0%, 96%) !default; // white-ter
$dark: hsl(0, 0%, 21%) !default; // grey-darker
$link: $teal;

$grey-dark: color.scale($black, $lightness: 25%) !default;
$grey-light: color.scale($light, $lightness: -25%) !default;
$grey-lighter: color.scale($light, $lightness: -10%) !default;

//== datepicker variables ==//
$dp-background-color: $white !default;
$dp-border-color: $grey-lighter !default;
$dp-border-radius: 8px !default;
$dp-border-radius-bottom-half: 0 0 8px 8px !default;
$dp-border-radius-small: 2px !default;
$dp-line-height-base: 1.5 !default;
$dp-font-size-normal: 1rem !default;
$dp-font-size-small: 0.75rem !default;
$dp-font-weight-semibold: 600 !default;
$dp-font-weight-bold: 700 !default;
$dp-dropdown-offset: 4px !default;
$dp-dropdown-shadow: 0 2px 3px rgb($black, 0.1), 0 0 0 1px rgb($black, 0.1) !default;
$dp-dropdown-z: 20 !default;

$dp-title-background-color: $light !default;

$dp-cell-size-base: 2.25rem !default;
$dp-cell-focus-background-color: color.scale($light, $lightness: -5%) !default;
$dp-cell-prevnext-color: hsl(0, 0%, 48%) !default; // grey
$dp-cell-disabled-color: $grey-lighter !default;
$dp-cell-selected-background-color: $link !default;
$dp-cell-selected-color: #fff !default; // link(blue)-invert
$dp-cell-selected-font-weight: $dp-font-weight-semibold !default;
$dp-cell-today-background-color: hsl(
    171,
    100%,
    41%
) !default; // turquoise (primary)
$dp-cell-today-color: #fff !default; // turquoise-invert
$dp-cell-highlighted-background-color: $light !default;
$dp-range-start-end-background-color: $grey-light !default;
$dp-range-start-end-color: $dp-cell-selected-color !default;
$dp-range-background-color: $grey-lighter !default;
$dp-range-today-background-color: $dp-cell-today-background-color !default;
$dp-week-color: $grey-light !default;

$dp-footer-background-color: $light !default;

$dp-input-in-edit-border-color: color.scale($link, $lightness: -5%) !default;
$dp-input-in-edit-focus-box-shadow-size: 0 0 0.25em 0.25em !default;

//== calculated variables ==//
$dp-dow-height: $dp-font-size-normal * $dp-line-height-base !default;
$dp-dow-font-size: math.div(
    $dp-font-size-small + $dp-font-size-normal,
    2
) !default;
//$dp-cell-size-base * 7 !default;
$dp-view-width: 100%;
$dp-week-numbers-width: math.percentage(
    math.div(0.75, 0.75 + 1 * 7)
) !default; // 0.75 = small-font, 1 = nomal-font
$dp-month-year-height: $dp-cell-size-base * 2 !default;

$dp-header-hover-background-color: color.scale(
    $white,
    $lightness: -2.5%
) !default;
$dp-header-active-background-color: color.scale(
    $white,
    $lightness: -5%
) !default;
$dp-cell-hover-background-color: color.scale(
    $dp-background-color,
    $lightness: -2.5%
) !default;
$dp-cell-today-focus-background-color: color.scale(
    $dp-cell-today-background-color,
    $lightness: -2.5%
) !default;
$dp-cell-highlighted-hover-background-color: color.scale(
    $dp-cell-highlighted-background-color,
    $lightness: -2.5%
) !default;
$dp-cell-prevnext-selected-color: color.scale(
    $dp-cell-selected-color,
    $lightness: -10%
) !default;
$dp-range-start-end-focus-background-color: color.scale(
    $dp-range-start-end-background-color,
    $lightness: -2.5%
) !default;
$dp-range-hover-background-color: color.scale(
    $dp-range-background-color,
    $lightness: -2.5%
) !default;
$dp-range-focus-background-color: color.scale(
    $dp-range-background-color,
    $lightness: -5%
) !default;
$dp-range-disabled-background-color: color.scale(
    $dp-range-background-color,
    $lightness: -10%
) !default;

$dp-title-box-shadow: inset 0 -1px 1px rgb($black, 0.1) !default;
$dp-footer-box-shadow: inset 0 1px 1px rgb($black, 0.1) !default;
$dp-input-in-edit-box-shadow: $dp-input-in-edit-focus-box-shadow-size
    rgb($dp-input-in-edit-border-color, 0.2) !default;

//== non-configurable variables ==//
$dp-one-seventh: math.percentage(math.div(1, 7));

//== mixins ==//
@import "mixins.scss";

//== styles ==//
.datepicker {
    width: 100%;
    transition: opacity 0.2s, visibility 0.2s, z-index 0.2s ease-in-out;

    &:not(.active) {
        // display: none;
        z-index: -1;
        visibility: hidden;
        opacity: 0;
    }

    &.active {
        
        z-index: 10000;
        visibility: visible;
        opacity: 1;
    }
}

.datepicker-dropdown {
    position: absolute;
    z-index: $dp-dropdown-z;
    padding-top: $dp-dropdown-offset;

    &.datepicker-orient-top {
        padding-top: 0;
        padding-bottom: $dp-dropdown-offset;
    }
}

.datepicker-picker {
    display: flex;
    flex-direction: column;
    border-radius: $dp-border-radius;
    background-color: $dp-background-color;

    .datepicker-dropdown & {
        border: 2px solid $link;
        // box-shadow: $dp-dropdown-shadow;
    }
}

.datepicker-main {
    flex: auto;
    padding: 2px;
}

.datepicker-footer {
    box-shadow: $dp-footer-box-shadow;
    background-color: $dp-footer-background-color;
    border-radius: $dp-border-radius-bottom-half;
}

.datepicker-title {
    box-shadow: $dp-title-box-shadow;
    background-color: $dp-title-background-color;
    padding: 0.375rem 0.75rem;
    text-align: center;
    font-weight: $dp-font-weight-bold;
}

.datepicker-controls {
    display: flex;

    .datepicker-header & {
        padding: 2px 2px 0;
    }

    @if mixin-exists(dp-button) {
        @include dp-button;
    } @else {
        .button {
            $button-color: $dark;
            $button-background-color: $white;

            $button-border-color: $dp-border-color;
            $button-border-width: 1px;

            $button-padding-vertical: calc(0.375em - #{$button-border-width});
            $button-padding-horizontal: 0.75em;

            $button-hover-color: $dark; // link-hover
            $button-hover-border-color: $grey-light; // link-hover-border

            $button-focus-color: $dark; // link-focus
            $button-focus-border-color: $link; // link-focus-border
            $button-focus-box-shadow-size: 0 0 0 0.125em;
            $button-focus-box-shadow-color: rgb($link, 0.25);

            $button-active-color: $dark; // link-active
            $button-active-border-color: $grey-dark; // link-active-border

            display: inline-flex;
            position: relative;
            align-items: center;
            justify-content: center;
            margin: 0;
            border: $button-border-width solid $button-border-color;
            border-radius: $dp-border-radius; // control-radius
            box-shadow: none;
            background-color: $button-background-color;
            cursor: pointer;
            padding: $button-padding-vertical $button-padding-horizontal;
            height: 2.25em; // control-height
            vertical-align: top;
            text-align: center;
            line-height: $dp-line-height-base; // control-line-height
            white-space: nowrap;
            color: $button-color;
            font-size: $dp-font-size-normal; // size-normal

            &:focus,
            &:active {
                outline: none;
            }

            &:hover {
                border-color: $button-hover-border-color;
                color: $button-hover-color;
            }

            &:focus {
                border-color: $button-focus-border-color;
                color: $button-focus-color;

                &:not(:active) {
                    box-shadow: $button-focus-box-shadow-size
                        $button-focus-box-shadow-color;
                }
            }

            &:active {
                border-color: $button-active-border-color;
                color: $button-active-color;
            }

            &[disabled] {
                cursor: not-allowed;
            }

            .datepicker-header & {
                @include dp-header-button-common;

                &:hover {
                    background-color: $dp-header-hover-background-color;
                }

                &:active {
                    background-color: $dp-header-active-background-color;
                }
            }

            .datepicker-footer & {
                @include dp-footer-button-common;
            }
        }
    }

    .view-switch {
        flex: auto;
    }

    .prev-button,
    .next-button {
        padding-right: 0.375rem;
        padding-left: 0.375rem;
        flex: 0 0 $dp-one-seventh;

        &.disabled {
            visibility: hidden;
        }
    }
}

.datepicker-view,
.datepicker-grid {
    display: flex;
}

.datepicker-view {
    align-items: stretch;
    width: $dp-view-width;
}

.datepicker-grid {
    flex-wrap: wrap;
    flex: auto;
}

.datepicker {
    .days {
        display: flex;
        flex-direction: column;
        flex: auto;
    }

    .days-of-week {
        display: flex;
    }

    .week-numbers {
        display: flex;
        flex-direction: column;
        flex: 0 0 $dp-week-numbers-width;
    }

    .weeks {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        flex: auto;
    }

    span {
        @include font(14px, 20px, 600);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: $dp-border-radius;
        cursor: default;
        -webkit-touch-callout: none;
        user-select: none;
    }

    .dow {
        height: $dp-dow-height;
        font-size: $dp-dow-font-size;
        font-weight: $dp-font-weight-bold;
    }

    .days .dow {
        @extend %flex-basis-day;
    }

    .week {
        flex: auto;
        color: $dp-week-color;
        font-size: $dp-font-size-small;
    }
}

%flex-basis-day {
    flex-basis: $dp-one-seventh;
}

.datepicker-cell {
    @extend %flex-basis-day;

    height: $dp-cell-size-base;

    &:not(.day) {
        flex-basis: 25%;
        height: $dp-month-year-height;
    }

    &:not(.disabled):hover {
        background-color: $dp-cell-hover-background-color;
        cursor: pointer;
    }

    &.focused:not(.selected) {
        background-color: $dp-cell-focus-background-color;
    }

    &.selected {
        &,
        &:hover {
            background-color: $dp-cell-selected-background-color;
            color: $dp-cell-selected-color;
            font-weight: $dp-cell-selected-font-weight;
        }
    }

    &.disabled {
        color: $dp-cell-disabled-color;
    }

    &.prev,
    &.next {
        &:not(.disabled) {
            color: $dp-cell-prevnext-color;
        }

        &.selected {
            color: $dp-cell-prevnext-selected-color;
        }
    }

    &.highlighted:not(.selected):not(.range):not(.today) {
        border-radius: 0;
        background-color: $dp-cell-highlighted-background-color;

        &:not(.disabled):hover {
            background-color: $dp-cell-highlighted-hover-background-color;
        }

        &.focused {
            background-color: $dp-cell-focus-background-color;
        }
    }

    &.today {
        &:not(.selected) {
            background-color: $dp-cell-today-background-color;

            &:not(.disabled) {
                color: $dp-cell-today-color;
            }
        }

        &.focused:not(.selected) {
            background-color: $dp-cell-today-focus-background-color;
        }
    }

    &%range-start-end-common {
        background-color: $dp-range-start-end-background-color;
        color: $dp-range-start-end-color;
    }

    &%range-start-end-focused-common {
        background-color: $dp-range-start-end-focus-background-color;
    }

    &.range-start {
        &:not(.range-end) {
            border-radius: $dp-border-radius 0 0 $dp-border-radius;
        }

        &:not(.selected) {
            @extend %range-start-end-common;
        }

        &.focused:not(.selected) {
            @extend %range-start-end-focused-common;
        }
    }

    &.range-end {
        &:not(.range-start) {
            border-radius: 0 $dp-border-radius $dp-border-radius 0;
        }

        &:not(.selected) {
            @extend %range-start-end-common;
        }

        &.focused:not(.selected) {
            @extend %range-start-end-focused-common;
        }
    }

    &.range {
        border-radius: 0;
        background-color: $dp-range-background-color;

        &:not(.disabled):not(.focused):not(.today):hover {
            background-color: $dp-range-hover-background-color;
        }

        &.disabled {
            color: $dp-range-disabled-background-color;
        }

        &.focused {
            background-color: $dp-range-focus-background-color;
        }

        @if $dp-range-today-background-color != $dp-cell-today-background-color
        {
            &.today {
                background-color: $dp-range-today-background-color;
            }
        }
    }
}

.datepicker-input {
    caret-color: transparent;
    cursor: pointer;

    &.in-edit {
        border-color: $dp-input-in-edit-border-color;
    
        // &:focus,
        // &:active {
        //     box-shadow: $dp-input-in-edit-box-shadow;
        // }
    }
    
}
