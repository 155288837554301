.form {
    .main--index & {
        margin: 40px 0;
        padding: 24px;
        border: 1px solid $border;
        border-radius: 24px;
        // background-color: $border;
        // backdrop-filter: blur(12px);
        box-shadow: $box-shadow;
        // background-image: $linear-gradient;
        background-color: $secondary;
    }

    .main--authorization & {
        width: 100%;
        // margin: 32px 0;
        margin: 0 auto;
        max-width: 400px;
    }

    .main--registration & {
        padding: 0;
        border: 1px solid $border-1;
        border-radius: 24px;
    }

    .main--settings &,
    .main--obligation &,
    .main--e-currency &,
    .main--check & {
        padding: 32px;
        border: 1px solid $border-1;
        border-radius: 24px;
        width: 100%;
    }

    .main--obligation &,
    .main--e-currency &,
    .main--check & {
        display: flex;
        flex-wrap: wrap;
    }

    .main--check & {
        &:not(:last-of-type) {
            margin-bottom: 24px;
        }
    }

    .main--settings & {
        display: flex;
        flex-wrap: wrap;
        max-width: 548px;
    }

    &__fieldset {
        position: relative;
        
        // border-radius: 24px;

        .main--registration & {
            display: flex;
            flex-direction: column;
            padding: 16px 16px 0 16px;
            
            &:not(:first-of-type) {
                // margin-top: 32px;
                padding-bottom: 32px;
            }

            &:not(:last-of-type) {
                border-bottom: 1px solid $border-1;
            }

            &:last-of-type {
                padding-bottom: 32px;
                margin-top: 0;
            }
        }

        // &::before {
        //     @include pseudo-element();
        //     left: -32px;
        //     top: -32px;
        //     width: calc(100% + 65px);
        //     height: 1px;
        //     background-color: $border-4;
        // }
    }

    &__container {
        &:not(:last-of-type) {
            border-bottom: 2px solid $input-border;
            padding-bottom: 24px;
            margin-bottom: 24px;
        }

        .main--registration & { 
            width: 100%;
        }

        .main--obligation &,
        .main--e-currency & {
            display: flex;
            align-items: center;
            margin-top: 24px;
        }
    }
    
    &__title {
        @include font(20px, 28px, 700);
        color: $primary;

        .main--index &,
        .main--settings & {
            margin-bottom: 48px;
        }

        .main--registration &,
        .main--obligation &,
        .main--e-currency &,
        .main--check &  {
            width: 100%;
            margin-bottom: 40px;
        }
    }

    &__name {
        @include font(20px, 28px, 700);
        margin-top: 8px;
        margin-bottom: 40px;
    }

    &__label { 
        position: relative;
        display: flex;
        flex-direction: column;
        // &:not(:first-of-type) {
        //     margin-top: 32px;
        // }

        // .form__field & {
        //     margin: 0;
        //     width: 50%;

        //     &:last-child {
        //         margin-left: 24px;
        //     }
        // }
        
        .main--index &,
        .main--registration & {
            @include font(16px, 20px, 500);
            color: $primary;
            width: 100%;
            margin-top: 24px;
        }

        .main--authorization & {
            @include font(16px, 20px, 700);
            color: $label;
            margin-top: 24px;
        }

        .main--obligation &,
        .main--e-currency &,
        .main--check &  {
            @include font(16px, 20px, 500);
            color: $primary;
        }

        .main--check & {
            margin-bottom: 8px;
            width: 100%;
        }

        .form__field > &:not(:first-child) {
            margin-top: 24px;
        }

        &--required {
            &::before {
                @include pseudo-element();
                top: 36px;
                right: 8px;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background-color: $required;
            }
        }

        &--disabled {
            cursor: auto;
            pointer-events: none;
        }
    }

    &__value {
        display: block;
        padding: 16px;
    }


    &__button {
        @include font(16px, 20px, 600);
        outline: none;

        &--bordered {
            padding: 8px 16px;
            border: 1px solid $primary;
            width: 60px;
            border-radius: 20px;
            background: none;

            .main--registration & {
                margin-top: 8px;
                width: auto;
            }
        }

        &--filled {
            padding: 16px;
            border-radius: 8px;
            background-color: $bg-tertiary;
            border: none;
            
            .main--obligation &,
            .main--e-currency & {
                margin-right: 24px;
                // margin-top: 28px;
                // margin-right: auto;
            }
        }

        .form__label:nth-of-type(2) & {
            margin-top: 12px;
            width: 100%;
        }

        .form__field > & {
            margin-top: 24px;
        }
        // white-space: nowrap;
    }

    &__field {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        // @include font(16px, 21px, 500);
        position: relative;
        
        // color: $primary;
        // width: 100%;
        // margin-bottom: 24px;
        .form__label:nth-of-type(2) & {
            flex-direction: column;
        }

        &--required {
            &::before {
                @include pseudo-element();
                top: 6px;
                right: 8px;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background-color: $required;
            }
        }

        .main--registration & { 
            width: 100%;
            margin-top: 8px;
        }

        .main--obligation &,
        .main--e-currency & {
            margin-bottom: 32px;
        }

        .main--check & {
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
        }

        .form__label + & {
            margin-top: 8px;
        }
        // &:last-of-type {
        //     margin-bottom: 40px;
        // }

        .form__field > & {
            margin: 0
        }
    }

    &__input,
    &__textarea {
        @include font(16px, 21px, 600);
        padding: 14px;
        background-color: $input-bg;
        border-radius: 8px;
        border: 2px solid $border;
        color: $primary;
        width: 100%;
        margin-top: 8px;
        outline: none;
        transition: 0.2s ease-in-out;

        &:focus {
            border: 2px solid $border-2;
        }

        .main--registration & {

        }
    }

    &__textarea {
        resize: none;
        margin-bottom: 32px;

        .form__label--disabled + & {
            opacity: 0.3;
            pointer-events: none;
            cursor: auto;
        }

        // &:not(:last-child) {
        //     margin-bottom: 24px;
        // }
    }

    &__date,
    &__time-zone {
        @include font(14px, 20px, 400);
    }

    &__date {
        margin: 32px 0 0 auto;
    }

    &__time-zone {
        .main--e-currency & {
            display: none;
        }

        // .main--obligation & {
        //     margin-left: 24px;
        // }
    }

    &__submit {
        @include font(16px, 21px, 500);
        color: $secondary;
        background: none;
        border: 1px solid $secondary;
        border-radius: 24px;
        padding: 10px 16px;
        transition: 0.2s ease-in-out;
    }
}



@include media-breakpoint-up(sm) { 
    .form {
        &__fieldset {
            .main--registration & {
                flex-direction: row;
                flex-wrap: wrap;
                padding: 32px 32px 0 32px;
                
            }
        }

        &__label {
            
            .form__field & {
                margin: 0;
                
                .main--obligation & {
                    width: 50%;
                }
    
                &:not(:first-child) {
                    margin-left: 24px;
                    margin-top: 0;
                }

                
            }

            .main--e-currency .form__field:first-of-type &:first-child {
                flex-grow: 1;
            }
        }

        &__field {
            flex-direction: row;
            align-items: center;
            flex-wrap: nowrap;

            .main--registration & {
                justify-content: space-between;
            }

            .main--obligation &,
            .main--e-currency &,
            .main--check & {
                align-items: flex-end;
                justify-content: flex-start;
            }

            .main--check & {
                margin-bottom: 24px;
            }
        }

        &__button {
            .main--registration & {
                margin-top: 0;
                margin-left: 8px;
            }

            &--filled {
                .main--obligation &,
                .main--e-currency & {
                    margin-left: 24px;
                }
            }
        }

        &__time-zone {
            display: block;
            margin: auto 0 auto auto;
            // margin-left: 24px;
        }
    }
}

@include media-breakpoint-up(md) { 
    .form {
        .main--index & {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}

@include media-breakpoint-up(lg) { 
    .form {
        &__submit {
            &:hover {
                background-color: $menu;
                color: $secondary;
                border: 1px solid $secondary;
            }
        }
    }
}

