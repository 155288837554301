.menu {
    display: flex;
    align-items: center;
    justify-content: center;

    &--disabled {
        pointer-events: none;
    }
    
    &--header {
        flex-wrap: wrap;
        position: absolute;
        left:0;
        top: 100%;
        opacity: 0;
        transform: translateY(-200%);
        background-color: $white;
        z-index: 1000;
        width: 100vw;
        padding: 40px 12px 28px 12px;
        min-width: $min-width-page;
        transition: 0.3s transform, opacity 0.3s ease-in-out;
        box-shadow: $box-shadow;

        .header__trigger.active + & {
            transform: translateY(10px);
            opacity: 1;
        }
    }

    &--section {
        flex-wrap: wrap;
        overflow-x: auto;
        overflow-y: hidden;
        padding: 0 8px;

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }

    &--section-1 {
        flex-wrap: wrap;
        gap: 16px;
        margin: 40px 0;
    }

    &--receipt {
        position: relative;
        width: 90%;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 32px auto 0 auto;
    }

    &__item {
        @include font(14px, 24px, 500);
        width: 100%;
        white-space: nowrap;
        border: 2px solid $menu;
        border-radius: 8px;
        margin-bottom: 16px;

        &--faq {
            display: none;
        }

        &--history {
            position: absolute;
            bottom: -80px;
            left: 0
        }

        .menu--section & {
            @include font(16px, 21px, 500);
            display: flex;
            align-items: center;
            color: $primary;
            padding: 5px 16px;
            text-align: center;
            column-gap: 8px;
            background-color: $secondary;
            border-radius: 24px;
            white-space: nowrap;
            border: 1px solid $border-2;
            cursor: pointer;
            transition: color 0.2s, background-color 0.2s ease-in-out;
            margin: 0 auto;
            width: 80%;

            &:not(:last-of-type) {
                margin-bottom: 24px;
            }

            &:active {
                background-color: $menu;
                color: $secondary;
                border: 1px solid $secondary;
            }
        }

        .menu--section-1 & {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            column-gap: 8px;
            color: $secondary;
            background-color: $tertiary;
            padding: 16px;
            border-radius: 20px;
            border: 1px solid $primary;
            cursor: pointer;
            transition: 0.2s ease-in-out;

            &:active {
                background-color: $menu;
                color: $secondary;
                border: 1px solid $secondary;
            }
        }

        .menu--receipt & {
            border: none;
            
            &:not(.menu__item--history) {
                width: 49%;
                margin-bottom: 8px;
            }   
        }

        
    }

    &__link {
        display: block;
        color: inherit;
        line-height: 32px;
        text-align: center;
        padding: 8px;
        margin-left: auto;
        margin-right: auto;
        transition: color 0.2s, background-color 0.2s, border-color 0.2s, opacity 0.2s ease-in-out;
        // &:hover {
        //     color: inherit;
        // } 

        &:active {
            color: $menu-active;
            opacity: 0.7;
        }

        &:visited {
            color: $primary;
        }
    }
}

@include media-breakpoint-up(sm) { 
    .menu {
        &--section {
            margin: 0 auto;
            max-width: 465px;
        }

        &--section-1 {
            flex-direction: row;
            column-gap: 2%;
        }

        &__item {
            .menu--section-1 & {
                width: 32%;
            }
        }
    }
}

@include media-breakpoint-up(md) { 
    .menu {
        &--header {
            justify-content: flex-start;
            flex-direction: row;
            align-items: center;
            position: static;
            transition: transform 0s; 
            column-gap: 14px;
            padding: 0;
            transform: none;
            box-shadow:none;
            opacity: 1;
            width: 100%;
            min-width: auto;
            margin-left: 14px;
            
            .header__trigger.active + & {
                transform: none;
            }
        }

        &--section {
            justify-content: flex-start;
            margin: -40px 0 0 0;
            padding: 0;
        }

        &--section-1 {
            justify-content: space-between;
            column-gap: 0;
        }

        &--receipt {
            width: 100%;
        }

        &__item {
            width: max-content;
            padding: 0;
            border-radius: 0;
            border: 0;
            margin: 0;

            &--faq {
                margin-left: auto;
                padding-right: 14px;
                border-right: 1px solid $separator;
            }

            &--login {
                display: block;
            }

            .menu--header & {
                display: block;
            }

            .menu--section & {
                font-size: 12px;
                line-height: 32px;
                padding: 4px 12px;
                width: auto;
                flex-wrap: nowrap;

                &:not(:last-of-type) {
                    margin-bottom: 0;
                    margin-right: 8px;
                }
            }

            .menu--section-1 & {
                width: 165px;
            }
        }

        &__link {
            padding: 0;

            .menu__item--faq & {
                font-size: 0;
                
            }

            .menu__item--login & {
                border: 2px solid $link;
                color: $link;
                border-radius: 20px;
                padding: 5px 12px;
            }

            .menu__item--registration & {
                background-color: $link;
                color: $secondary;
                border-radius: 20px;
                padding: 5px 12px;
                border: 2px solid $link;

                &:active {
                    background-color: $menu-active;
                    color: $primary;
                    border: 2px solid $primary
                }
            }

            &--faq {
                display: none;
            }
        }
    }
}

@include media-breakpoint-up(lg) { 
    .menu {
        &--header {
            column-gap: 24px;
            margin-left: 24px;
        }

        &--section-1 {
            justify-content: flex-start;
        }

        &__item {
            font-size: 16px;

            &--logo,
            &--faq {
                padding-right: 24px;
            }
            
            &--login {
                margin-left: 0;
            }

            &--history {
                left: auto;
                right: 0;
            }

            &--issues {
                .menu--header & {
                    display: block;
                }
            }

            .menu--section & {
                font-size: 16px;

                &:not(:last-child) {
                    margin-right: 16px;
                }

                &:hover {
                    background-color: $menu;
                    color: $secondary;
                    border: 1px solid $secondary;
                }
            }

            .menu--section-1 & {
                width: 176px;
                margin-right: 12px;

                &:hover {
                    background-color: $menu;
                    color: $secondary;
                    border: 1px solid $secondary;
                }
            }

            .menu--receipt & {
                margin-bottom: 0;

                &:not(.menu__item--history) {
                    width: 150px;
                    margin-right: 8px;
                }  
            }
        }

        &__link {
            &:hover {
                color: $menu-active;
                opacity: 0.7;
            }

            &:active {
                color: $menu;
                opacity: 1;
            }

            .menu__item--faq & {
                font-size: 16px;
            }

            .menu__item--login & {
                &:active {
                    background: none;
                    color: $primary;
                    border: 2px solid $primary;
                    opacity: 1;
                }
            }

            .menu__item--registration & {
                &:hover {
                    background-color: $menu-active;
                    color: $primary;
                    border: 2px solid $menu-active;
                    opacity: 1;
                }

                &:active {
                    background-color: $menu-active;
                    color: $primary;
                    border: 2px solid $primary;
                }
            }
        }
    }
}

