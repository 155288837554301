.popup {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $cover;
    z-index: 1000;

    &__container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 452px;
        width: 90%;
        background-color: $secondary;
        z-index: 1000;
        border-radius: 24px;
        padding: 36px 40px;
    }  
    
    &__title {
        @include font(16px, 20px, 700);
        margin-bottom: 24px;
    }
}