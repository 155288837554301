
.checkbox {
    .info__block--check & {
        margin-top: 24px;
    }

    .receipt & {
        margin-bottom: 24px;
    }

    .main--authorization &,
    .main--issue & {
        margin-bottom: 24px;
    }

    .main--registration & {
        margin-top: 24px;
    }

    .form__field + & {
        margin-top: 8px;
    }

    &__input {
        display: none;
    }

    &__label {
        @include font(14px, 18px, 500);
        position: relative;
        padding-left: 33px;
        border-radius: 8px;
        cursor: pointer;
        user-select: none;
        width: 100%;
        transition: color 0.15s ease-in-out;

        &:hover,
        &:focus {
            outline: none;
        }

        &::after {
            @include pseudo-element();
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            border: 2px solid $checkbox-border;
            border-radius: 8px;
            width: 24px;
            height: 24px;
            
            .checkbox__input:checked ~ & {
                // background: url("../images/icons/check.svg") no-repeat 50%;
                background-size: 32px;
            }
        }

        .checkbox__input:checked + &::after {
            border: 2px solid $receipt-button;
            background-color: $receipt-button;
        }
    }

    &__link {
        margin-left: 4px;
        color: $link;
        cursor: pointer;

        &:hover {
            color: $link;
            text-decoration: underline;
        }
    }
}
