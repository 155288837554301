@mixin font($font-size, $line-height, $font-weight: normal, $font-style: normal) {
    font-family: "Inter", "Times New Roman", sans-serif;
    font-size: $font-size;
    line-height: $line-height;
    font-weight: $font-weight;
    font-style: $font-style;
}

@mixin pseudo-element($display: block, $position: absolute, $content: '') {
    content: $content;
    display: $display;
    position: $position;
}

@mixin button($color, $background-color, $width: max-content) {
    padding: 14px;
    white-space: normal;
    border-radius: 8px;
    border: none;
    color: $color;
    background-color: $background-color;
    cursor: pointer;
    transition: 0.15s ease-in-out;
    width: $width;

    &:focus {
        outline: none;
    }
}

@mixin visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
}

@mixin visually-visible {
    // width: auto;
    // height: auto;
    margin: auto;
    overflow: visible;
    clip: auto;
    clip-path: none;
}


// @mixin hover($transition, $background-color, $color:inherit) {
//     color:  $color;
//     background-color: $background-color;
//     transition: $transition;
// }
