.radio {
    display: flex;
    // align-items: center;
    width: 100%;

    &--disabled {
        pointer-events: none;
    }

    .tabs-form__label & {
        margin-top: 8px;
        flex-wrap: wrap;
    }

    .receipt & {
        flex-direction: column;
        margin-bottom: 32px;
    }

    .main--settings & {
        flex-direction: column;
    }

    .main--e-currency & {
        display: flex;
        flex-direction: column;
        // margin-bottom: 32px;
    }

    .receipt__actions & {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        column-gap: 16px;
        margin: 0;
    }
    
    &__field {
        display: flex;
        align-items: center;

        .receipt & {
            width: 100%;
            // width: 50%;
            margin-bottom: 16px;

            // &:not(:nth-of-type(2n)) {
            //     margin-right: 16px;
            // }
        }

        .receipt__actions & {
            width: auto;
        }

        .main--settings & {
            margin-bottom: 32px;
        }

        .main--e-currency & {
            margin-bottom: 32px;
        }
    }

    &__input {
        display: none;
    }

    &__label {
        color: $primary;
        display: inline-block;
        position: relative;
        // padding: 8px;
        width: 16px;
        height: 16px;
        background-color: $secondary;
        border-radius: 8px;
        margin-right: 8px;
        cursor: pointer;
        user-select: none;

        &:hover,
        &:focus {
            outline: none;
        }

        &::before,
        &::after {
            @include pseudo-element();
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: 10px;
            border-radius: 50%;
            transition: background-color 0.2s ease-in-out;
        }

        &::before {
            // left: 0;
            width: 16px;
            height: 16px;
            background-color: $secondary;

            .tabs-form__label & {
                border: 1px solid $primary;
            }

            .receipt &,
            .form & {
                border: 1px solid $radio-receipt-default;
            }
        }

        .radio__input:checked + &::after {
            width: 8px;
            height: 8px;

            .tabs-form__label & {
                background-color: $primary;
            }

            .receipt &,
            .form & {
                background-color: $radio-receipt-active;
            }

            .radio--disabled & {
                background-color: $disabled-2;
            }

        }

        .radio__input:checked + & {
            .tabs-form__label & {
                color: $primary;
            }

            .receipt &::before,
            .form &::before {
                border: 1px solid $radio-receipt-active;
            }

            .radio--disabled &::before  {
                border: 1px solid $disabled-2;
            }

        }
    }

    &__text {
        .receipt & {
            @include font(20px, 24px, 500);
        }

        .form & {
            @include font(16px, 20px, 500);
        }

        .receipt__actions & {
            color: $primary;
            @include font(14px, 17px, 500);
        }
    }
}

@include media-breakpoint-up(sm) { 
    .radio {
        .receipt__actions & {
            justify-content: space-around;
        }

        .form__field + & {

            flex-direction: row;
            justify-content: space-between;
        }
        
        &__field {
            .receipt__actions & {
                margin: 0;
            }

            .tabs-form__label & {
                width: max-content;
                
                &:not(:first-of-type) {
                    margin-top: 0;
                    margin-left: 32px;
                }
            }

            .main--e-currency & {
                margin-top: 0;

                &:not(:last-child) {
                    margin-right: 16px;
                }
            }
        }
    }
}

@include media-breakpoint-up(md) { 
    .radio {
        &__field {
            // width: 100%;

            &:not(:first-of-type) {
                // margin-top: 8px;
                margin-left: 0;
            }
        }
    }
}

@include media-breakpoint-up(lg) { 
    .radio {
        &__field {
            .tabs-form__label & { 
                width: max-content;
                :not(:first-of-type) {
                    margin-top: 0;
                    margin-left: 32px;
                }
            }
        }
    }
}