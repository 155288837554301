@mixin dp-header-button-common {
    border-color: transparent;
    font-weight: bold;
}

@mixin dp-footer-button-common {
    @include font($dp-font-size-small, 24px, 500);
    flex: auto;
    margin: calc(0.375rem - 1px) 0.375rem;
    border-radius: $dp-border-radius;
}
