.info {
    // &__toggles {
    //     display: flex;
    //     flex-wrap: nowrap;
    //     overflow-x: auto; 
    //     padding: 0 16px 24px 16px;

    //     &::-webkit-scrollbar {
    //         width: 0;
    //         height: 0;
    //     }
    // }

    // &__toggle {
    //     @include font(20px, 25px, 500);
    //     display: flex;
    //     white-space: nowrap;
    //     padding: 12px 24px 12px 24px;
    //     color: $primary;
    //     border-top: 4px solid transparent;
    //     transition: 0.2s ease-in-out;
    //     cursor: pointer;

    //     &:active {
    //         color: $menu;
    //     }

    //     &.active {
    //         color: $menu;
    //         border-top: 4px solid $menu;
    //     }
    // }

    &__block {
        display: none;
        padding: 24px 0;

        &.active {
            display: block;
        }
    }

    // &__content {
    //     padding: 48px;
    // }

    &__item {
        padding: 24px 0;
        margin-bottom: 24px; 
        border-top: 1px solid $separator;
        border-bottom: 1px solid $separator; 
        
        // &:not(:first-of-type) {
           
        // }
    }

    &__top {
        display: flex;
        align-items: center;
    }

    &__heading {
        @include font(24px, 31px, 500);
        color: $heading-2;
        padding-right: 24px;
        cursor: pointer;
    }

    &__sub-heading {
        display: block;
        font-weight: 700;
        margin-bottom: 24px;
    }

    &__button {
        padding: 8px;
        border: none;
        outline: none;
        background: none;

        &--copy {
            margin-right: 16px;
            margin-left: auto;
            transition: 0.2s ease-in-out;

            &:active {
                color: $menu;
            }
        }

        &--dropdown {
            transform: rotate(-180deg);
            transition: 0.2s ease-in-out;

            &.collapsed {
                transform: rotate(0);
            }
        }
    }

    &__term {
        font-weight: 700;
    }

    &__paragraph {
        margin-top: 40px;

        .info__content & {
            margin-top: 24px;
        }
    }

    &__link {
        color: inherit;
        text-decoration: underline;
        margin: 0 4px;
    }

    &__text {
        color: $link;
    }
}

@include media-breakpoint-up(md) { 
    .info {
        // &__toggles {
        //     flex-direction: column;
        //     margin: 40px 0;
        //     border-right: 1px solid $separator;
        //     padding: 0;
        // }

        // &__toggle {
        //     border-top: none;
        //     border-left: 4px solid transparent;
        //     margin-top: 8px;
        //     white-space: normal;

        //     &.active {
        //         color: $menu;
        //         border-top: none;
        //         border-left: 4px solid $menu;
        //         margin-left: 12px;
        //     }
        // }
        // &__content {
        //     padding: 32px 32px 32px 0;
        // }

        &__item {
            border: 1px solid $separator;
            border-radius: 16px;
            padding: 24px;
        }

        &__block {
            margin: 16px 0;
        }
    }
}

@include media-breakpoint-up(lg) { 
    .info {
        // &__toggle {
        //     &:hover {
        //         color: $menu;
        //     }
        // }

        &__button {
            &:hover {
                color: $menu;
            }
        }
    } 
}
