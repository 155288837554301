.input {
    border-radius: 8px;
    outline: none;
    text-align: center;
    transition: 0.2s ease-in-out;

    .receipt & {
        &:not(.input--green):not(.receipt__label--in-line .input) {
            @include font(16px, 34px, 500);
        }
    }

    .receipt &,
    .popup &,
    .main--obligation &,
    .main--e-currency & {
        color: $tertiary;
        border: 2px solid $input-border;
        background-color: $input-bg;
        padding: 16px;

        // &:not(.input--password):not(.receipt__label--in-line .input) {
        //     padding: 16px;
        // }

        // &:not(.input--green),
        // &:not(.input--error) {
        //     border: 2px solid $input-border;
        //     background-color: $input-bg;
        // }

        &--crypto {
            font-size: 16px;
        }
    }

    .receipt__label--in-line & {
        @include font(14px, 18px, 500);
        margin-left: 8px;
        // width: calc(100% - 210px);
        width: 20%;
        max-width: 75px;
        padding: 8px;
    }

    .popup & {
        margin-bottom: 24px;
        width: 100%;
    } 

    .tabs-form & {
        @include font(20px, 24px, 500);
        padding: 14px;
        width: 100%;
    }

    .main--authorization &,
    .main--obligation &,
    .main--e-currency &,
    .main--check & {
        @include font(16px, 22px, 500);
        text-align: left;
        border: 2px solid $input-border;
        background-color: $input-bg;
        // color: $label;
        color: $primary;
        width: 100%;

        &:not(.input--password) {
            padding: 16px;
        }

        &::placeholder {
            color: $label;
        }
    }

    .main--registration & {
        @include font(16px, 20px, 500);
        padding: 16px;
        border: 2px solid $input-border;
        text-align: left;
        width: 100%;
    }

    .form__label--disabled & {
        opacity: 0.3;
    }

    .receipt__value + & {
        padding: 16px 62px;
    }

    &--time {
        .main--e-currency & {
            width: 106px;
        } 
        
        .form__label--disabled + & {
            pointer-events: none;
            opacity: 0.3;
            cursor: auto;
        }

        .form__field > & {
            margin-top: 24px;
        } 
    }

    &--error {
        .receipt &,
        .popup &,
        .main--obligation &,
        .main--e-currency &,
        .main--registration &,
        .main--authorization & {
            transition: 0.2s ease-in-out;
            border: 2px solid $error;
            background-color: $error-bg;
            color: $error;

            &:focus {
                color: $error-active;
                border-color: $error-active;
            }
        }
    }

    &--password {
        padding: 16px 64px;

        .main--authorization & {
            padding: 16px 64px 16px 16px;
        }
    }

    &--green {
        @include font(14px, 18px, 500);
        background-color: $bg-tertiary;
        color: $green-input;
        border: 2px solid $bg-tertiary;
        padding: 8px 24px;

        &:focus {
            border: 2px solid $green-input; 
        }

        // .receipt & {
        //     width: calc(100% - 329px);
        // }
    }

    // &--center {
    //     text-align: center;
    // }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0; 
    }

    &:focus:not(input[readonly="true"]):not(.input--green):not(.input--error) {
        outline: none;
        border: 2px solid $border-2;
    }

    .info__block--replenishment & {
        // width: 40%;
        // flex-grow: 1;
        // margin-bottom: 8px;
    }

    .receipt &:not(.receipt__field &):not(.receipt__label--in-line &):not(.input--green) {
        width: 100%;
        // background-color: $input-bg;
        margin-top: 8px;
    }

    .receipt__field & {
        width: 100%;
    }

    .form__label > & ,
    .tabs-form__label > & {
        margin-top: 8px;
    }

    .capcha + & {
        order: 1;
    }

    // &[type="password"] {
    //     // padding-right: 64px;
    //     
    // }

    &[readonly="true"] {
        cursor: auto;
    }
}

@include media-breakpoint-up(sm) { 
    .input {
        .receipt & {
            &:not(.input--green):not(.receipt__label--in-line .input) {
                font-size: 20px;
            }
        }

        .receipt__label--in-line & {
            font-size: 14px;
            width: calc(100% - 310px);
            padding: 8px 24px;
        }
        .info__block--replenishment & {
            margin-right: 8px;
            margin-bottom: 0;
        }

        .capcha + & {
            font-size: 11px;
            order: 2;
            width: calc(100% - 207px);
        }

        // .form__field > & {
        //     margin-top: 0;
        //     margin-left: 24px;
        // } 

        &--time {
            .form__field > & {
                margin-top: 0;
                margin-left: 24px;
            } 
        }

        &--date {
            .main--e-currency & {
                width: 240px;
            } 
        }
    }
}

@include media-breakpoint-up(md) { 
    .input {
        .capcha + & {
            font-size: 16px;
        }

        &--limited {
            max-width: 230px;
        }
        
    }
}