.link {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    transition:  color 0.15s, background-color 0.15s ease-in-out;

    &--primary {
        @include font(20px, 26px, 500);
        border-radius: 8px;
        padding: 16px;
        background-color: $primary-button-default;
        color: $secondary;
        text-align: center;

        &:active {
            background-color: $primary-button-active;
            color: $tertiary;
        }

        &:hover {
            color: $secondary;
        }
    }

    &__text {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    
    &:visited {
        color: $secondary;
    }
}

@include media-breakpoint-up(sm) { 
    .link {
        flex-wrap: nowrap;
    }
}

@include media-breakpoint-up(lg) { 
    .link {
        &--primary {
            &:hover {
                color: $secondary;
                background-color: $primary-button-hover;
            }

            &:active {
                background-color: $primary-button-active;
            }
        }
    }
}