.button {
    outline: none;
    border: none;
    user-select: none;
    transition:  color 0.15s, background-color 0.15s, border-color 0.15s ease-in-out;
    color: $primary;

    &--success {
        @include font(19px, 26px, 500);
        border-radius: 8px;
        padding: 16px;
        background-color: $success-button;
        color: $primary;

        .info__block--check &,
        .info__block--replenishment & {
            margin-top: 24px;
        }
        
        .form & {
            border: 1px solid $secondary;
            margin-top: 48px;

            &:active {
                background-color: $menu;
                color: $secondary;
            }
        }
    }

    &--primary {
        @include font(17px, 26px, 500);
        border-radius: 8px;
        padding: 16px;
        background-color: $primary-button-default;
        color: $secondary;

        &:active {
            background-color: $primary-button-active;
            color: $tertiary;
        }

        .receipt &,
        .main--authorization & {
            width: 100%;
        }

        .receipt & {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: nowrap;
        }
        
        .main--registration & {
            background-color: $primary-registration-button-default;
            color: $primary;
            width: auto;

            &:active {
                background-color: $primary-button-active;
                color: $secondary;
            }
        }
        
        // .form__field > & {
        //     margin-top: 24px;
        // }

        // .main--settings &,
        // .main--obligation &,
        // .main--e-currency & {
        //     margin-top: 40px;
        // }
    }

    &--secondary {
        @include font(19px, 26px, 500);
        border-radius: 8px;
        padding: 16px;
        background-color: $secondary;
        border: 1px solid $secondary-button;
        color: $secondary-button;

        &:active {
            border: 1px solid $receipt-button;
            color: $receipt-button;
        }

        .main--settings & {
            margin-top: 24px;
        }

        .main--receipts & {
            width: 100%;
        }
    }

    &--tertiary {
        @include font(19px, 26px, 500);
        border-radius: 8px;
        padding: 16px;
        background-color: $brand;
        color: $secondary;

        &:active {
            background-color: lighten($brand, 10%);
        }

        .main--authorization & {
            width: 100%;
        }
    }

    &--rounded {
        @include font(15px, 20px, 500);
        border-radius: 20px;
        padding: 10px 16px;
        background-color: $rounded-button-default;
        color: $primary;

        .tabs-form & {
            width: 100%;

            &:not(:first-of-type) {
                margin-top: 8px;
            }
        }

        .main--authorization &,
        .main--issue & {
            border-radius: 8px;
        }

        .main--receipts & {
            &:active {
                background-color: $rounded-button-active;
                color: $tertiary;
            }
        }
    }

    &--bordered {
        @include font(15px, 20px, 500);
        // border-radius: 20px;
        padding: 10px 16px;
        background-color: transparent;
        border: 2px solid $border-4;

        .main--receipts & {
            border: 2px solid $receipt-button;
            // position: absolute;
            // right: 0;
            // bottom: -85px;
            color: $receipt-button;
            

            &:active {
                background-color: $receipt-button;
                color: $secondary;
            }
        }

        .tabs-form & {
            margin-top: 0;
            color: $tertiary;
            border: 2px solid $button-border;
        }

        .form & {
            font-size: 20px;
            color: $primary-button-default;
            border: 2px solid $receipt-button;
            padding: 16px;
            width: 100%;
        }

        .main--registration & {
            border-radius: 8px;
            border: 2px solid $primary;
            color: $primary;
            width: auto;
            margin-top: 16px;

            &:active {
                background-color: $primary-button-active;
                color: $secondary;
                border: 2px solid $secondary;
            }
        }
    }

    &--menu {
        padding: 16px;
        display: flex;
        flex-direction: column;
        width: 100%;
        border-radius: 8px;
        color: $primary;

        &:active {
            opacity: 0.6;
        }
        
        &:hover,
        &:visited {
            color: $primary;
        }
    }

    &--colored-1 {
        background-color: $menu-button-1;
    }

    &--active {
        border: 2px solid $primary-button-default;
    }

    &--colored-2 {
        background-color: $menu-button-2;
    }

    &--colored-3 {
        background-color: $menu-button-3;
    }

    &--colored-4 {
        background-color: $menu-button-4;
    }

    &--colored-5 {
        background-color: $menu-button-5;
    }

    &--colored-1, 
    &--colored-2,
    &--colored-3,
    &--colored-4,
    &--colored-5 {
        max-height: 90px;
    }


    &--show {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        transition: 0.15s ease-in-out;
        cursor: pointer;
        background: none;
        outline: none;
        border: none;
        color: $label;
        padding: 0;

        &:active {
            color: $receipt-button;
            opacity: 0.6;
        }

        .input--error + & {
            color: $error;
        }

        .input--error:focus + & {
            color: $error-active;

            &:hover {
                color: $receipt-button;
            }
        }
    }

    &--disabled {
        @include font(20px, 26px, 500);
        border-radius: 8px;
        padding: 16px;
        background-color: $disabled-3;
        color: $secondary;

        .main--receipts & {
            width: 100%;
        }
        
        &:active {
            background-color: $disabled-3;
            color: $secondary;
        }
    }

    &--file {
        color: transparent;
        position: relative;
        cursor: pointer;
        width: 143px;

        &::-webkit-file-upload-button {
            display: none;
        }

        &::before {
            @include pseudo-element(block, absolute, "Выбрать");
            color: $secondary;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &:active {
            background-color: $primary-button-active;
            color: transparent;

            &::before {
                color: $primary;
            }
        }

        .main--check & {
            margin-left: 24px
        }
    }

    &__text {
        text-align: left;
        // width: 100%;
    }

    &__icons {
        display: flex;
        align-items: center;
        margin-left: auto;
    }

    .receipt & {
        &:not(&:first-of-type) {
            margin-top: 16px;
        }
    }

    .main--authorization &,
    .main--issue & {
        &:not(:last-of-type) {
            margin-bottom: 24px;
        }
    }

    &:visited {
        color: $primary;
    }
}

@include media-breakpoint-up(sm) {
    .button {
        &--bordered {
            .main--registration & {
                margin-top: 0;
                margin-left: 16px;
            }
        }

        &--primary {
            .main--issue .section__container--1 & {
                width: auto;
                margin: auto auto auto 24px;
            }

            .main--obligation .form__field &,
            .main--e-currency .form__field & {
                margin-top: 0;
                margin-right: auto;
                margin-left: 24px;
                width: auto;
            }

            .main--obligation .form &,
            .main--e-currency .form & {
                width: auto;
            }

            .main--settings & {
                width: auto;
            }

            .receipt & {
                flex-wrap: nowrap;
            }
        }

        &--secondary {
            .main--settings & {
                margin-top: 0;
                margin-left: 24px ;
                width: auto;
            }
        }

        &--file {
            .main--check .form__field & {
                margin-left: 24px;
            }
        }

        &__text {
            width: auto;
        }
    }
}

@include media-breakpoint-up(md) { 
    .button {
        &--primary {
            .main--issue & {
                width: auto;
            }

            .main--issue .section__container--1 & {
                margin: auto auto auto 8px;
            }
        }
    }
}


@include media-breakpoint-up(lg) { 
    .button {
        &--success {
            .tabs-form & {
                margin-top: 48px
            }

            .form & {
                border: 1px solid $secondary;
                
                &:active  {
                    background-color: $menu;
                    color: $secondary;
                }
            }
        }

        &--primary {
            &:hover {
                background-color: $primary-button-hover;
            }

            &:active {
                background-color: $primary-button-active;
            }

            // .main--authorization &,
            // .main--issue & {
            //     max-width: 380px;
            // }

            .main--registration & {
                &:hover {
                    color: $secondary;
                }
            }

            .main--issue .section__container--1 & {
                margin: 0;
            }
        }

        &--secondary {
            &:hover {
                border: 1px solid $receipt-button;
                color: $receipt-button;
            }

            &:active {
                border: 1px solid $tertiary;
                color: $tertiary;
            }
        }

        &--tertiary {
            // .main--authorization & {
            //     max-width: 380px;
            // }

            &:hover {
                background-color: darken($brand, 10%);
            }

            &:active {
                background-color: lighten($brand, 10%);
            }
        }

        &--bordered {
            .receipt & {
                &:hover {
                    background-color: $receipt-button;
                    color: $secondary;
                }
    
                &:active {
                    background-color: $success-button;
                    color: $tertiary;
                    border-color: $success-button;
                }
            }

            .main--registration & {
                &:hover {
                    background-color: $primary-button-hover;
                    color: $secondary;
                    border-color: $primary-button-hover;
                }
    
                &:active {
                    background-color: $primary-button-active;
                    border-color: $primary-button-active;
                }
            }
        }

        &--rounded {
            .tabs-form & {
                margin-top: 0;
                
                &:last-of-type {
                    position: relative;
                    margin-left: 32px; 
    
                    &::before {
                        @include pseudo-element();
                        top: 50%;
                        transform: translateY(-50%);
                        left: -18px;
                        width: 2px;
                        height: 40px;
                        background-color: $button-border;
                    }
                }
            }

            &:hover {
                background-color: $rounded-button-hover;
                color: $secondary;
            }

            &:active {
                background-color: $rounded-button-active;
            }

            .main--authorization .section__container--2 &,
            .main--issue .section__container--2 &{
                padding: 6px 20px;

                &:hover {
                    color: $primary;
                    background: none;
                    border: 2px solid $receipt-button;
                }
                
                // &:active {
                //     background-color: $rounded-button-active;
                // }
            }
        }

        &--disabled { 
            &:hover,
            &:active {
                cursor: default;
                background-color: $disabled-3;
                color: $secondary;
            }
        }

        &--colored-1 {
            &:hover {
                background-color: darken($menu-button-1, 20%);
            }
            
        }
    
        &--colored-2 {
            &:hover {
                background-color: darken($menu-button-2, 20%);
            }
        }
    
        &--colored-3 {
            &:hover {
                background-color: darken($menu-button-3, 20%);
            }
        }
    
        &--colored-4 {
            &:hover {
                background-color: darken($menu-button-4, 20%);
            }
        }

        &--colored-5 {
            &:hover {
                background-color: darken($menu-button-5, 20%);
            }
            
        }

        &--show {
            &:hover {
                color: $receipt-button;
            }
        }
    }
}