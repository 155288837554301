.file-input {
    display: block;
    width: 100%;

    .file-caption,
    .fileinput-cancel {
        display: none;
    }

    .btn-primary {
        color: transparent;
        background-color: transparent;
        border: transparent;
        font-size: 0;

        &:hover {
            color: transparent;
            background-color: transparent;
            border: transparent;
        }
    }

    .input-group .btn {
        padding: 0;
    }
}