@charset "UTF-8";
/*!
 * Bootstrap v5.0.2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6, h5, h4, h3, h2, h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1 {
    font-size: 2.5rem;
  }
}

h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2 {
    font-size: 2rem;
  }
}

h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3 {
    font-size: 1.75rem;
  }
}

h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4 {
    font-size: 1.5rem;
  }
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 0.875em;
}

mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #0d6efd;
  text-decoration: underline;
}
a:hover {
  color: #0a58ca;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #dee2e6 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #dee2e6 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #0d6efd !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #198754 !important;
}

.border-info {
  border-color: #0dcaf0 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #212529 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  color: #0d6efd !important;
}

.text-secondary {
  color: #6c757d !important;
}

.text-success {
  color: #198754 !important;
}

.text-info {
  color: #0dcaf0 !important;
}

.text-warning {
  color: #ffc107 !important;
}

.text-danger {
  color: #dc3545 !important;
}

.text-light {
  color: #f8f9fa !important;
}

.text-dark {
  color: #212529 !important;
}

.text-white {
  color: #fff !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  color: inherit !important;
}

.bg-primary {
  background-color: #0d6efd !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

.bg-success {
  background-color: #198754 !important;
}

.bg-info {
  background-color: #0dcaf0 !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.bg-dark {
  background-color: #212529 !important;
}

.bg-body {
  background-color: #fff !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1100px;
  }
}
@keyframes blink {
  0% {
    opacity: 0.2;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.2;
  }
}
@font-face {
  font-family: Inter;
  font-display: swap;
  src: url("../fonts/Inter-ExtraBold.woff2") format("woff2"), url("../fonts/Inter-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: Inter;
  font-display: swap;
  src: url("../fonts/Inter-Medium.woff2") format("woff2"), url("../fonts/Inter-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: Inter;
  font-display: swap;
  src: url("../fonts/Inter-Regular.woff2") format("woff2"), url("../fonts/Inter-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Inter;
  font-display: swap;
  src: url("../fonts/Inter-SemiBold.woff2") format("woff2"), url("../fonts/Inter-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "icons";
  src: url("fonts/icons.eot?1693234294");
  src: url("fonts/icons.eot?1693234294#iefix") format("eot"), url("fonts/icons.woff2?1693234294") format("woff2"), url("fonts/icons.woff?1693234294") format("woff"), url("fonts/icons.ttf?1693234294") format("truetype"), url("fonts/icons.svg?1693234294#icons") format("svg");
}
.icon:before {
  font-family: "icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  /* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
  text-decoration: none;
  text-transform: none;
}

.icon-accept:before {
  content: "\e001";
}

.icon-api:before {
  content: "\e002";
}

.icon-arrow-double-left:before {
  content: "\e003";
}

.icon-arrow-double-right:before {
  content: "\e004";
}

.icon-arrow-left:before {
  content: "\e005";
}

.icon-arrow-right:before {
  content: "\e006";
}

.icon-arrow:before {
  content: "\e007";
}

.icon-bitcoin:before {
  content: "\e008";
}

.icon-burger:before {
  content: "\e009";
}

.icon-check-payment-thin:before {
  content: "\e00a";
}

.icon-check-payment:before {
  content: "\e00b";
}

.icon-check:before {
  content: "\e00c";
}

.icon-clip:before {
  content: "\e00d";
}

.icon-close:before {
  content: "\e00e";
}

.icon-copy-1:before {
  content: "\e00f";
}

.icon-copy:before {
  content: "\e010";
}

.icon-creation:before {
  content: "\e011";
}

.icon-diagram:before {
  content: "\e012";
}

.icon-dots:before {
  content: "\e013";
}

.icon-dropdown:before {
  content: "\e014";
}

.icon-english:before {
  content: "\e015";
}

.icon-ethereum:before {
  content: "\e016";
}

.icon-exchange:before {
  content: "\e017";
}

.icon-eye:before {
  content: "\e018";
}

.icon-gift:before {
  content: "\e019";
}

.icon-google:before {
  content: "\e01a";
}

.icon-guard-default:before {
  content: "\e01b";
}

.icon-guard:before {
  content: "\e01c";
}

.icon-income:before {
  content: "\e01d";
}

.icon-info-2:before {
  content: "\e01e";
}

.icon-info-default:before {
  content: "\e01f";
}

.icon-info-error:before {
  content: "\e020";
}

.icon-info-warning:before {
  content: "\e021";
}

.icon-info:before {
  content: "\e022";
}

.icon-key-rounded:before {
  content: "\e023";
}

.icon-key:before {
  content: "\e024";
}

.icon-lightcoin:before {
  content: "\e025";
}

.icon-lock:before {
  content: "\e026";
}

.icon-login:before {
  content: "\e027";
}

.icon-logo-default:before {
  content: "\e028";
}

.icon-logo:before {
  content: "\e029";
}

.icon-logout:before {
  content: "\e02a";
}

.icon-message:before {
  content: "\e02b";
}

.icon-multiswap:before {
  content: "\e02c";
}

.icon-obligation:before {
  content: "\e02d";
}

.icon-pay:before {
  content: "\e02e";
}

.icon-payment:before {
  content: "\e02f";
}

.icon-plus:before {
  content: "\e030";
}

.icon-profile:before {
  content: "\e031";
}

.icon-question-1:before {
  content: "\e032";
}

.icon-question:before {
  content: "\e033";
}

.icon-receipt:before {
  content: "\e034";
}

.icon-receive:before {
  content: "\e035";
}

.icon-robot:before {
  content: "\e036";
}

.icon-russian:before {
  content: "\e037";
}

.icon-save:before {
  content: "\e038";
}

.icon-search:before {
  content: "\e039";
}

.icon-shield:before {
  content: "\e03a";
}

.icon-simple:before {
  content: "\e03b";
}

.icon-simplicity:before {
  content: "\e03c";
}

.icon-success:before {
  content: "\e03d";
}

.icon-swap-default:before {
  content: "\e03e";
}

.icon-swap:before {
  content: "\e03f";
}

.icon-tether:before {
  content: "\e040";
}

.icon-time:before {
  content: "\e041";
}

.icon-transfer:before {
  content: "\e042";
}

.icon-union:before {
  content: "\e043";
}

.icon-user:before {
  content: "\e044";
}

.icon-webmoney:before {
  content: "\e045";
}

.icon-wmz:before {
  content: "\e046";
}

/* Document
   ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 */
html {
  line-height: 1.15; /* 1 */
  text-size-adjust: 100%; /* 2 */
}

/* Sections
   ========================================================================== */
/**
 * Set core body defaults.
 */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd,
button,
input,
optgroup,
select,
textarea {
  margin: 0;
}

/**
 * Render the `main` element consistently in IE.
 */
main {
  display: block;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
   ========================================================================== */
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Text-level semantics
   ========================================================================== */
/**
 * Remove the gray background on active links in IE 10.
 */
a {
  background-color: transparent;
  text-decoration: none;
}

/**
 * 1. Remove the bottom border in Chrome 57-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
   ========================================================================== */
/**
 * Remove the border on images inside links in IE 10.
 */
img {
  border-style: none;
}

/* Forms
   ========================================================================== */
/**
 * 1. Change the font styles in all browsers.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type=button],
[type=reset],
[type=submit] {
  appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline;
}

/**
 * Remove the default vertical scrollbar in IE 10+.
 */
textarea {
  overflow: auto;
}

/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */
[type=checkbox],
[type=radio] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */
[type=search]::-webkit-search-decoration {
  appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/* Interactive
   ========================================================================== */
/*
 * Add the correct display in Edge, IE 10+, and Firefox.
 */
details {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/* Misc
   ========================================================================== */
/**
 * Add the correct display in IE 10+.
 */
template {
  display: none;
}

/**
 * Add the correct display in IE 10.
 */
[hidden] {
  display: none;
}

/* Remove list styles, margin and padding on ul, ol elements, which suggests default styling will be removed */
ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

.header {
  padding: 8px 0;
  background-color: #ffffff;
  border-bottom: 2px solid #009F9F;
}
.header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.header__link {
  color: inherit;
}
.header__link--faq {
  display: flex;
  align-items: center;
  margin: 0 8px 0 auto;
  border: 0;
  max-width: none;
}
.header__link:visited {
  color: #1C2432;
}
.header__link:hover {
  color: #6CD0BF;
  opacity: 0.7;
}
.header__link:active {
  opacity: 1;
}
.header__trigger {
  padding: 8px 16px;
  font-size: 0;
  border-radius: 20px;
  border: 2px solid #1C2432;
  outline: none;
  width: 60px;
  height: 40px;
  background: transparent url("../images/icons/burger.svg") no-repeat 50%;
  background-size: 24px;
  transition: 0.2s ease-in-out;
}
.header__trigger.active {
  background: #009F9F url("../images/icons/close.svg") no-repeat 50%;
  border: 2px solid #A1E3D8;
}

@media (min-width: 768px) {
  .header__container {
    justify-content: flex-start;
  }
  .header__link {
    display: none;
  }
  .header__link:active {
    color: #6CD0BF;
    opacity: 0.7;
  }
  .header__trigger {
    display: none;
  }
}
.menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu--disabled {
  pointer-events: none;
}
.menu--header {
  flex-wrap: wrap;
  position: absolute;
  left: 0;
  top: 100%;
  opacity: 0;
  transform: translateY(-200%);
  background-color: #ffffff;
  z-index: 1000;
  width: 100vw;
  padding: 40px 12px 28px 12px;
  min-width: 320px;
  transition: 0.3s transform, opacity 0.3s ease-in-out;
  box-shadow: 0 8px 40px rgba(0, 0, 0, 0.1);
}
.header__trigger.active + .menu--header {
  transform: translateY(10px);
  opacity: 1;
}
.menu--section {
  flex-wrap: wrap;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 8px;
}
.menu--section::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.menu--section-1 {
  flex-wrap: wrap;
  gap: 16px;
  margin: 40px 0;
}
.menu--receipt {
  position: relative;
  width: 90%;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 32px auto 0 auto;
}
.menu__item {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  font-style: normal;
  width: 100%;
  white-space: nowrap;
  border: 2px solid #009F9F;
  border-radius: 8px;
  margin-bottom: 16px;
}
.menu__item--faq {
  display: none;
}
.menu__item--history {
  position: absolute;
  bottom: -80px;
  left: 0;
}
.menu--section .menu__item {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 16px;
  line-height: 21px;
  font-weight: 500;
  font-style: normal;
  display: flex;
  align-items: center;
  color: #1C2432;
  padding: 5px 16px;
  text-align: center;
  column-gap: 8px;
  background-color: #ffffff;
  border-radius: 24px;
  white-space: nowrap;
  border: 1px solid #A1E3D8;
  cursor: pointer;
  transition: color 0.2s, background-color 0.2s ease-in-out;
  margin: 0 auto;
  width: 80%;
}
.menu--section .menu__item:not(:last-of-type) {
  margin-bottom: 24px;
}
.menu--section .menu__item:active {
  background-color: #009F9F;
  color: #ffffff;
  border: 1px solid #ffffff;
}
.menu--section-1 .menu__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  column-gap: 8px;
  color: #ffffff;
  background-color: #273145;
  padding: 16px;
  border-radius: 20px;
  border: 1px solid #1C2432;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
.menu--section-1 .menu__item:active {
  background-color: #009F9F;
  color: #ffffff;
  border: 1px solid #ffffff;
}
.menu--receipt .menu__item {
  border: none;
}
.menu--receipt .menu__item:not(.menu__item--history) {
  width: 49%;
  margin-bottom: 8px;
}
.menu__link {
  display: block;
  color: inherit;
  line-height: 32px;
  text-align: center;
  padding: 8px;
  margin-left: auto;
  margin-right: auto;
  transition: color 0.2s, background-color 0.2s, border-color 0.2s, opacity 0.2s ease-in-out;
}
.menu__link:active {
  color: #6CD0BF;
  opacity: 0.7;
}
.menu__link:visited {
  color: #1C2432;
}

@media (min-width: 576px) {
  .menu--section {
    margin: 0 auto;
    max-width: 465px;
  }
  .menu--section-1 {
    flex-direction: row;
    column-gap: 2%;
  }
  .menu--section-1 .menu__item {
    width: 32%;
  }
}
@media (min-width: 768px) {
  .menu--header {
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    position: static;
    transition: transform 0s;
    column-gap: 14px;
    padding: 0;
    transform: none;
    box-shadow: none;
    opacity: 1;
    width: 100%;
    min-width: auto;
    margin-left: 14px;
  }
  .header__trigger.active + .menu--header {
    transform: none;
  }
  .menu--section {
    justify-content: flex-start;
    margin: -40px 0 0 0;
    padding: 0;
  }
  .menu--section-1 {
    justify-content: space-between;
    column-gap: 0;
  }
  .menu--receipt {
    width: 100%;
  }
  .menu__item {
    width: max-content;
    padding: 0;
    border-radius: 0;
    border: 0;
    margin: 0;
  }
  .menu__item--faq {
    margin-left: auto;
    padding-right: 14px;
    border-right: 1px solid #C4C4C4;
  }
  .menu__item--login {
    display: block;
  }
  .menu--header .menu__item {
    display: block;
  }
  .menu--section .menu__item {
    font-size: 12px;
    line-height: 32px;
    padding: 4px 12px;
    width: auto;
    flex-wrap: nowrap;
  }
  .menu--section .menu__item:not(:last-of-type) {
    margin-bottom: 0;
    margin-right: 8px;
  }
  .menu--section-1 .menu__item {
    width: 165px;
  }
  .menu__link {
    padding: 0;
  }
  .menu__item--faq .menu__link {
    font-size: 0;
  }
  .menu__item--login .menu__link {
    border: 2px solid #009F9F;
    color: #009F9F;
    border-radius: 20px;
    padding: 5px 12px;
  }
  .menu__item--registration .menu__link {
    background-color: #009F9F;
    color: #ffffff;
    border-radius: 20px;
    padding: 5px 12px;
    border: 2px solid #009F9F;
  }
  .menu__item--registration .menu__link:active {
    background-color: #6CD0BF;
    color: #1C2432;
    border: 2px solid #1C2432;
  }
  .menu__link--faq {
    display: none;
  }
}
@media (min-width: 992px) {
  .menu--header {
    column-gap: 24px;
    margin-left: 24px;
  }
  .menu--section-1 {
    justify-content: flex-start;
  }
  .menu__item {
    font-size: 16px;
  }
  .menu__item--logo, .menu__item--faq {
    padding-right: 24px;
  }
  .menu__item--login {
    margin-left: 0;
  }
  .menu__item--history {
    left: auto;
    right: 0;
  }
  .menu--header .menu__item--issues {
    display: block;
  }
  .menu--section .menu__item {
    font-size: 16px;
  }
  .menu--section .menu__item:not(:last-child) {
    margin-right: 16px;
  }
  .menu--section .menu__item:hover {
    background-color: #009F9F;
    color: #ffffff;
    border: 1px solid #ffffff;
  }
  .menu--section-1 .menu__item {
    width: 176px;
    margin-right: 12px;
  }
  .menu--section-1 .menu__item:hover {
    background-color: #009F9F;
    color: #ffffff;
    border: 1px solid #ffffff;
  }
  .menu--receipt .menu__item {
    margin-bottom: 0;
  }
  .menu--receipt .menu__item:not(.menu__item--history) {
    width: 150px;
    margin-right: 8px;
  }
  .menu__link:hover {
    color: #6CD0BF;
    opacity: 0.7;
  }
  .menu__link:active {
    color: #009F9F;
    opacity: 1;
  }
  .menu__item--faq .menu__link {
    font-size: 16px;
  }
  .menu__item--login .menu__link:active {
    background: none;
    color: #1C2432;
    border: 2px solid #1C2432;
    opacity: 1;
  }
  .menu__item--registration .menu__link:hover {
    background-color: #6CD0BF;
    color: #1C2432;
    border: 2px solid #6CD0BF;
    opacity: 1;
  }
  .menu__item--registration .menu__link:active {
    background-color: #6CD0BF;
    color: #1C2432;
    border: 2px solid #1C2432;
  }
}
.section {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 auto;
}
.section__cover {
  display: flex;
  width: 100%;
  min-height: 180px;
}
.main--faq .section__cover, .main--authorization .section__cover, .main--registration .section__cover, .main--settings .section__cover, .main--issue .section__cover, .main--obligation .section__cover, .main--e-currency .section__cover, .main--check .section__cover {
  background: url("../images/cover.jpg") no-repeat 0 0;
  background-size: cover;
  padding: 32px 0;
}
.main--receipts .section__cover {
  background: url("../images/receipts-cover.jpg") no-repeat 0 0;
  background-size: cover;
  padding: 32px 0;
}
.main--index .section--1 {
  padding-top: 150px;
  padding-bottom: 150px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.64) 0%, rgba(255, 255, 255, 0) 100%), url("../images/index-bg.png") no-repeat;
  background-color: #E8EFF8;
  background-position: 50% 100%;
  background-size: cover;
}
.main--business .section--1 {
  padding: 88px 0;
  background: linear-gradient(112.95deg, rgba(255, 255, 255, 0.88) 0%, rgba(255, 255, 255, 0) 62.96%), url("../images/business-bg-1.png") no-repeat;
  background-color: #B3D5DD;
  border-bottom: 1px solid #ffffff;
  background-size: cover;
  background-position: 100% 100%;
}
.main--receipts .section--1 {
  background: #1C2432 url("../images/business-bg-1.png") no-repeat;
  padding-bottom: 16px;
  border-bottom: 1px solid #ffffff;
  background-size: cover;
  background-position: -754px 0;
}
.main--index .section--2 {
  padding-top: 64px;
  padding-bottom: 40px;
  margin-bottom: 40px;
}
.main--business .section--2 {
  padding-top: 80px;
  padding-bottom: 90px;
}
.section--separate {
  padding: 32px;
  overflow: hidden;
  background-image: radial-gradient(55.39% 191.09% at 46.04% 50.2%, #009F9F 0%, #20B4AB 100%);
  background-position: 100%;
}
.section--separate::before, .section--separate:after {
  content: "";
  display: block;
  position: absolute;
  width: 200px;
  height: 100%;
}
.section--separate::before {
  left: -50px;
  top: -46px;
  background: url("../images/separate-block-bg-1.svg") no-repeat 50%;
  background-size: contain;
}
.section--separate::after {
  background: url("../images/separate-block-bg-2.svg") no-repeat 50%;
  background-size: contain;
  right: -50px;
  bottom: -46px;
}
.section--3 {
  padding-top: 80px;
  padding-bottom: 125px;
}
.main--index .section--3 {
  background: linear-gradient(100.81deg, #E8EFF8 0%, #FFFFFF 100%);
}
.main--business .section--3 {
  background: url("../images/business-bg.png") no-repeat 50%;
  background-size: cover;
}
.section--4 {
  padding-top: 80px;
  padding-bottom: 80px;
  overflow: hidden;
}
.main--index .section--4 {
  background-color: #009F9F;
}
.main--business .section--4 {
  row-gap: 64px;
}
.section__title {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 32px;
  line-height: 41px;
  font-weight: 800;
  font-style: normal;
  margin-bottom: 56px;
}
.section--1 .section__title {
  color: #1C2432;
  margin-bottom: 35px;
}
.main--index .section--2 .section__title, .main--index .section--3 .section__title {
  color: #1C2432;
  text-align: center;
}
.main--business .section--3 .section__title, .main--index .section--4 .section__title {
  color: #ffffff;
}
.main--business .section--3 .section__title {
  text-align: center;
}
.main--faq .section__title, .main--operations .section__title {
  display: block;
  margin: auto 0 auto;
}
.main--authorization .section__cover .section__title, .main--registration .section__cover .section__title, .main--settings .section__cover .section__title, .main--authorization .section__content .section__title, .main--issue .section__cover .section__title, .main--issue .section__content .section__title, .main--obligation .section__cover .section__title, .main--obligation .section__content .section__title, .main--e-currency .section__cover .section__title, .main--e-currency .section__content .section__title, .main--check .section__cover .section__title, .main--check .section__content .section__title {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 26px;
  line-height: 31px;
  font-weight: 700;
  font-style: normal;
}
.main--authorization .section__cover .section__title, .main--registration .section__cover .section__title, .main--settings .section__cover .section__title, .main--issue .section__cover .section__title, .main--obligation .section__cover .section__title, .main--e-currency .section__cover .section__title, .main--check .section__cover .section__title, .main--check .section__content .section__title {
  margin: auto 0;
  max-width: 810px;
}
.main--authorization .section__content .section__title, .main--issue .section__content .section__title {
  margin-bottom: 8px;
}
.main--obligation .section__content .section__title, .main--e-currency .section__content .section__title, .main--check .section__content .section__title {
  margin-bottom: 32px;
}
.section__link--button {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
  font-style: normal;
  display: block;
  width: max-content;
  padding: 14px 16px;
  background-color: #009F9F;
  color: #ffffff;
  border-radius: 8px;
  transition: 0.2s ease-in-out;
}
.section__link--button:active {
  background-color: #A1E3D8;
}
.section__link--button:visited {
  color: #ffffff;
}
.section__link--inline {
  margin-right: 4px;
  text-decoration: underline;
}
.main--index .section__link--inline {
  color: #1C2432;
}
.main--business .section__link--inline {
  color: #009F9F;
}
.section__paragraph--small .section__link {
  position: relative;
  padding-left: 32px;
}
.section__paragraph--small .section__link::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background: url("../images/icons/question-1.svg") no-repeat 50%;
  background-size: cover;
  width: 24px;
  height: 24px;
}
.main--index .section__block {
  position: relative;
  backdrop-filter: blur(12px);
  background-color: #009F9F;
  overflow: hidden;
  border-radius: 24px;
  position: relative;
  z-index: 2;
  margin-bottom: 64px;
}
.main--business .section--1 .section__block, .main--business .section--4 .section__block {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  border-radius: 24px;
}
.main--business .section--1 .section__block {
  margin: 40px auto 0 auto;
}
.main--business .section--4 .section__block {
  background: linear-gradient(180deg, #10151D 0%, #1C2432 100%);
  position: relative;
  overflow: hidden;
  max-width: 1892px;
  margin: 0 auto;
}
.main--business .section--4 .section__block::before, .main--business .section--4 .section__block::after {
  content: "";
  display: block;
  position: absolute;
  width: 202px;
  height: 233px;
  background: url("../images/shape-4.svg") no-repeat 50%;
}
.main--business .section--4 .section__block::before {
  top: -130px;
  left: -60px;
}
.main--business .section--4 .section__block::after {
  top: 0px;
  left: -139px;
}
.section__row {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.section--1 .section__paragraph {
  margin-bottom: 40px;
}
.section--2 .section__paragraph, .section--3 .section__paragraph {
  margin-bottom: 40px;
}
.section--4 .section__paragraph {
  color: #ffffff;
}
.section--4 .section__paragraph:not(:last-child) {
  margin-bottom: 24px;
}
.section--separate .section__paragraph {
  color: #ffffff;
  max-width: 875px;
  margin: 0 auto;
  text-align: center;
}
.section__tile .section__paragraph {
  margin-top: 0;
  margin-bottom: 0;
  color: #1C2432;
}
.section__block .section__paragraph {
  position: relative;
  color: #ffffff;
  margin: 0;
  z-index: 2;
}
.section__block .section__paragraph:first-of-type {
  margin-bottom: 24px;
}
.main--authorization .section__paragraph, .main--issue .section__paragraph {
  margin-bottom: 32px;
}
.section__paragraph--small {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-style: normal;
}
.section__block .section__paragraph--small {
  padding: 0 24px 24px 24px;
}
.section__paragraph--medium {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  font-style: normal;
}
.main--business .section--3 .section__paragraph--medium {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  font-style: normal;
  color: #ffffff;
  text-align: center;
}
.section__paragraph--large {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  font-style: normal;
}
.section__block .section__paragraph--large {
  padding: 24px;
}
.main-index .section--3 .section__paragraph--large {
  margin-bottom: 80px;
}
.section__tab {
  display: flex;
  align-items: center;
  padding: 24px;
  color: #1C2432;
  border-radius: 24px;
  cursor: pointer;
}
.main--index .section__tab {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  font-style: normal;
  justify-content: center;
  column-gap: 8px;
  background-color: #ffffff;
}
.main--index .section__tab:nth-of-type(1) {
  margin-top: 32px;
  margin-bottom: 32px;
  color: #2266CC;
}
.main--business .section__tab {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 20px;
  line-height: 25px;
  font-weight: 400;
  font-style: normal;
  background-color: rgba(255, 255, 255, 0.8);
  justify-content: flex-start;
  color: #1C2432;
  backdrop-filter: blur(12px);
}
.main--business .section__tab:not(:last-child) {
  margin-bottom: 16px;
}
.main--authorization .section__tab {
  border-radius: 8px;
  padding: 10px;
  justify-content: center;
  border: 1px solid #D9D9D9;
}
.section__tab:last-child {
  flex-wrap: wrap;
}
.main--index .section__tab:nth-of-type(2) .section__text, .main--authorization .section__text {
  font-size: 22px;
  color: #1C2432;
  margin-bottom: 8px;
  width: 100%;
  text-align: center;
}
.main--business .section__text {
  margin-left: 16px;
}
.section--4 .section__text {
  font-size: 18px;
  font-weight: 700;
}
.section__tile .section__text {
  display: block;
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  font-style: normal;
  color: #009F9F;
}
.section__container {
  display: flex;
  flex-direction: column;
}
.main--faq .section__container--1 {
  padding: 0 16px;
}
.main--authorization .section__container--1, .main--issue .section__container--1 {
  margin-top: 32px;
}
.main--authorization .section__container--1 {
  padding-right: 14px;
}
.main--issue .section__container--1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  padding-bottom: 32px;
}
.main--index .section__container--2, .main--index .section__container--4, .main--index .section__container--6 {
  position: relative;
  margin-bottom: 64px;
}
.main--index .section__container--2::before, .main--index .section__container--4::before, .main--index .section__container--6::before {
  content: "";
  display: block;
  position: absolute;
  height: 100%;
}
.main--index .section__container--2::before {
  left: 0;
  background: url("../images/shape-1.svg") no-repeat 50%;
  background-size: contain;
  width: 100%;
}
.main--authorization .section__container--2, .main--issue .section__container--2 {
  padding: 24px 14px;
  margin-top: 32px;
  border: 1px solid #D9D9D9;
  border-radius: 24px;
}
.section__container--4::before {
  right: 0;
  background: url("../images/shape-2.svg") no-repeat 0 50%;
  width: calc(100vh + 100%);
  background-size: 100% 75%;
}
.section__container--6::before {
  left: -4px;
  background: url("../images/shape-3.svg") no-repeat 55% 50%;
  background-size: contain;
  width: 100%;
}
.section__container--7 {
  margin: 40px auto;
  background: linear-gradient(180deg, rgb(255, 255, 255) 20%, rgb(233, 236, 239) 68%);
  border-radius: 24px;
  overflow: hidden;
}
.main--index .section--4 .section__container {
  position: relative;
}
.main--index .section--4 .section__container::before, .main--index .section--4 .section__container::after {
  content: "";
  display: block;
  position: absolute;
}
.main--index .section--4 .section__container::before {
  width: 278px;
  height: 321px;
  background: url("../images/shape-5.svg") no-repeat 50%;
  background-size: contain;
  right: -80px;
  bottom: 406px;
}
.main--index .section--4 .section__container::after {
  top: 50px;
  left: -139px;
  width: 406px;
  height: 469px;
  background: url("../images/shape-4.svg") no-repeat 50%;
  background-size: contain;
}
.main--receipts .section__container {
  margin: 0 auto;
}
.section__block .section__container {
  position: absolute;
  bottom: -42px;
  right: -3px;
  opacity: 0.6;
}
.main--obligation .section__container:not(.section__cover .section__container), .main--e-currency .section__container:not(.section__cover .section__container), .main--check .section__container:not(.section__cover .section__container) {
  margin-top: 32px;
  margin-bottom: 32px;
}
.section__image {
  width: 100%;
  margin: auto;
  z-index: 1;
}
.main--index .section__block .section__image {
  max-width: 392px;
}
.main--index .section--2 .section__image {
  max-width: 321px;
}
.section__wrapper .section__image {
  max-width: none;
}
.section__container--7 .section__image {
  margin: 0 0 0 auto;
}
.section__tile {
  position: relative;
  border-radius: 24px;
  padding: 24px;
  margin: 0 auto;
  margin-bottom: 24px;
}
.section__tile--1::before, .section__tile--2::before, .section__tile--3::before {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  transform: translateY(-50%);
  width: 4px;
}
.section__tile--1:first-of-type::before, .section__tile--2:first-of-type::before, .section__tile--3:first-of-type::before {
  height: 24px;
  bottom: -37px;
}
.section__tile--1:last-of-type::before, .section__tile--2:last-of-type::before, .section__tile--3:last-of-type::before {
  height: 80px;
  bottom: -120px;
}
.section__tile--1 {
  border: 1px solid #A1E3D8;
}
.section__tile--1::before {
  background-color: #A1E3D8;
}
.section__tile--2 {
  border: 1px solid #EEEEEE;
}
.section__tile--2::before {
  background-color: #EEEEEE;
}
.section__tile--3 {
  border: 1px solid #E8EFF8;
}
.section__tile--3::before {
  background-color: #E8EFF8;
}
.section__tile--4 {
  border: 1px solid #E8EFF8;
}
.section__tile--4::before {
  background-color: #E8EFF8;
}
.main--index .section--2 .section__tile {
  width: 100%;
  max-width: 390px;
}
.main--index .section--2 .section__tile, .main--index .section--3 .section__tile {
  background-color: #ffffff;
}
.main--index .section--4 .section__tile {
  background-color: #A1E3D8;
}
.section__heading {
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin-bottom: 24px;
}
.section--3 .section__heading {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  font-style: normal;
}
.section--4 .section__heading {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  font-style: normal;
}
.main--index .section--3 .section__heading, .main--business .section--2 .section__heading {
  color: #009F9F;
}
.main--index .section--4 .section__heading {
  color: #1C2432;
}
.section__item {
  display: flex;
  align-items: center;
  margin-top: 56px;
}
.section__inline {
  width: calc(100% - 64px);
  margin-left: 16px;
}
.section__wrapper {
  display: flex;
  height: 100%;
}
.main--index .section__content {
  margin: auto;
  width: 100%;
}
.main--business .section--4 .section__content {
  padding: 40px 40px 0 40px;
}
.main--registration .section__content, .main--authorization .section__content {
  padding: 40px 0 80px 0;
  width: 100%;
}
.main--settings .section__content {
  padding: 40px 0;
}
.main--issue .section__content {
  width: 100%;
}

@media (min-width: 576px) {
  .section__features {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
  }
  .section__block .section__paragraph--small {
    font-size: 14px;
    max-width: 330px;
  }
  .main--index .section--2 .section__paragraph {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .main--index .section__block .section__text {
    font-size: 31px;
    max-width: 282px;
    line-height: 42px;
  }
  .main--index .section__tab:nth-of-type(2) .section__text {
    width: 100%;
  }
  .section__container {
    align-items: flex-start;
  }
  .main--authorization .section__container--1, .main--issue .section__container--1 {
    height: calc(100% - 64px);
  }
  .main--authorization .section__container--1 {
    border-right: 1px solid #D9D9D9;
    padding-top: 24px;
  }
  .main--index .section__container--2, .main--index .section__container--4, .main--index .section__container--6 {
    margin-bottom: 0;
  }
  .section__container--3 {
    order: 2;
  }
  .section__container--7 {
    height: 100%;
  }
  .section__block .section__container {
    opacity: 1;
    right: -158px;
    top: 11px;
  }
  .main--obligation .section__container:not(.section__cover .section__container), .main--e-currency .section__container:not(.section__cover .section__container), .main--check .section__container:not(.section__cover .section__container) {
    padding-top: 24px;
  }
  .section__tile {
    margin: auto;
    margin-bottom: 20px;
    height: calc(100% - 24px);
  }
  .section__tile--1::before, .section__tile--2::before, .section__tile--3::before {
    left: auto;
    top: 50%;
    transform: translateY(-50%);
  }
  .section__tile--1:first-of-type::before, .section__tile--1:last-of-type::before, .section__tile--2:first-of-type::before, .section__tile--2:last-of-type::before, .section__tile--3:first-of-type::before, .section__tile--3:last-of-type::before {
    height: 4px;
  }
  .section__tile--1::before, .section__tile--3::before {
    left: 100%;
  }
  .section__tile--1::before, .section__tile--2::before {
    width: 210px;
  }
  .section__tile--2::before {
    left: auto;
    right: 100%;
  }
  .section__tile--3::before {
    width: 190px;
  }
  .main--index .section__tile:not(.section--3 .section__tile):not(.section--4 .section__tile) {
    max-width: 364px;
  }
  .main--index .section__block .section__image {
    max-height: none;
    max-width: 479px;
  }
  .section__features .section__image {
    margin: auto;
    max-width: 366px;
  }
  .section__tab {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .main--business .section--1 {
    background-position: 100% 100%;
    background-size: contain;
  }
  .main--index .section--1 {
    background-position: 100%;
  }
  .main--issue .section--1 {
    padding-right: 14px;
  }
  .main--index .section--2 {
    padding-top: 120px;
    margin-bottom: 0;
  }
  .section--separate {
    padding: 85px;
  }
  .section--separate::before, .section--separate:after {
    content: "";
    display: block;
    position: absolute;
    width: 500px;
    height: 500px;
  }
  .section--separate::before {
    left: -50px;
    top: auto;
    bottom: -135px;
  }
  .section--separate::after {
    top: -179px;
    right: -50px;
    bottom: auto;
  }
  .section--3 {
    padding-bottom: 80px;
  }
  .main--business .section--3 {
    max-width: none;
  }
  .section--4 {
    padding-top: 100px;
  }
  .section--2, .section__content {
    max-width: 1100px;
  }
  .section__content {
    margin: 0 auto;
    width: 100%;
  }
  .main--business .section--4 .section__content {
    padding: 64px 64px 0 64px;
  }
  .section__tile {
    padding: 24px;
    max-width: 528px;
  }
  .section__heading {
    position: relative;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 40px;
  }
  .section--3 .section__heading {
    padding-left: 109px;
  }
  .main--index .section__title {
    margin-top: 0;
  }
  .main--business .section--3 .section__title {
    text-align: center;
    max-width: 772px;
    margin: 0 auto 48px auto;
  }
  .section--1 .section__paragraph {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }
  .section--2 .section__paragraph {
    margin: 0;
  }
  .section--3 > .section__paragraph {
    font-family: "Inter", "Times New Roman", sans-serif;
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
    font-style: normal;
    margin-bottom: 160px;
  }
  .section__paragraph--small {
    font-size: 16px;
    line-height: 22px;
  }
  .section__block .section__paragraph--small {
    padding: 0 24px 24px 24px;
  }
  .main--index .section__block {
    overflow: unset;
  }
  .main--business .section--3 .section__block {
    max-width: 1392px;
  }
  .section__block .section__container {
    z-index: 1;
    top: -74px;
    right: -3px;
  }
  .main--index .section--4 .section__container::before {
    right: -80px;
    bottom: 279px;
  }
  .main--index .section--4 .section__container::after {
    top: 50px;
    left: -139px;
  }
  .main--authorization .section__container--1, .main--authorization .section__container--2 {
    padding: 40px;
  }
  .main--issue .section__container--1 {
    justify-content: space-between;
    border-right: 1px solid #D9D9D9;
    padding-right: 14px;
    padding-top: 24px;
  }
  .main--obligation .section__container:not(.section__cover .section__container) .main--e-currency .section__container:not(.section__cover .section__container) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .section__row {
    flex-direction: row;
  }
  .section__text:nth-of-type(1) {
    width: 100%;
    text-align: left;
  }
  .section__text:nth-of-type(2) {
    width: max-content;
    margin: 0 8px;
  }
  .section__tab .section__text {
    width: max-content;
    margin: 0 0 0 16px;
  }
  .main--index .section__tab:nth-of-type(2) .section__text {
    color: #1C2432;
    margin: 0;
    width: auto;
  }
  .section__tab {
    width: max-content;
  }
  .main--index .section__tab:nth-of-type(2) {
    width: max-content;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .main--business .section__tab {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .main--index .section--1 {
    height: 100vh;
  }
  .section--1 .section__title {
    max-width: 520px;
  }
  .section--3 .section__tile {
    padding: 32px 24px 32px 32px;
  }
  .main--index .section__tile:not(.section--3 .section__tile):not(.section--4 .section__tile) {
    max-width: none;
    width: 364px;
  }
  .main--index .section__heading {
    font-size: 26px;
    line-height: 32px;
  }
  .section__link--button:hover {
    color: #ffffff;
    background-color: #057777;
  }
  .section__link--button:active {
    color: #1C2432;
    background-color: #A1E3D8;
  }
  .section--1 .section__paragraph {
    justify-content: flex-start;
  }
  .section__paragraph--small {
    font-size: 20px;
    line-height: 25px;
  }
  .section__block .section__paragraph--small {
    padding: 24px 0 70px 115px;
    max-width: 356px;
  }
  .section__block .section__paragraph--large {
    padding: 48px 0 0 115px;
  }
  .section__tab {
    padding: 24px;
  }
  .main--index .section--4 .section__container::before {
    right: -162px;
    bottom: 380px;
  }
  .main--index .section--4 .section__container::after {
    top: -50px;
    left: -217px;
  }
  .section__block .section__container {
    top: -53px;
  }
  .main--authorization .section__container--1 {
    margin-top: 32px;
    padding: 40px 40px 0 0;
    height: 100%;
  }
  .main--issue .section__container--1 {
    padding-right: 40px;
    padding-top: 40px;
  }
  .main--issue .section__container--2 {
    padding: 40px;
  }
  .main--index .section__block .section__text {
    font-size: 42px;
    max-width: 386px;
    line-height: 51px;
  }
  .section--4 .section__text {
    display: block;
    margin-top: 56px;
    font-size: 30px;
    line-height: 38px;
  }
  .section__text:nth-of-type(2) {
    margin: 0 24px;
  }
  .main--business .section--4 .section__content {
    padding: 64px 0 64px 64px;
  }
  .main--settings .section__content {
    height: 100vh;
  }
  .main--obligation .section__content, .main--e-currency .section__content {
    padding-bottom: 230px;
  }
  .section__wrapper .section__image {
    margin: auto 0 0 0;
  }
  .main--index .section__block .section__image {
    max-height: none;
    max-width: 561px;
  }
}
@media (min-width: 1200px) {
  .section__title {
    font-size: 56px;
    line-height: 72px;
  }
  .main--business .section--4 .section__content {
    padding: 56px 0 56px 132px;
  }
  .main--index .section__heading {
    font-size: 30px;
    line-height: 36px;
  }
  .section__block .section__image {
    max-width: none;
  }
  .main--index .section--4 .section__container::after {
    top: -50px;
    left: -510px;
  }
}
.main--index .form {
  margin: 40px 0;
  padding: 24px;
  border: 1px solid rgba(255, 255, 255, 0.24);
  border-radius: 24px;
  box-shadow: 0 8px 40px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}
.main--authorization .form {
  width: 100%;
  margin: 0 auto;
  max-width: 400px;
}
.main--registration .form {
  padding: 0;
  border: 1px solid #EEEEEE;
  border-radius: 24px;
}
.main--settings .form, .main--obligation .form, .main--e-currency .form, .main--check .form {
  padding: 32px;
  border: 1px solid #EEEEEE;
  border-radius: 24px;
  width: 100%;
}
.main--obligation .form, .main--e-currency .form, .main--check .form {
  display: flex;
  flex-wrap: wrap;
}
.main--check .form:not(:last-of-type) {
  margin-bottom: 24px;
}
.main--settings .form {
  display: flex;
  flex-wrap: wrap;
  max-width: 548px;
}
.form__fieldset {
  position: relative;
}
.main--registration .form__fieldset {
  display: flex;
  flex-direction: column;
  padding: 16px 16px 0 16px;
}
.main--registration .form__fieldset:not(:first-of-type) {
  padding-bottom: 32px;
}
.main--registration .form__fieldset:not(:last-of-type) {
  border-bottom: 1px solid #EEEEEE;
}
.main--registration .form__fieldset:last-of-type {
  padding-bottom: 32px;
  margin-top: 0;
}
.form__container:not(:last-of-type) {
  border-bottom: 2px solid #EEEEEE;
  padding-bottom: 24px;
  margin-bottom: 24px;
}
.main--registration .form__container {
  width: 100%;
}
.main--obligation .form__container, .main--e-currency .form__container {
  display: flex;
  align-items: center;
  margin-top: 24px;
}
.form__title {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  font-style: normal;
  color: #1C2432;
}
.main--index .form__title, .main--settings .form__title {
  margin-bottom: 48px;
}
.main--registration .form__title, .main--obligation .form__title, .main--e-currency .form__title, .main--check .form__title {
  width: 100%;
  margin-bottom: 40px;
}
.form__name {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  font-style: normal;
  margin-top: 8px;
  margin-bottom: 40px;
}
.form__label {
  position: relative;
  display: flex;
  flex-direction: column;
}
.main--index .form__label, .main--registration .form__label {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  font-style: normal;
  color: #1C2432;
  width: 100%;
  margin-top: 24px;
}
.main--authorization .form__label {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  font-style: normal;
  color: #B8BABB;
  margin-top: 24px;
}
.main--obligation .form__label, .main--e-currency .form__label, .main--check .form__label {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  font-style: normal;
  color: #1C2432;
}
.main--check .form__label {
  margin-bottom: 8px;
  width: 100%;
}
.form__field > .form__label:not(:first-child) {
  margin-top: 24px;
}
.form__label--required::before {
  content: "";
  display: block;
  position: absolute;
  top: 36px;
  right: 8px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #F55624;
}
.form__label--disabled {
  cursor: auto;
  pointer-events: none;
}
.form__value {
  display: block;
  padding: 16px;
}
.form__button {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  font-style: normal;
  outline: none;
}
.form__button--bordered {
  padding: 8px 16px;
  border: 1px solid #1C2432;
  width: 60px;
  border-radius: 20px;
  background: none;
}
.main--registration .form__button--bordered {
  margin-top: 8px;
  width: auto;
}
.form__button--filled {
  padding: 16px;
  border-radius: 8px;
  background-color: #A1E3D8;
  border: none;
}
.main--obligation .form__button--filled, .main--e-currency .form__button--filled {
  margin-right: 24px;
}
.form__label:nth-of-type(2) .form__button {
  margin-top: 12px;
  width: 100%;
}
.form__field > .form__button {
  margin-top: 24px;
}
.form__field {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
}
.form__label:nth-of-type(2) .form__field {
  flex-direction: column;
}
.form__field--required::before {
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  right: 8px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #F55624;
}
.main--registration .form__field {
  width: 100%;
  margin-top: 8px;
}
.main--obligation .form__field, .main--e-currency .form__field {
  margin-bottom: 32px;
}
.main--check .form__field {
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}
.form__label + .form__field {
  margin-top: 8px;
}
.form__field > .form__field {
  margin: 0;
}
.form__input, .form__textarea {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 16px;
  line-height: 21px;
  font-weight: 600;
  font-style: normal;
  padding: 14px;
  background-color: #EEEEEE;
  border-radius: 8px;
  border: 2px solid rgba(255, 255, 255, 0.24);
  color: #1C2432;
  width: 100%;
  margin-top: 8px;
  outline: none;
  transition: 0.2s ease-in-out;
}
.form__input:focus, .form__textarea:focus {
  border: 2px solid #A1E3D8;
}
.form__textarea {
  resize: none;
  margin-bottom: 32px;
}
.form__label--disabled + .form__textarea {
  opacity: 0.3;
  pointer-events: none;
  cursor: auto;
}
.form__date, .form__time-zone {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
}
.form__date {
  margin: 32px 0 0 auto;
}
.main--e-currency .form__time-zone {
  display: none;
}
.form__submit {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 16px;
  line-height: 21px;
  font-weight: 500;
  font-style: normal;
  color: #ffffff;
  background: none;
  border: 1px solid #ffffff;
  border-radius: 24px;
  padding: 10px 16px;
  transition: 0.2s ease-in-out;
}

@media (min-width: 576px) {
  .main--registration .form__fieldset {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 32px 32px 0 32px;
  }
  .form__field .form__label {
    margin: 0;
  }
  .main--obligation .form__field .form__label {
    width: 50%;
  }
  .form__field .form__label:not(:first-child) {
    margin-left: 24px;
    margin-top: 0;
  }
  .main--e-currency .form__field:first-of-type .form__label:first-child {
    flex-grow: 1;
  }
  .form__field {
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
  }
  .main--registration .form__field {
    justify-content: space-between;
  }
  .main--obligation .form__field, .main--e-currency .form__field, .main--check .form__field {
    align-items: flex-end;
    justify-content: flex-start;
  }
  .main--check .form__field {
    margin-bottom: 24px;
  }
  .main--registration .form__button {
    margin-top: 0;
    margin-left: 8px;
  }
  .main--obligation .form__button--filled, .main--e-currency .form__button--filled {
    margin-left: 24px;
  }
  .form__time-zone {
    display: block;
    margin: auto 0 auto auto;
  }
}
@media (min-width: 768px) {
  .main--index .form {
    margin-top: 0;
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .form__submit:hover {
    background-color: #009F9F;
    color: #ffffff;
    border: 1px solid #ffffff;
  }
}
.tabs {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.tabs button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tabs button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tabs button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}

.tabs-form {
  width: 90%;
  margin: auto;
  box-shadow: 0 8px 40px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  padding: 32px 16px;
  background-color: #ffffff;
}
.tabs-form__title {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  font-style: normal;
  margin-bottom: 48px;
}
.tabs-form__field {
  display: flex;
  margin-top: 8px;
}
.tabs-form__field:not(:last-of-type) {
  margin-bottom: 32px;
}
.info__block--repayment .tabs-form__field {
  margin-bottom: 48px;
}
.tabs-form__label {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  font-style: normal;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}
.tabs-form__input {
  padding: 16px;
  width: 100%;
  border-radius: 8px;
  border: 2px solid #C4C4C4;
  margin-top: 8px;
}
.tabs-form__input::placeholder {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  font-style: normal;
}

@media (min-width: 576px) {
  .tabs-form {
    padding: 32px;
  }
}
.info__block {
  display: none;
  padding: 24px 0;
}
.info__block.active {
  display: block;
}
.info__item {
  padding: 24px 0;
  margin-bottom: 24px;
  border-top: 1px solid #C4C4C4;
  border-bottom: 1px solid #C4C4C4;
}
.info__top {
  display: flex;
  align-items: center;
}
.info__heading {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 24px;
  line-height: 31px;
  font-weight: 500;
  font-style: normal;
  color: #005555;
  padding-right: 24px;
  cursor: pointer;
}
.info__sub-heading {
  display: block;
  font-weight: 700;
  margin-bottom: 24px;
}
.info__button {
  padding: 8px;
  border: none;
  outline: none;
  background: none;
}
.info__button--copy {
  margin-right: 16px;
  margin-left: auto;
  transition: 0.2s ease-in-out;
}
.info__button--copy:active {
  color: #009F9F;
}
.info__button--dropdown {
  transform: rotate(-180deg);
  transition: 0.2s ease-in-out;
}
.info__button--dropdown.collapsed {
  transform: rotate(0);
}
.info__term {
  font-weight: 700;
}
.info__paragraph {
  margin-top: 40px;
}
.info__content .info__paragraph {
  margin-top: 24px;
}
.info__link {
  color: inherit;
  text-decoration: underline;
  margin: 0 4px;
}
.info__text {
  color: #009F9F;
}

@media (min-width: 768px) {
  .info__item {
    border: 1px solid #C4C4C4;
    border-radius: 16px;
    padding: 24px;
  }
  .info__block {
    margin: 16px 0;
  }
}
@media (min-width: 992px) {
  .info__button:hover {
    color: #009F9F;
  }
}
.main--receipts + .footer {
  background-color: #E8F0F1;
}
.footer__container {
  padding-top: 72px;
  padding-bottom: 32px;
  max-width: 1100px;
  border-top: 1px solid #D9D9D9;
}
.main--receipts + .footer .footer__container {
  border-top: 2px solid #009F9F;
  padding-bottom: 32px;
  padding-top: 48px;
}
.main--business + .footer .footer__container {
  border-top: 1px solid #D9D9D9;
}
.footer__form {
  margin-bottom: 80px;
}
.footer__search {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  font-style: normal;
  padding: 16px;
  border-radius: 8px;
  width: 100%;
  border: 2px solid transparent;
  outline: none;
  background-color: #EEEEEE;
  transition: 0.2s ease-in-out;
}
.footer__search:focus {
  border: 2px solid #009F9F;
}
.footer__list {
  display: flex;
  flex-wrap: wrap;
}
.footer__list--1 {
  flex-direction: column;
  margin-bottom: 80px;
}
.footer__list--2 {
  align-items: center;
  row-gap: 32px;
}
.footer__list--3 {
  align-items: center;
  row-gap: 32px;
  margin-top: 48px;
}
.footer__item {
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
.footer__list--1 .footer__item:not(:last-child) {
  margin-bottom: 32px;
}
.footer__list--2 .footer__item {
  padding: 7px 22px 7px 16px;
  border: 2px solid #009F9F;
  color: #009F9F;
  border-radius: 20px;
}
.footer__list--2 .footer__item:not(:last-child) {
  margin-right: 32px;
}
.footer__list--2 .footer__item:active {
  color: #1C2432;
  border: 2px solid #1C2432;
}
.footer__item--language {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  font-style: normal;
  display: flex;
  align-items: center;
  margin-right: auto;
}
.footer__item--copyright {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  font-style: normal;
  width: 100%;
}
.footer__link {
  display: block;
  transition: 0.2s ease-in-out;
}
.footer__list--1 .footer__link {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
  font-style: normal;
  text-decoration: underline;
  color: #1C2432;
}
.footer__list--1 .footer__link:active {
  color: #009F9F;
}
.footer__container > .footer__link {
  margin-bottom: 32px;
}
.footer__date {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-style: normal;
  color: #B8BABB;
}

@media (min-width: 576px) {
  .footer__container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .footer__list {
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .footer__list--1 {
    width: 100%;
  }
  .footer__list--2 {
    margin-left: auto;
  }
  .footer__list--3 {
    width: 100%;
  }
  .footer__list--1 .footer__item:not(:last-child) {
    margin-bottom: 0;
    margin-right: 32px;
  }
  .footer__container > .footer__link {
    margin: 0;
  }
}
@media (min-width: 992px) {
  .footer__list--1 {
    width: max-content;
    margin: 0;
  }
  .footer__list--2 {
    margin-left: 16px;
  }
  .footer__list--1 .footer__item:not(:last-child) {
    margin-bottom: 0;
  }
  .footer__list--1 .footer__item {
    margin-bottom: 0;
  }
  .footer__list--2 .footer__item:hover {
    opacity: 0.7;
  }
  .footer__list--2 .footer__item:active {
    color: #1C2432;
    border: 2px solid #1C2432;
    opacity: 1;
  }
  .footer__container > .footer__link {
    margin-left: auto;
  }
  .footer__list--1 .footer__link:hover {
    color: #009F9F;
    opacity: 0.7;
  }
  .footer__list--1 .footer__link:active {
    opacity: 1;
  }
}
.toggles {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 0;
  border-bottom: 2px solid #C4C4C4;
}
.toggles::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.toggles__item {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 20px;
  line-height: 25px;
  font-weight: 500;
  font-style: normal;
  display: flex;
  white-space: nowrap;
  padding: 20px 24px 20px 24px;
  color: #1C2432;
  border-top: 4px solid transparent;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}
.toggles__item:active {
  color: #009F9F;
}
.toggles__item.active {
  color: #009F9F;
  border-top: 4px solid #009F9F;
}

@media (min-width: 768px) {
  .toggles--modified {
    flex-direction: column;
    margin: 40px 0;
    border-right: 1px solid #C4C4C4;
    border-bottom: none;
  }
  .toggles--modified .toggles__item {
    border-top: none;
    border-left: 4px solid transparent;
    margin-top: 8px;
    white-space: normal;
  }
  .toggles--modified .toggles__item.active {
    color: #009F9F;
    border-top: none;
    border-left: 4px solid #009F9F;
    margin-left: 12px;
  }
}
@media (min-width: 992px) {
  .toggles__item:hover {
    color: #009F9F;
  }
}
.receipt {
  position: relative;
  width: 97%;
  height: auto;
  max-width: 452px;
  padding: 32px;
  box-sizing: border-box;
  border-radius: 24px 24px 0 0;
  background: #ffffff url("../images/receipt-bg.png") no-repeat 50% 50%;
  background-size: 100% 100%;
  z-index: 2;
}
.receipt::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -18px;
  left: 0;
  width: 100%;
  height: 20px;
  background: url("../images/side.png") no-repeat 50% 0;
  background-size: contain;
  z-index: 1;
}
.receipt--operation {
  padding-top: 64px;
}
.main--index .receipt {
  pointer-events: none;
  min-height: 663px;
  margin: 64px auto;
}
.main--receipts .receipt {
  margin: -130px auto 19px auto;
}
.receipt__operation {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  font-style: normal;
  color: #ffffff;
  background-color: #009F9F;
  position: absolute;
  top: -14px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 30px;
  text-align: center;
  border-radius: 4px;
}
.receipt__title {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 22px;
  line-height: 26px;
  font-weight: 700;
  font-style: normal;
  text-align: center;
  margin-top: 32px;
  margin-bottom: 0;
}
.receipt__container {
  position: relative;
  margin-top: 45px;
}
.receipt__container:not(:first-of-type) {
  padding-top: 40px;
}
.receipt__container:not(:first-of-type)::before {
  content: "";
  display: block;
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 78px);
  height: 30px;
  background: url("../images/line.svg") no-repeat 50% 50%;
  background-size: contain;
  z-index: 1;
}
.receipt__label {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  font-style: normal;
  color: #B8BABB;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 24px;
}
.receipt__label--in-line {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  font-style: normal;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  margin-top: 24px;
}
.receipt .receipt__label--in-line {
  color: #1C2432;
}
.receipt__link {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  font-style: normal;
  display: flex;
  flex-direction: column;
  color: #009F9F;
  margin: 8px auto 0 auto;
  transition: 0.2s ease-in-out;
}
.receipt__link:visited, .receipt__link:active {
  color: #009F9F;
}
.receipt__link:hover {
  color: #057777;
}
.receipt__link .receipt__status {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  font-style: normal;
  white-space: nowrap;
  color: #df6c78;
  background-color: #FFD2D7;
  border-radius: 4px;
  padding: 0 6px;
  margin-top: 8px;
}
.receipt__field {
  position: relative;
  display: flex;
  margin-top: 8px;
}
.receipt__balance {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 56px;
  line-height: 67px;
  font-weight: 600;
  font-style: normal;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
  text-align: center;
  gap: 8px;
  padding-bottom: 24px;
  margin-top: 24px;
  margin-bottom: 40px;
}
.receipt__balance::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -15px;
  width: calc(100% + 90px);
  height: 30px;
}
.main--receipts .receipt__balance::after {
  background: url("../images/line.svg") no-repeat 50% 50%;
  background-size: contain;
}
.main--index .receipt__balance::after {
  background: url("../images/line-index.svg") no-repeat 50% 50%;
  background-size: contain;
}
.receipt__date, .receipt__transaction, .receipt__sum {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
}
.receipt__date {
  font-weight: 700;
  color: #009F9F;
}
.receipt__table {
  margin-top: 24px;
  width: 100%;
}
.receipt__table--details {
  display: none;
  padding: 20px;
  background-color: #A1E3D8;
  border-radius: 8px;
}
.receipt__table--show {
  display: block;
}
.receipt__table-body {
  display: block;
}
.receipt__row {
  display: flex;
  justify-content: space-between;
}
.receipt__cell--details .receipt__row:not(:last-of-type) {
  margin-bottom: 20px;
}
.receipt__cell--details {
  display: block;
  width: calc(100% + 39px);
}
.receipt__cell:nth-of-type(2) {
  text-align: right;
}
.receipt__sum {
  font-weight: 500;
}
.receipt__currency {
  display: block;
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 22px;
  line-height: 26px;
  font-weight: 500;
  font-style: normal;
  width: 60px;
}
.receipt__actions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 8px;
  margin-top: 24px;
}
.receipt__button {
  color: #009F9F;
  background: none;
  outline: none;
  border: none;
  padding: 0;
}
.receipt__button--bordered {
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid #009F9F;
}
.receipt__table .receipt__button {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  font-style: normal;
  text-decoration: underline;
}
.receipt__actions .receipt__button {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  font-style: normal;
  display: flex;
  align-items: center;
  transition: 0.2s ease-in-out;
}
.receipt__actions .receipt__button:not(:last-child) {
  margin-right: 20px;
}
.receipt__actions .receipt__button:active {
  opacity: 0.7;
}
.receipt__note {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  font-style: normal;
  text-align: center;
  padding: 0 16px;
  margin-bottom: 24px;
}
.receipt__textarea {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 20px;
  line-height: 25px;
  font-weight: 500;
  font-style: normal;
  background-color: #EEEEEE;
  border-radius: 8px;
  border: 2px solid #EEEEEE;
  transition: 0.2s ease-in-out;
  outline: none;
  width: 100%;
  resize: none;
  padding: 16px;
  margin-top: 8px;
  overflow: hidden;
}
.receipt__textarea:focus {
  border: 2px solid #A1E3D8;
}
.receipt__value {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  font-style: normal;
  color: #273145;
  margin-top: 8px;
}
.receipt__field .receipt__value {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  line-height: 36px;
  color: #273145;
  padding: 16px;
  margin-left: 8px;
  margin-top: 0;
}
.receipt__container--invalid .receipt__value {
  color: #B8BABB;
}
.receipt__wrapper {
  display: flex;
  flex-wrap: nowrap;
}

@media (min-width: 576px) {
  .receipt {
    width: 100%;
  }
  .receipt__label {
    font-size: 16px;
  }
  .receipt__label--in-line {
    font-size: 14px;
  }
  .receipt__link {
    position: relative;
  }
  .receipt__link .receipt__status {
    position: absolute;
    left: 103%;
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0;
  }
}
@media (min-width: 768px) {
  .main--index .receipt {
    margin: 0 auto 89px auto;
  }
}
@media (min-width: 992px) {
  .receipt {
    width: 452px;
  }
  .receipt__button:hover {
    color: #006c6c;
  }
  .receipt__button:active {
    opacity: 0.7;
  }
}
.notification {
  position: relative;
  border-radius: 8px;
  width: 100%;
  text-align: left;
}
.receipt > .notification {
  margin: 24px auto;
}
.form + .notification, .section__container + .notification {
  margin-top: 32px;
}
.notification--default {
  font-weight: 400;
  padding: 16px 24px;
  background-color: #A1E3D8;
  color: #273145;
}
.form__title + .notification--default {
  margin: 0 0 32px 0;
}
.main--receipts .notification--default, .main--authorization .notification--default, .main--registration .notification--default {
  margin: 32px 0;
}
.main--e-currency .notification--default, .main--obligation .notification--default {
  margin: 16px 0;
}
.main--issue .notification--default {
  margin-top: 32px;
}
.notification--info {
  padding: 16px 16px 16px 48px;
}
.notification--info::before {
  content: "";
  display: block;
  position: absolute;
  left: 16px;
  top: 18px;
  background: url("../images/icons/info-2.svg") no-repeat 50%;
  width: 20px;
  height: 20px;
  background-size: contain;
}
.notification--error {
  padding: 16px 16px 16px 48px;
  background-color: #FFD2D7;
  color: #DC3545;
}
.receipt .notification--error {
  margin-top: 8px;
}
.main--e-currency .notification--error {
  margin: 16px 0;
}
.main--check .notification--error {
  margin-bottom: 24px;
}
.notification--error::before {
  content: "";
  display: block;
  position: absolute;
  left: 16px;
  top: 16px;
  background: url("../images/icons/info-error.svg") no-repeat 50%;
  width: 20px;
  height: 20px;
  background-size: contain;
}
.notification--mark {
  color: #009F9F;
  font-weight: 500;
}
.receipt__container .notification--mark {
  margin-bottom: 12px;
}
.receipt .notification--mark {
  margin-top: 8px;
}
.user .notification--mark {
  user-select: none;
  cursor: auto;
}
.notification--warning {
  font-weight: 400;
  padding: 16px 24px 16px 48px;
  background-color: #FFE4B3;
  color: #FFA500;
}
.main--e-currency .notification--warning, .receipt .notification--warning {
  margin: 16px 0;
}
.notification--warning::before {
  content: "";
  display: block;
  position: absolute;
  left: 16px;
  top: 16px;
  background: url("../images/icons/info-warning.svg") no-repeat 50%;
  width: 20px;
  height: 20px;
  background-size: contain;
}
.main--e-currency .notification--simple {
  margin-bottom: 32px;
}
.notification__text {
  display: block;
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  font-style: normal;
}
.notification--error .notification__text {
  text-align: left;
  font-weight: 800;
  max-width: 270px;
}
.notification--mark .notification__text {
  font-style: italic;
}
.receipt__container .notification--mark .notification__text {
  display: block;
  text-align: center;
  font-weight: 600;
}
.main--e-currency .notification--simple .notification__text {
  text-align: left;
}
.main--receipts .notification--simple .notification__text {
  text-align: center;
}
.notification__value {
  font-weight: 700;
}
.notification__link {
  color: #009F9F;
}
.main--registration .notification__link, .main--e-currency .notification__link {
  color: #1C2432;
  font-weight: 700;
  text-decoration: underline;
}
.notification--warning .notification__link {
  color: #FFA500;
  font-weight: 700;
  text-decoration: underline;
  margin: 0 4px;
}
.notification__item {
  margin-top: 16px;
  margin-left: 16px;
}
ul.notification__list .notification__item {
  list-style: disc;
}
ol.notification__list .notification__item {
  list-style: decimal;
}
.notification__item:last-child {
  margin-bottom: 16px;
}

@media (min-width: 1200px) {
  .notification {
    max-width: 330px;
  }
  .main--receipts .notification--simple {
    text-align: center;
    padding: 0 16px;
  }
  .notification--default {
    margin: 0;
  }
  .main--receipts .notification--default {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 80%;
  }
  .receipt > .notification--default {
    left: 102%;
  }
  .receipt__label > .notification--default {
    left: 113%;
  }
  .button + .notification--info {
    position: relative;
    margin-top: 24px;
  }
  .main--receipts .notification--error {
    position: absolute;
    top: 31px;
    left: 112%;
  }
  .main--receipts .notification--mark {
    position: static;
  }
  .main--receipts .notification--warning {
    position: absolute;
    top: 0;
    left: 112%;
    width: max-content;
    margin: 0;
  }
  .notification__link {
    cursor: pointer;
  }
  .notification__link:hover, .notification__link:active {
    color: #009F9F;
    text-decoration: underline;
  }
  .main--registration .notification__link:hover, .main--registration .notification__link:active {
    color: #1C2432;
  }
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main--receipts .pagination {
  width: 90%;
  margin: 24px auto 0 auto;
}
.main--issue .pagination {
  margin: 24px auto;
}
.pagination__block, .pagination__list {
  display: flex;
  align-items: center;
}
.pagination__block--left {
  flex-grow: 1;
}
.pagination__item:not(:last-of-type) {
  margin-right: 3px;
}
.pagination__item--more-previous, .pagination__item--more-next {
  display: none;
}
.pagination__item--show {
  display: block;
}
.pagination__link {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 14px;
  line-height: 15px;
  font-weight: 600;
  font-style: normal;
  display: block;
  border-radius: 8px;
  padding: 8px 0;
  border: 2px solid #009F9F;
  color: #009F9F;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s ease-in-out;
  user-select: none;
  min-height: 37px;
}
.pagination__link:visited {
  color: #009F9F;
}
.pagination__link:active {
  background-color: #057777;
  color: #ffffff;
  border-color: #057777;
}
.main--receipts .pagination__link {
  min-width: 34px;
}
.main--issue .pagination__link {
  min-width: 30px;
}
.pagination__item--disabled .pagination__link {
  color: #B8BABB;
  border-color: #B8BABB;
  cursor: auto;
  pointer-events: none;
}
.pagination__item--disabled .pagination__link:active {
  background: none;
  color: #B8BABB;
}
.pagination__item--selected .pagination__link {
  background-color: #057777;
  border-color: #057777;
  color: #ffffff;
}
.pagination__item--selected .pagination__link:active {
  background-color: #057777;
  border-color: #057777;
}
.pagination__item--reset .pagination__link {
  padding-top: 13px;
  margin: 0;
  border: none;
  pointer-events: none;
}
.pagination__total {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  font-style: normal;
  color: #009F9F;
  margin-right: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 118px;
  user-select: none;
}

@media (min-width: 576px) {
  .main--receipts .pagination {
    width: 100%;
  }
  .main--issue .pagination {
    margin: 32px 0 32px auto;
  }
  .main--receipts .pagination__link {
    font-size: 18px;
    line-height: 24px;
    padding: 8px 14px;
    min-width: 46px;
  }
  .pagination__item--disabled .pagination__link:hover {
    background: none;
    color: #B8BABB;
  }
  .pagination__total {
    font-size: 18px;
    line-height: 23px;
  }
}
@media (min-width: 768px) {
  .main--receipts .pagination {
    width: 100%;
    margin: 24px auto 0 auto;
  }
  .main--issue .pagination {
    margin: auto;
  }
  .main--authorization .pagination__link {
    font-size: 12px;
    line-height: 15px;
    padding: 8px;
  }
}
@media (min-width: 992px) {
  .main--issue .pagination {
    margin: auto 0;
  }
  .pagination__item:not(:last-of-type) {
    margin-right: 8px;
  }
  .pagination__link:hover {
    background-color: #057777;
    border-color: #057777;
    color: #ffffff;
  }
  .pagination__link:active {
    background-color: #057777;
    color: #ffffff;
  }
}
@media (min-width: 1200px) {
  .main--issue .pagination__link {
    font-size: 18px;
    line-height: 24px;
  }
  .pagination__link:not(.pagination__item--reset .pagination__link) {
    padding: 8px 16px;
  }
}
.popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(39, 49, 69, 0.3);
  z-index: 1000;
}
.popup__container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 452px;
  width: 90%;
  background-color: #ffffff;
  z-index: 1000;
  border-radius: 24px;
  padding: 36px 40px;
}
.popup__title {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 24px;
}

.table {
  padding: 16px;
  border-radius: 24px;
  border-spacing: 4px;
  border-collapse: separate;
  width: 100%;
}
.main--issue .table {
  margin-bottom: 0;
}
.table--issues {
  margin-top: 24px;
  border: 1px solid #EEEEEE;
}
.table--information {
  max-width: 440px;
  margin: 32px auto 0 auto;
}
.table--information:not(:first-of-type) {
  margin-bottom: 64px;
}
.section__container--1 .table--information {
  max-width: none;
  margin: 0;
}
.section__container--1 .table--information:not(:first-of-type) {
  margin-bottom: 0;
}
.table__cell {
  padding: 0;
  border: none;
  vertical-align: middle;
}
.table--issues .table__row--head .table__cell {
  text-align: center;
  border-radius: 8px;
  background-color: #EEEEEE;
}
.table--information .table__row--head .table__cell {
  padding-bottom: 24px;
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 16px;
  line-height: 28px;
  font-weight: 700;
  font-style: normal;
}
.table--issues .table__cell {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  font-style: normal;
}
.table--issues .table__cell:not(:first-child) {
  text-align: center;
}
.table--information .table__cell {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 10px;
  line-height: 20px;
  font-weight: 500;
  font-style: normal;
  padding-bottom: 0;
}
.table--information .table__cell:first-child {
  white-space: nowrap;
}
.table__link {
  color: #009F9F;
  transition: 0.2s ease-in-out;
}
.table__link:active, .table__link:visited {
  color: #009F9F;
}
.table__link:active {
  opacity: 0.6;
}

@media (min-width: 576px) {
  .table--information .table__cell, .table--issues .table__cell {
    font-size: 13px;
    line-height: 20px;
  }
  .table--information .table__row--head .table__cell {
    font-size: 20px;
  }
}
@media (min-width: 768px) {
  .main--issue .table {
    margin-bottom: 24px;
  }
  .table--information:not(:first-of-type) {
    margin-bottom: 64px;
  }
}
@media (min-width: 992px) {
  .table--information .table__row--head .table__cell {
    font-size: 26px;
  }
  .table__link:hover {
    color: #057777;
  }
}
.user {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 8px;
  position: relative;
}
.user__avatar {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
.user__identification {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 10px 0;
  transition: 0.2s ease-in-out;
}
.user__button {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-style: normal;
  background: none;
  outline: none;
  border: none;
  padding: 0;
  transition: 0.2s ease-in-out;
}
.user__button--confirm {
  display: flex;
  align-items: center;
  border: 2px solid #009F9F;
  color: #ffffff;
  border-radius: 24px;
  padding: 5px 6px 5px 12px;
  background-color: #009F9F;
  color: #ffffff;
  text-align: left;
  margin-left: 8px;
}
.user__button--confirm:active {
  background-color: #6CD0BF;
  color: #1C2432;
  border: 2px solid #1C2432;
}
.user__button--confirmed {
  position: relative;
}
.user__button--confirmed::before {
  content: "";
  display: block;
  position: absolute;
  background: #009F9F url("../images/icons/key-rounded.svg") no-repeat 50%;
  width: 20px;
  height: 20px;
  right: 0;
  bottom: -4px;
  background-size: cover;
  border-radius: 50%;
}
.user__button--logout {
  display: none;
}
.user__menu {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  background-color: #ffffff;
  width: 290px;
  right: 0;
  top: 100%;
  opacity: 0;
  transform: translateY(-200%);
  z-index: 1000;
  transition: 0.3s transform, opacity 0.3s ease-in-out;
  box-shadow: 0 8px 40px rgba(0, 0, 0, 0.1);
  padding: 16px;
  border-radius: 8px;
}
.active + .user__menu {
  transform: translateY(10px);
  opacity: 1;
}
.user__list {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.user__item {
  padding: 10px 0;
}
.user__link {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-style: normal;
  display: block;
  color: #1C2432;
  transition: color 0.2s ease-in-out;
  max-width: calc(100% - 35px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.user__link:visited {
  color: #1C2432;
}
.user__link:active {
  color: #6CD0BF;
}

@media (min-width: 768px) {
  .user {
    order: 1;
    margin-right: 0;
    margin-left: 8px;
  }
  .user__button--confirmed::before {
    width: 24px;
    height: 24px;
  }
  .user__button--logout {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    color: #009F9F;
    border: 2px solid #009F9F;
    border-radius: 24px;
    padding: 11px 13px;
    transition: color 0.2s, border-color 0.2s ease-in-out;
    margin-left: 8px;
  }
  .user__button--logout:active {
    color: #1C2432;
    border-color: #1C2432;
  }
  .user__avatar {
    margin-right: 8px;
  }
  .user__menu {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    position: static;
    box-shadow: none;
    transform: none;
    padding: 0;
    opacity: 1;
    transition: transform 0s;
    width: auto;
  }
  .active + .user__menu {
    transform: none;
  }
  .user__identification {
    padding: 0;
    height: 46px;
  }
  .user__list {
    display: none;
  }
  .user__link {
    max-width: 134px;
  }
}
@media (min-width: 992px) {
  .user {
    margin-left: 24px;
  }
  .user__menu {
    flex-wrap: nowrap;
  }
  .user__button {
    font-size: 16px;
    cursor: default;
  }
  .user__button--confirm {
    cursor: pointer;
    margin-left: 24px;
  }
  .user__button--confirm:hover {
    background-color: #6CD0BF;
    color: #1C2432;
    border: 2px solid #6CD0BF;
    opacity: 1;
  }
  .user__button--confirm:active {
    background-color: #6CD0BF;
    color: #1C2432;
    border: 2px solid #1C2432;
  }
  .user__button--logout {
    cursor: pointer;
    margin-left: 24px;
  }
  .user__button--logout:hover {
    color: #6CD0BF;
    opacity: 0.7;
  }
  .user__button--logout:active {
    color: #1C2432;
    border-color: #1C2432;
    opacity: 1;
  }
  .user__link {
    font-size: 16px;
  }
  .user .user__link:hover {
    color: #6CD0BF;
    opacity: 0.7;
  }
  .user .user__link:active {
    color: #009F9F;
    opacity: 1;
  }
}
.info__block--check .checkbox {
  margin-top: 24px;
}
.receipt .checkbox {
  margin-bottom: 24px;
}
.main--authorization .checkbox, .main--issue .checkbox {
  margin-bottom: 24px;
}
.main--registration .checkbox {
  margin-top: 24px;
}
.form__field + .checkbox {
  margin-top: 8px;
}
.checkbox__input {
  display: none;
}
.checkbox__label {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  font-style: normal;
  position: relative;
  padding-left: 33px;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  width: 100%;
  transition: color 0.15s ease-in-out;
}
.checkbox__label:hover, .checkbox__label:focus {
  outline: none;
}
.checkbox__label::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border: 2px solid #BABCBF;
  border-radius: 8px;
  width: 24px;
  height: 24px;
}
.checkbox__input:checked ~ .checkbox__label::after {
  background-size: 32px;
}
.checkbox__input:checked + .checkbox__label::after {
  border: 2px solid #009F9F;
  background-color: #009F9F;
}
.checkbox__link {
  margin-left: 4px;
  color: #009F9F;
  cursor: pointer;
}
.checkbox__link:hover {
  color: #009F9F;
  text-decoration: underline;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  transition: color 0.15s, background-color 0.15s ease-in-out;
}
.link--primary {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
  font-style: normal;
  border-radius: 8px;
  padding: 16px;
  background-color: #009F9F;
  color: #ffffff;
  text-align: center;
}
.link--primary:active {
  background-color: #A1E3D8;
  color: #273145;
}
.link--primary:hover {
  color: #ffffff;
}
.link__text {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.link:visited {
  color: #ffffff;
}

@media (min-width: 576px) {
  .link {
    flex-wrap: nowrap;
  }
}
@media (min-width: 992px) {
  .link--primary:hover {
    color: #ffffff;
    background-color: #057777;
  }
  .link--primary:active {
    background-color: #A1E3D8;
  }
}
.button {
  outline: none;
  border: none;
  user-select: none;
  transition: color 0.15s, background-color 0.15s, border-color 0.15s ease-in-out;
  color: #1C2432;
}
.button--success {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 19px;
  line-height: 26px;
  font-weight: 500;
  font-style: normal;
  border-radius: 8px;
  padding: 16px;
  background-color: #A1E3D8;
  color: #1C2432;
}
.info__block--check .button--success, .info__block--replenishment .button--success {
  margin-top: 24px;
}
.form .button--success {
  border: 1px solid #ffffff;
  margin-top: 48px;
}
.form .button--success:active {
  background-color: #009F9F;
  color: #ffffff;
}
.button--primary {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 17px;
  line-height: 26px;
  font-weight: 500;
  font-style: normal;
  border-radius: 8px;
  padding: 16px;
  background-color: #009F9F;
  color: #ffffff;
}
.button--primary:active {
  background-color: #A1E3D8;
  color: #273145;
}
.receipt .button--primary, .main--authorization .button--primary {
  width: 100%;
}
.receipt .button--primary {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.main--registration .button--primary {
  background-color: #A1E3D8;
  color: #1C2432;
  width: auto;
}
.main--registration .button--primary:active {
  background-color: #A1E3D8;
  color: #ffffff;
}
.button--secondary {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 19px;
  line-height: 26px;
  font-weight: 500;
  font-style: normal;
  border-radius: 8px;
  padding: 16px;
  background-color: #ffffff;
  border: 1px solid #BABCBF;
  color: #BABCBF;
}
.button--secondary:active {
  border: 1px solid #009F9F;
  color: #009F9F;
}
.main--settings .button--secondary {
  margin-top: 24px;
}
.main--receipts .button--secondary {
  width: 100%;
}
.button--tertiary {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 19px;
  line-height: 26px;
  font-weight: 500;
  font-style: normal;
  border-radius: 8px;
  padding: 16px;
  background-color: #2266CC;
  color: #ffffff;
}
.button--tertiary:active {
  background-color: #4281df;
}
.main--authorization .button--tertiary {
  width: 100%;
}
.button--rounded {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  font-style: normal;
  border-radius: 20px;
  padding: 10px 16px;
  background-color: #A1E3D8;
  color: #1C2432;
}
.tabs-form .button--rounded {
  width: 100%;
}
.tabs-form .button--rounded:not(:first-of-type) {
  margin-top: 8px;
}
.main--authorization .button--rounded, .main--issue .button--rounded {
  border-radius: 8px;
}
.main--receipts .button--rounded:active {
  background-color: #009F9F;
  color: #273145;
}
.button--bordered {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  font-style: normal;
  padding: 10px 16px;
  background-color: transparent;
  border: 2px solid #D9D9D9;
}
.main--receipts .button--bordered {
  border: 2px solid #009F9F;
  color: #009F9F;
}
.main--receipts .button--bordered:active {
  background-color: #009F9F;
  color: #ffffff;
}
.tabs-form .button--bordered {
  margin-top: 0;
  color: #273145;
  border: 2px solid #EEEEEE;
}
.form .button--bordered {
  font-size: 20px;
  color: #009F9F;
  border: 2px solid #009F9F;
  padding: 16px;
  width: 100%;
}
.main--registration .button--bordered {
  border-radius: 8px;
  border: 2px solid #1C2432;
  color: #1C2432;
  width: auto;
  margin-top: 16px;
}
.main--registration .button--bordered:active {
  background-color: #A1E3D8;
  color: #ffffff;
  border: 2px solid #ffffff;
}
.button--menu {
  padding: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  color: #1C2432;
}
.button--menu:active {
  opacity: 0.6;
}
.button--menu:hover, .button--menu:visited {
  color: #1C2432;
}
.button--colored-1 {
  background-color: #B3D5DD;
}
.button--active {
  border: 2px solid #009F9F;
}
.button--colored-2 {
  background-color: #CCECF0;
}
.button--colored-3 {
  background-color: #C5CDF6;
}
.button--colored-4 {
  background-color: #A1E3D8;
}
.button--colored-5 {
  background-color: #9EF0B5;
}
.button--colored-1, .button--colored-2, .button--colored-3, .button--colored-4, .button--colored-5 {
  max-height: 90px;
}
.button--show {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  transition: 0.15s ease-in-out;
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
  color: #B8BABB;
  padding: 0;
}
.button--show:active {
  color: #009F9F;
  opacity: 0.6;
}
.input--error + .button--show {
  color: #df6c78;
}
.input--error:focus + .button--show {
  color: #DC3545;
}
.input--error:focus + .button--show:hover {
  color: #009F9F;
}
.button--disabled {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
  font-style: normal;
  border-radius: 8px;
  padding: 16px;
  background-color: #d0f2eb;
  color: #ffffff;
}
.main--receipts .button--disabled {
  width: 100%;
}
.button--disabled:active {
  background-color: #d0f2eb;
  color: #ffffff;
}
.button--file {
  color: transparent;
  position: relative;
  cursor: pointer;
  width: 143px;
}
.button--file::-webkit-file-upload-button {
  display: none;
}
.button--file::before {
  content: "Выбрать";
  display: block;
  position: absolute;
  color: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.button--file:active {
  background-color: #A1E3D8;
  color: transparent;
}
.button--file:active::before {
  color: #1C2432;
}
.main--check .button--file {
  margin-left: 24px;
}
.button__text {
  text-align: left;
}
.button__icons {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.receipt .button:not(.receipt .button:first-of-type) {
  margin-top: 16px;
}
.main--authorization .button:not(:last-of-type), .main--issue .button:not(:last-of-type) {
  margin-bottom: 24px;
}
.button:visited {
  color: #1C2432;
}

@media (min-width: 576px) {
  .main--registration .button--bordered {
    margin-top: 0;
    margin-left: 16px;
  }
  .main--issue .section__container--1 .button--primary {
    width: auto;
    margin: auto auto auto 24px;
  }
  .main--obligation .form__field .button--primary, .main--e-currency .form__field .button--primary {
    margin-top: 0;
    margin-right: auto;
    margin-left: 24px;
    width: auto;
  }
  .main--obligation .form .button--primary, .main--e-currency .form .button--primary {
    width: auto;
  }
  .main--settings .button--primary {
    width: auto;
  }
  .receipt .button--primary {
    flex-wrap: nowrap;
  }
  .main--settings .button--secondary {
    margin-top: 0;
    margin-left: 24px;
    width: auto;
  }
  .main--check .form__field .button--file {
    margin-left: 24px;
  }
  .button__text {
    width: auto;
  }
}
@media (min-width: 768px) {
  .main--issue .button--primary {
    width: auto;
  }
  .main--issue .section__container--1 .button--primary {
    margin: auto auto auto 8px;
  }
}
@media (min-width: 992px) {
  .tabs-form .button--success {
    margin-top: 48px;
  }
  .form .button--success {
    border: 1px solid #ffffff;
  }
  .form .button--success:active {
    background-color: #009F9F;
    color: #ffffff;
  }
  .button--primary:hover {
    background-color: #057777;
  }
  .button--primary:active {
    background-color: #A1E3D8;
  }
  .main--registration .button--primary:hover {
    color: #ffffff;
  }
  .main--issue .section__container--1 .button--primary {
    margin: 0;
  }
  .button--secondary:hover {
    border: 1px solid #009F9F;
    color: #009F9F;
  }
  .button--secondary:active {
    border: 1px solid #273145;
    color: #273145;
  }
  .button--tertiary:hover {
    background-color: #1b50a0;
  }
  .button--tertiary:active {
    background-color: #4281df;
  }
  .receipt .button--bordered:hover {
    background-color: #009F9F;
    color: #ffffff;
  }
  .receipt .button--bordered:active {
    background-color: #A1E3D8;
    color: #273145;
    border-color: #A1E3D8;
  }
  .main--registration .button--bordered:hover {
    background-color: #057777;
    color: #ffffff;
    border-color: #057777;
  }
  .main--registration .button--bordered:active {
    background-color: #A1E3D8;
    border-color: #A1E3D8;
  }
  .tabs-form .button--rounded {
    margin-top: 0;
  }
  .tabs-form .button--rounded:last-of-type {
    position: relative;
    margin-left: 32px;
  }
  .tabs-form .button--rounded:last-of-type::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -18px;
    width: 2px;
    height: 40px;
    background-color: #EEEEEE;
  }
  .button--rounded:hover {
    background-color: #057777;
    color: #ffffff;
  }
  .button--rounded:active {
    background-color: #009F9F;
  }
  .main--authorization .section__container--2 .button--rounded, .main--issue .section__container--2 .button--rounded {
    padding: 6px 20px;
  }
  .main--authorization .section__container--2 .button--rounded:hover, .main--issue .section__container--2 .button--rounded:hover {
    color: #1C2432;
    background: none;
    border: 2px solid #009F9F;
  }
  .button--disabled:hover, .button--disabled:active {
    cursor: default;
    background-color: #d0f2eb;
    color: #ffffff;
  }
  .button--colored-1:hover {
    background-color: #6daebd;
  }
  .button--colored-2:hover {
    background-color: #7dcfd9;
  }
  .button--colored-3:hover {
    background-color: #6d81e8;
  }
  .button--colored-4:hover {
    background-color: #52ccb7;
  }
  .button--colored-5:hover {
    background-color: #46e272;
  }
  .button--show:hover {
    color: #009F9F;
  }
}
.icon {
  display: inline-block;
}
.section__features .icon {
  color: #1C2432;
  margin-bottom: 24px;
}
.menu--section .icon {
  margin-left: auto;
}
.menu__item--faq .icon {
  margin-left: 4px;
}
.footer__item .icon:not(.logo .icon) {
  margin-left: 12px;
}
.info__content .icon {
  margin-right: 16px;
}
.receipt .icon {
  display: block;
  margin: 0 auto;
}
.link .icon {
  margin: 0 4px;
}
.receipt__currency .icon {
  color: #2266CC;
  margin-bottom: 4px;
}
.checkbox__label .icon {
  position: absolute;
  top: 50%;
  left: -4px;
  transform: translateY(-50%);
  color: #ffffff;
  z-index: 2;
}
.menu--receipt .icon {
  margin-bottom: 8px;
}
.pagination .icon {
  margin: 0 auto;
  width: 13px;
  height: 16px;
}
.section--4 .icon:not(.receipt .icon) {
  color: #1C2432;
  z-index: 1;
}
.main--business .section__tab .icon {
  color: #2266CC;
}
.section__heading .icon {
  width: 48px;
  height: 48px;
}
.receipt__button .icon, .button--primary .icon {
  margin: 0 4px;
}
.form__button .icon {
  display: block;
  margin: auto;
}
.user__menu .icon {
  margin-right: 8px;
}
.user__button--confirm .icon {
  margin: 0;
}
.button .icon {
  margin-right: 4px;
}
.icon--1 {
  color: #AAEE11;
}
.icon--2 {
  color: #F55624;
}
.icon--3 {
  color: #33CCC3;
}
.icon--4 {
  color: #ffffff;
}

@media (min-width: 768px) {
  .main--index .section__heading .icon {
    position: absolute;
    left: 0;
    top: -35px;
    width: 90px;
    height: 90px;
  }
  .menu--section .icon {
    margin-left: 0;
  }
  .user__menu .icon {
    margin-right: 0;
  }
  .user__identification > .icon {
    display: none;
  }
}
@media (min-width: 992px) {
  .main--index .section__heading .icon {
    top: -45px;
    width: 100px;
    height: 100px;
  }
}
.custom-select {
  position: relative;
}
.custom-select__selected {
  display: flex;
  align-items: center;
  user-select: none;
  width: 100%;
}
.custom-select__selected::after {
  content: "";
  width: 16px;
  height: 8px;
  margin-left: 8px;
  background: url("../images/icons/dropdown.svg") no-repeat 50%;
  background-size: cover;
  transition: 0.2s ease-in-out;
}
.custom-select__selected:focus {
  outline: none;
}
.custom-select__name {
  display: block;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.custom-select__icon {
  margin-right: 8px;
}
.custom-select__dropdown {
  position: absolute;
  top: 105%;
  left: 0;
  right: 0;
  z-index: 99;
  overflow: auto;
  max-height: 350px;
  transition: background-color 0.15s ease-in-out;
  width: 100%;
  transition: 0.15s ease-in-out;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
}
.custom-select__menu {
  width: 100%;
}
.custom-select__item {
  display: flex;
  align-items: center;
  padding: 14px;
  cursor: pointer;
  transition: 0.15s ease-in-out;
  user-select: none;
}

.info__block--replenishment .custom-select {
  width: 100%;
  margin-left: 8px;
}
.receipt__field .custom-select {
  margin-left: 8px;
  min-width: 125px;
}
.receipt__label > .custom-select {
  margin-top: 8px;
  margin-left: 8px;
}
.main--registration .custom-select, .main--obligation .custom-select, .main--e-currency .custom-select {
  margin-top: 8px;
  width: 100%;
}
.footer .custom-select__selected::after {
  display: none;
}
.main--receipts .custom-select__selected, .main--obligation .custom-select__selected, .main--e-currency .custom-select__selected {
  border: 2px solid #EEEEEE;
}
.main--registration .custom-select__selected {
  background-color: #ffffff;
}
.receipt__label .custom-select__selected {
  padding: 16px 10px;
}
.pagination .custom-select__selected {
  padding: 8px 10px;
  border-radius: 8px;
  border: 2px solid #009F9F;
}
.pagination .custom-select__selected--active {
  border-radius: 8px 8px 0 0;
  border-bottom: 2px solid #A1E3D8;
}
.receipt__field .custom-select__selected, .receipt__label .custom-select__selected, .pagination .custom-select__selected, .main--obligation .custom-select__selected, .main--e-currency .custom-select__selected {
  color: #273145;
  background-color: #EEEEEE;
  border-radius: 8px;
}
.receipt__field .custom-select__selected--active, .receipt__label .custom-select__selected--active, .pagination .custom-select__selected--active, .main--obligation .custom-select__selected--active, .main--e-currency .custom-select__selected--active {
  border-radius: 8px 8px 0 0;
  background-color: #ffffff;
  border-top: 2px solid #A1E3D8;
  border-left: 2px solid #A1E3D8;
  border-right: 2px solid #A1E3D8;
}
.tabs-form__field .custom-select__selected {
  border-radius: 8px;
  border: 2px solid #EEEEEE;
  padding: 8px;
}
.main--obligation .custom-select__selected, .main--e-currency .custom-select__selected {
  padding: 10px 16px;
}
.footer .custom-select__selected {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  border: none;
  outline: none;
  padding: 8px;
  margin-left: 8px;
}
.main--registration .custom-select__selected {
  padding: 16px;
  border: 2px solid #EEEEEE;
  border-radius: 8px;
}
.main--receipts .custom-select__name, .main--obligation .custom-select__name, .main--e-currency .custom-select__name {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 14px;
  line-height: 34px;
  font-weight: 500;
  font-style: normal;
}
.footer .custom-select__name {
  flex-grow: 0;
  order: 1;
}
.footer .custom-select__icon {
  order: 2;
  margin-left: 8px;
  margin-right: 0;
  width: 24px;
  height: 24px;
}
.main--receipts .custom-select__dropdown, .pagination .custom-select__dropdown, .main--obligation .custom-select__dropdown, .main--e-currency .custom-select__dropdown {
  border-radius: 8px;
  background-color: #ffffff;
  border: 2px solid #A1E3D8;
}
.main--receipts .custom-select__dropdown::-webkit-scrollbar, .pagination .custom-select__dropdown::-webkit-scrollbar, .main--obligation .custom-select__dropdown::-webkit-scrollbar, .main--e-currency .custom-select__dropdown::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 0 8px 8px 0;
}
.main--receipts .custom-select__dropdown::-webkit-scrollbar-track, .pagination .custom-select__dropdown::-webkit-scrollbar-track, .main--obligation .custom-select__dropdown::-webkit-scrollbar-track, .main--e-currency .custom-select__dropdown::-webkit-scrollbar-track {
  border-radius: 0 8px 8px 0;
  background-color: #EEEEEE;
}
.main--receipts .custom-select__dropdown::-webkit-scrollbar-thumb, .pagination .custom-select__dropdown::-webkit-scrollbar-thumb, .main--obligation .custom-select__dropdown::-webkit-scrollbar-thumb, .main--e-currency .custom-select__dropdown::-webkit-scrollbar-thumb {
  border-radius: 0 8px 8px 0;
  background-color: #A1E3D8;
}
.main--registration .custom-select__dropdown, .footer .custom-select__dropdown {
  border-radius: 8px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
}
.tabs-form__field .custom-select__menu {
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 0px 24px -10px rgba(34, 60, 80, 0.2);
}
.footer .custom-select__menu {
  width: auto;
  background-color: #ffffff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.main--registration .custom-select__menu {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 24px -10px rgba(34, 60, 80, 0.2);
}
.receipt__field .custom-select__item, .receipt__label .custom-select__item, .tabs-form__field .custom-select__item, .pagination .custom-select__item {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 16px;
  line-height: 34px;
  font-weight: 500;
  font-style: normal;
  color: #273145;
  transition: 0.2s ease-in-out;
}
.receipt__field .custom-select__item:active, .receipt__label .custom-select__item:active, .tabs-form__field .custom-select__item:active, .pagination .custom-select__item:active {
  background-color: #A1E3D8;
}
.main--registration .custom-select__item:active {
  background-color: #A1E3D8;
}
.footer .custom-select__item {
  justify-content: space-between;
  padding: 8px;
}
.footer .custom-select__item:not(:last-child) {
  border-bottom: 1px solid #D9D9D9;
}
.pagination .custom-select__name {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 14px;
  line-height: 15px;
  font-weight: 500;
  font-style: normal;
  color: #009F9F;
}
.footer .custom-select__selected .custom-select__name, .footer .custom-select__item .custom-select__name {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-style: normal;
}
.footer .custom-select__selected .custom-select__name {
  color: #B8BABB;
}
.footer .custom-select__item .custom-select__name {
  color: #273145;
}
.main--registration .custom-select__name {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  font-style: normal;
  color: #1C2432;
}

.custom-select--show .custom-select__selected::after {
  transform: rotate(-180deg);
}
.main--receipts .custom-select--show .custom-select__selected, .main--obligation .custom-select--show .custom-select__selected, .main--e-currency .custom-select--show .custom-select__selected {
  border: 2px solid #A1E3D8;
}
.custom-select--show .custom-select__dropdown {
  z-index: 10000;
  visibility: visible;
  opacity: 1;
}

.custom-select--disabled .custom-select__selected,
.custom-select--disabled .custom-select__icon {
  opacity: 0.3;
  pointer-events: none;
}
.custom-select--disabled .custom-select__text {
  color: #B8BABB;
}

@media (min-width: 576px) {
  .info__block--replenishment .custom-select {
    width: auto;
    flex-basis: 200px;
  }
  .receipt__field .custom-select {
    min-width: auto;
  }
  .receipt__label .custom-select__selected {
    min-width: 155px;
    padding: 16px;
  }
  .custom-select__icon {
    width: 32px;
    height: 32px;
  }
  .receipt__field .custom-select__item, .receipt__label .custom-select__item, .tabs-form__field .custom-select__item, .pagination .custom-select__item {
    font-size: 20px;
  }
  .main--receipts .custom-select__name, .main--obligation .custom-select__name, .main--e-currency .custom-select__name {
    font-size: 20px;
  }
  .pagination .custom-select__name {
    font-size: 18px;
    line-height: 24px;
  }
  .footer .custom-select__name {
    min-width: 48px;
  }
}
@media (min-width: 768px) {
  .info__block--replenishment .custom-select {
    width: auto;
    flex-basis: 200px;
  }
}
@media (min-width: 992px) {
  .receipt__field .custom-select__item, .receipt__label .custom-select__item, .main--registration .custom-select__item, .pagination .custom-select__item {
    color: #273145;
    transition: 0.2s ease-in-out;
  }
  .receipt__field .custom-select__item:active, .receipt__field .custom-select__item:hover, .receipt__label .custom-select__item:active, .receipt__label .custom-select__item:hover, .main--registration .custom-select__item:active, .main--registration .custom-select__item:hover, .pagination .custom-select__item:active, .pagination .custom-select__item:hover {
    background-color: #A1E3D8;
  }
  .footer .custom-select {
    margin-left: 8px;
  }
}
.input {
  border-radius: 8px;
  outline: none;
  text-align: center;
  transition: 0.2s ease-in-out;
}
.receipt .input:not(.input--green):not(.receipt__label--in-line .input) {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 16px;
  line-height: 34px;
  font-weight: 500;
  font-style: normal;
}
.receipt .input, .popup .input, .main--obligation .input, .main--e-currency .input {
  color: #273145;
  border: 2px solid #EEEEEE;
  background-color: #EEEEEE;
  padding: 16px;
}
.receipt .input--crypto, .popup .input--crypto, .main--obligation .input--crypto, .main--e-currency .input--crypto {
  font-size: 16px;
}
.receipt__label--in-line .input {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  font-style: normal;
  margin-left: 8px;
  width: 20%;
  max-width: 75px;
  padding: 8px;
}
.popup .input {
  margin-bottom: 24px;
  width: 100%;
}
.tabs-form .input {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  font-style: normal;
  padding: 14px;
  width: 100%;
}
.main--authorization .input, .main--obligation .input, .main--e-currency .input, .main--check .input {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  font-style: normal;
  text-align: left;
  border: 2px solid #EEEEEE;
  background-color: #EEEEEE;
  color: #1C2432;
  width: 100%;
}
.main--authorization .input:not(.input--password), .main--obligation .input:not(.input--password), .main--e-currency .input:not(.input--password), .main--check .input:not(.input--password) {
  padding: 16px;
}
.main--authorization .input::placeholder, .main--obligation .input::placeholder, .main--e-currency .input::placeholder, .main--check .input::placeholder {
  color: #B8BABB;
}
.main--registration .input {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  font-style: normal;
  padding: 16px;
  border: 2px solid #EEEEEE;
  text-align: left;
  width: 100%;
}
.form__label--disabled .input {
  opacity: 0.3;
}
.receipt__value + .input {
  padding: 16px 62px;
}
.main--e-currency .input--time {
  width: 106px;
}
.form__label--disabled + .input--time {
  pointer-events: none;
  opacity: 0.3;
  cursor: auto;
}
.form__field > .input--time {
  margin-top: 24px;
}
.receipt .input--error, .popup .input--error, .main--obligation .input--error, .main--e-currency .input--error, .main--registration .input--error, .main--authorization .input--error {
  transition: 0.2s ease-in-out;
  border: 2px solid #df6c78;
  background-color: #FFD2D7;
  color: #df6c78;
}
.receipt .input--error:focus, .popup .input--error:focus, .main--obligation .input--error:focus, .main--e-currency .input--error:focus, .main--registration .input--error:focus, .main--authorization .input--error:focus {
  color: #DC3545;
  border-color: #DC3545;
}
.input--password {
  padding: 16px 64px;
}
.main--authorization .input--password {
  padding: 16px 64px 16px 16px;
}
.input--green {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  font-style: normal;
  background-color: #A1E3D8;
  color: #009F9F;
  border: 2px solid #A1E3D8;
  padding: 8px 24px;
}
.input--green:focus {
  border: 2px solid #009F9F;
}
.input::-webkit-outer-spin-button, .input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input:focus:not(input[readonly=true]):not(.input--green):not(.input--error) {
  outline: none;
  border: 2px solid #A1E3D8;
}
.receipt .input:not(.receipt__field .input):not(.receipt__label--in-line .input):not(.input--green) {
  width: 100%;
  margin-top: 8px;
}
.receipt__field .input {
  width: 100%;
}
.form__label > .input, .tabs-form__label > .input {
  margin-top: 8px;
}
.capcha + .input {
  order: 1;
}
.input[readonly=true] {
  cursor: auto;
}

@media (min-width: 576px) {
  .receipt .input:not(.input--green):not(.receipt__label--in-line .input) {
    font-size: 20px;
  }
  .receipt__label--in-line .input {
    font-size: 14px;
    width: calc(100% - 310px);
    padding: 8px 24px;
  }
  .info__block--replenishment .input {
    margin-right: 8px;
    margin-bottom: 0;
  }
  .capcha + .input {
    font-size: 11px;
    order: 2;
    width: calc(100% - 207px);
  }
  .form__field > .input--time {
    margin-top: 0;
    margin-left: 24px;
  }
  .main--e-currency .input--date {
    width: 240px;
  }
}
@media (min-width: 768px) {
  .capcha + .input {
    font-size: 16px;
  }
  .input--limited {
    max-width: 230px;
  }
}
.radio {
  display: flex;
  width: 100%;
}
.radio--disabled {
  pointer-events: none;
}
.tabs-form__label .radio {
  margin-top: 8px;
  flex-wrap: wrap;
}
.receipt .radio {
  flex-direction: column;
  margin-bottom: 32px;
}
.main--settings .radio {
  flex-direction: column;
}
.main--e-currency .radio {
  display: flex;
  flex-direction: column;
}
.receipt__actions .radio {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 16px;
  margin: 0;
}
.radio__field {
  display: flex;
  align-items: center;
}
.receipt .radio__field {
  width: 100%;
  margin-bottom: 16px;
}
.receipt__actions .radio__field {
  width: auto;
}
.main--settings .radio__field {
  margin-bottom: 32px;
}
.main--e-currency .radio__field {
  margin-bottom: 32px;
}
.radio__input {
  display: none;
}
.radio__label {
  color: #1C2432;
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  border-radius: 8px;
  margin-right: 8px;
  cursor: pointer;
  user-select: none;
}
.radio__label:hover, .radio__label:focus {
  outline: none;
}
.radio__label::before, .radio__label::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 10px;
  border-radius: 50%;
  transition: background-color 0.2s ease-in-out;
}
.radio__label::before {
  width: 16px;
  height: 16px;
  background-color: #ffffff;
}
.tabs-form__label .radio__label::before {
  border: 1px solid #1C2432;
}
.receipt .radio__label::before, .form .radio__label::before {
  border: 1px solid #D9D9D9;
}
.radio__input:checked + .radio__label::after {
  width: 8px;
  height: 8px;
}
.tabs-form__label .radio__input:checked + .radio__label::after {
  background-color: #1C2432;
}
.receipt .radio__input:checked + .radio__label::after, .form .radio__input:checked + .radio__label::after {
  background-color: #009F9F;
}
.radio--disabled .radio__input:checked + .radio__label::after {
  background-color: #B8BABB;
}
.tabs-form__label .radio__input:checked + .radio__label {
  color: #1C2432;
}
.receipt .radio__input:checked + .radio__label::before, .form .radio__input:checked + .radio__label::before {
  border: 1px solid #009F9F;
}
.radio--disabled .radio__input:checked + .radio__label::before {
  border: 1px solid #B8BABB;
}
.receipt .radio__text {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  font-style: normal;
}
.form .radio__text {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  font-style: normal;
}
.receipt__actions .radio__text {
  color: #1C2432;
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  font-style: normal;
}

@media (min-width: 576px) {
  .receipt__actions .radio {
    justify-content: space-around;
  }
  .form__field + .radio {
    flex-direction: row;
    justify-content: space-between;
  }
  .receipt__actions .radio__field {
    margin: 0;
  }
  .tabs-form__label .radio__field {
    width: max-content;
  }
  .tabs-form__label .radio__field:not(:first-of-type) {
    margin-top: 0;
    margin-left: 32px;
  }
  .main--e-currency .radio__field {
    margin-top: 0;
  }
  .main--e-currency .radio__field:not(:last-child) {
    margin-right: 16px;
  }
}
@media (min-width: 768px) {
  .radio__field:not(:first-of-type) {
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  .tabs-form__label .radio__field {
    width: max-content;
  }
  .tabs-form__label .radio__field :not(:first-of-type) {
    margin-top: 0;
    margin-left: 32px;
  }
}
.capcha {
  display: block;
  border-radius: 8px;
  background-color: #1C2432;
  width: 100%;
  height: 56px;
}
.main--registration .capcha {
  order: 2;
  margin-top: 8px;
}

@media (min-width: 576px) {
  .capcha {
    height: 56px;
    width: 195px;
  }
  .main--registration .capcha {
    order: 1;
    margin-top: 0;
  }
}
.datepicker {
  width: 100%;
  transition: opacity 0.2s, visibility 0.2s, z-index 0.2s ease-in-out;
}
.datepicker:not(.active) {
  z-index: -1;
  visibility: hidden;
  opacity: 0;
}
.datepicker.active {
  z-index: 10000;
  visibility: visible;
  opacity: 1;
}

.datepicker-dropdown {
  position: absolute;
  z-index: 20;
  padding-top: 4px;
}
.datepicker-dropdown.datepicker-orient-top {
  padding-top: 0;
  padding-bottom: 4px;
}

.datepicker-picker {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: #ffffff;
}
.datepicker-dropdown .datepicker-picker {
  border: 2px solid #A1E3D8;
}

.datepicker-main {
  flex: auto;
  padding: 2px;
}

.datepicker-footer {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  background-color: #f8f9fa;
  border-radius: 0 0 8px 8px;
}

.datepicker-title {
  box-shadow: inset 0 -1px 1px rgba(0, 0, 0, 0.1);
  background-color: #f8f9fa;
  padding: 0.375rem 0.75rem;
  text-align: center;
  font-weight: 700;
}

.datepicker-controls {
  display: flex;
}
.datepicker-header .datepicker-controls {
  padding: 2px 2px 0;
}
.datepicker-controls .button {
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin: 0;
  border: 1px solid #dbe0e5;
  border-radius: 8px;
  box-shadow: none;
  background-color: #ffffff;
  cursor: pointer;
  padding: calc(0.375em - 1px) 0.75em;
  height: 2.25em;
  vertical-align: top;
  text-align: center;
  line-height: 1.5;
  white-space: nowrap;
  color: #212529;
  font-size: 1rem;
}
.datepicker-controls .button:focus, .datepicker-controls .button:active {
  outline: none;
}
.datepicker-controls .button:hover {
  border-color: #afbbc6;
  color: #212529;
}
.datepicker-controls .button:focus {
  border-color: #A1E3D8;
  color: #212529;
}
.datepicker-controls .button:focus:not(:active) {
  box-shadow: 0 0 0 0.125em rgba(161, 227, 216, 0.25);
}
.datepicker-controls .button:active {
  border-color: #404040;
  color: #212529;
}
.datepicker-controls .button[disabled] {
  cursor: not-allowed;
}
.datepicker-header .datepicker-controls .button {
  border-color: transparent;
  font-weight: bold;
}
.datepicker-header .datepicker-controls .button:hover {
  background-color: #f9f9f9;
}
.datepicker-header .datepicker-controls .button:active {
  background-color: #f2f2f2;
}
.datepicker-footer .datepicker-controls .button {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 0.75rem;
  line-height: 24px;
  font-weight: 500;
  font-style: normal;
  flex: auto;
  margin: calc(0.375rem - 1px) 0.375rem;
  border-radius: 8px;
}
.datepicker-controls .view-switch {
  flex: auto;
}
.datepicker-controls .prev-button,
.datepicker-controls .next-button {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
  flex: 0 0 14.2857142857%;
}
.datepicker-controls .prev-button.disabled,
.datepicker-controls .next-button.disabled {
  visibility: hidden;
}

.datepicker-view,
.datepicker-grid {
  display: flex;
}

.datepicker-view {
  align-items: stretch;
  width: 100%;
}

.datepicker-grid {
  flex-wrap: wrap;
  flex: auto;
}

.datepicker .days {
  display: flex;
  flex-direction: column;
  flex: auto;
}
.datepicker .days-of-week {
  display: flex;
}
.datepicker .week-numbers {
  display: flex;
  flex-direction: column;
  flex: 0 0 9.6774193548%;
}
.datepicker .weeks {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: auto;
}
.datepicker span {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: default;
  -webkit-touch-callout: none;
  user-select: none;
}
.datepicker .dow {
  height: 1.5rem;
  font-size: 0.875rem;
  font-weight: 700;
}
.datepicker .week {
  flex: auto;
  color: #afbbc6;
  font-size: 0.75rem;
}

.datepicker-cell, .datepicker .days .dow {
  flex-basis: 14.2857142857%;
}

.datepicker-cell {
  height: 2.25rem;
}
.datepicker-cell:not(.day) {
  flex-basis: 25%;
  height: 4.5rem;
}
.datepicker-cell:not(.disabled):hover {
  background-color: #f9f9f9;
  cursor: pointer;
}
.datepicker-cell.focused:not(.selected) {
  background-color: #e9edf0;
}
.datepicker-cell.selected, .datepicker-cell.selected:hover {
  background-color: #A1E3D8;
  color: #fff;
  font-weight: 600;
}
.datepicker-cell.disabled {
  color: #dbe0e5;
}
.datepicker-cell.prev:not(.disabled), .datepicker-cell.next:not(.disabled) {
  color: hsl(0, 0%, 48%);
}
.datepicker-cell.prev.selected, .datepicker-cell.next.selected {
  color: #e6e6e6;
}
.datepicker-cell.highlighted:not(.selected):not(.range):not(.today) {
  border-radius: 0;
  background-color: #f8f9fa;
}
.datepicker-cell.highlighted:not(.selected):not(.range):not(.today):not(.disabled):hover {
  background-color: #f1f3f5;
}
.datepicker-cell.highlighted:not(.selected):not(.range):not(.today).focused {
  background-color: #e9edf0;
}
.datepicker-cell.today:not(.selected) {
  background-color: hsl(171, 100%, 41%);
}
.datepicker-cell.today:not(.selected):not(.disabled) {
  color: #fff;
}
.datepicker-cell.today.focused:not(.selected) {
  background-color: #00ccad;
}
.datepicker-cell.range-end:not(.selected), .datepicker-cell.range-start:not(.selected) {
  background-color: #afbbc6;
  color: #fff;
}
.datepicker-cell.range-end.focused:not(.selected), .datepicker-cell.range-start.focused:not(.selected) {
  background-color: #aab6c2;
}
.datepicker-cell.range-start:not(.range-end) {
  border-radius: 8px 0 0 8px;
}
.datepicker-cell.range-end:not(.range-start) {
  border-radius: 0 8px 8px 0;
}
.datepicker-cell.range {
  border-radius: 0;
  background-color: #dbe0e5;
}
.datepicker-cell.range:not(.disabled):not(.focused):not(.today):hover {
  background-color: #d4dae1;
}
.datepicker-cell.range.disabled {
  color: #c1cad3;
}
.datepicker-cell.range.focused {
  background-color: #ced5dc;
}

.datepicker-input {
  caret-color: transparent;
  cursor: pointer;
}
.datepicker-input.in-edit {
  border-color: #92dfd2;
}

.file-input {
  display: block;
  width: 100%;
}
.file-input .file-caption,
.file-input .fileinput-cancel {
  display: none;
}
.file-input .btn-primary {
  color: transparent;
  background-color: transparent;
  border: transparent;
  font-size: 0;
}
.file-input .btn-primary:hover {
  color: transparent;
  background-color: transparent;
  border: transparent;
}
.file-input .input-group .btn {
  padding: 0;
}

.page {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  min-width: 320px;
}
.page--fixed {
  overflow: hidden;
}
.page--headers {
  justify-content: space-between;
}

.main {
  flex: 1;
}
.main--receipts {
  background-color: #E8F0F1;
  padding-bottom: 130px;
}
.main__container {
  margin: 0 auto;
}
.main__content {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin: auto;
  max-width: 500px;
  padding: 64px;
}

.logo {
  display: block;
  width: max-content;
}
.receipt .logo {
  margin: 0 auto;
}
.receipt__currency .logo {
  margin: 0;
}

.reminder {
  font-family: "Inter", "Times New Roman", sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-style: normal;
  display: block;
  color: #009F9F;
  background: none;
  border: none;
  outline: none;
  width: 115px;
  padding: 0;
  margin-top: 8px;
  margin-left: auto;
  margin-bottom: 24px;
  transition: 0.2s ease-in-out;
}
.reminder:active {
  color: #009F9F;
  opacity: 0.6;
}

.header .logo, .footer .logo {
  padding-right: 14px;
  border-right: 1px solid #C4C4C4;
}

.animation-blink {
  animation: blink 4s ease-in infinite;
}

@media (min-width: 768px) {
  .header .logo, .footer .logo {
    padding-right: 14px;
  }
}
@media (min-width: 992px) {
  .header .logo, .footer .logo {
    padding-right: 24px;
  }
}