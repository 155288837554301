.footer {
    .main--receipts + & {
        background-color: $bg-footer;
    }
    
    &__container {
        padding-top: 72px;
        padding-bottom: 32px;
        max-width: 1100px;
        border-top: 1px solid $border-4;

        .main--receipts + .footer & {
            // margin-top: 170px;
            border-top: 2px solid $menu;
            padding-bottom: 32px;
            padding-top: 48px;
        }

        .main--business + .footer & {
            border-top: 1px solid $border-4;
        }
    }

    &__form {
        margin-bottom: 80px
    }

    &__search {
        @include font(18px, 26px, 500);
        padding: 16px;
        border-radius: 8px;
        width: 100%;
        border: 2px solid transparent;
        outline: none;
        background-color: $search;
        transition: 0.2s ease-in-out;
        
        &:focus {
            border: 2px solid $menu;
        }

    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        
        &--1 {
            flex-direction: column;
            margin-bottom: 80px;
        }

        &--2 {
            align-items: center;
            row-gap: 32px;
        }

        &--3 {
            align-items: center;
            row-gap: 32px;
            margin-top: 48px;
        }
    }

    &__item {
        cursor: pointer;
        transition: 0.2s ease-in-out;
        
        // &:not(:last-child) {
        //     margin-right: 16px;
        // }

        .footer__list--1 & {
            &:not(:last-child) {
                margin-bottom: 32px;
            }
        }

        .footer__list--2 & {
            padding: 7px 22px 7px 16px;
            border: 2px solid $link;
            color: $link;
            border-radius: 20px;

            &:not(:last-child) {
                margin-right: 32px;
            }

            &:active {
                color: $primary;
                border: 2px solid $primary;
            }
        }

        &--language {
            @include font(12px, 20px, 500);
            display: flex;
            align-items: center;
            margin-right: auto;
        }

        &--copyright {
            @include font(16px, 20px, 500);
            width: 100%;
        }
    }

    &__link {
        display: block;
        transition: 0.2s ease-in-out;
        
        .footer__list--1 & {
            @include font(20px, 26px, 500);
            text-decoration: underline;
            color: $primary;

            &:active {
                color: $link;
            }
        }

        .footer__container > & {
            margin-bottom: 32px;
        }
    }

    &__date {
        // @include font(14px, 20px, 500);
        @include font(12px, 18px, 500);
        color: $label;
    }
}

@include media-breakpoint-up(sm) { 
    .footer {
        &__container {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }

        &__list {
            flex-direction: row;
            flex-wrap: nowrap;

            &--1 {
                width: 100%;
            }

            &--2 {
                margin-left: auto;
            }

            &--3 {
                width: 100%;
                // justify-content: space-between;
            }
        }

        &__item {
            .footer__list--1 & {
                &:not(:last-child) {
                    margin-bottom: 0;
                    margin-right: 32px;
                }
            }
            
            // &:not(:last-child) {
            //     margin-right: 16px;
            // }

            // &--copyright {
            //     @include font(16px, 20px, 500);
            //     width: max-content;
            // }
        }

        &__link {
            .footer__container > & {
                margin: 0;
            }
        }
    } 
}

@include media-breakpoint-up(lg) { 
    .footer {

        &__list {
            &--1 {
                width: max-content;
                margin: 0;
            }

            &--2 {
                margin-left: 16px;
            }
        }

        &__item {
            .footer__list--1 & {
                &:not(:last-child) {
                    margin-bottom: 0;
                }
            }

            .footer__list--1 & {
                margin-bottom: 0;
            }

            .footer__list--2 & {
                &:hover {
                    opacity: 0.7;
                }

                &:active {
                    color: $primary;
                    border: 2px solid $primary;
                    opacity: 1;
                }
            }
        }

        &__link {
            .footer__container > & {
                margin-left: auto;
            }

            .footer__list--1 & {
                &:hover {
                    color: $link;
                    opacity: 0.7;
                }
    
                &:active {
                    opacity: 1;
                }
            }
        }
    }
}