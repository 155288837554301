//базовые стили (не изменять)
.custom-select {
	position: relative;

    &__selected {
        display: flex;
        align-items: center;
        user-select: none;
        width: 100%;
        
        &::after {
            content: "";
            width: 16px;
            height: 8px;
            margin-left: 8px;
            background: url("../images/icons/dropdown.svg") no-repeat 50%;
            background-size: cover;
            transition: 0.2s ease-in-out;
        }

        &:focus {
            outline: none;
        }
    }

    &__name {
        display: block;
        flex-grow: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
    }

    &__icon {
        margin-right: 8px;
    }

    &__dropdown {
        position: absolute;
        top: 105%;
        left: 0;
        right: 0;
        z-index: 99;
        overflow: auto;
        max-height: 350px;
        transition: background-color 0.15s ease-in-out;
        width: 100%;
        transition: 0.15s ease-in-out;
        z-index: -1;
        visibility: hidden;
        opacity: 0;  
    }

    &__menu {
        width: 100%;
    }

    &__item {
        display: flex;
        align-items: center;
        padding: 14px;
        cursor: pointer;
        transition: 0.15s ease-in-out;
        user-select: none;
    }

}

//состояние элементов на разных страницах
.custom-select {
    .info__block--replenishment & {
        width: 100%;
        margin-left: 8px;
    }

    .receipt__field & {
        margin-left: 8px;
        min-width: 125px;
    }

    .receipt__label > & {
        margin-top: 8px;
        margin-left: 8px;
    }

    .main--registration &,
    .main--obligation &,
    .main--e-currency &  {
        margin-top: 8px;
        width: 100%;
    }

    &__selected {
        &::after {
            .footer & {
                display: none;
            }
        }

        .main--receipts &,
        .main--obligation &,
        .main--e-currency &  {
            border: 2px solid $input-bg;
            // border-top: 2px solid $input-bg;
            // border-left: 2px solid $input-bg;
            // border-right: 2px solid $input-bg;
        }

        .main--registration & {
            background-color: $secondary;
        }

        .receipt__label & {
            padding: 16px 10px;
        }

        .pagination & {
            padding: 8px 10px;
            border-radius: 8px;
            border: 2px solid $receipt-button;

            &--active {
                border-radius: 8px 8px 0 0;
                border-bottom: 2px solid $border-2;
            }
        }

        .receipt__field &,
        .receipt__label &,
        .pagination &,
        .main--obligation &,
        .main--e-currency &  {
            color: $tertiary;
            background-color: $input-bg;
            border-radius: 8px;
            // transition: 0.2s ease-in-out;
            
            &--active {
                border-radius: 8px 8px 0 0;
                background-color: $secondary;
                border-top: 2px solid $border-2;
                border-left: 2px solid $border-2;
                border-right: 2px solid $border-2;
            }
        }

        .tabs-form__field & {
            border-radius: 8px;
            border: 2px solid $input-bg;
            padding: 8px;
        }

        .main--obligation &,
        .main--e-currency & {
            padding: 10px 16px;
        }

        .footer & {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: none;
            border: none;
            outline: none;
            padding: 8px;
            margin-left: 8px;
        }

        .main--registration & {
            padding: 16px;
            border: 2px solid $input-border;
            border-radius: 8px;
        }
    }

    &__name {
        .main--receipts &,
        .main--obligation &,
        .main--e-currency & {
            @include font(14px, 34px, 500);
        }

        .footer & {
            flex-grow: 0;
            order: 1;
        }
    }

    &__icon {
        .footer & {
            order: 2;
            margin-left: 8px;
            margin-right: 0;
            width: 24px;
            height: 24px;
        }
    } 

    &__dropdown {
        .main--receipts &,
        .pagination &,
        .main--obligation &,
        .main--e-currency & {
            border-radius: 8px;
            background-color: $secondary;
            border: 2px solid $border-2;

            &::-webkit-scrollbar {
                width: 5px;
                height: 5px;
                border-radius: 0 8px 8px 0;
            }
    
            &::-webkit-scrollbar-track {
                border-radius: 0 8px 8px 0;
                background-color: $border-1;
            }
    
            &::-webkit-scrollbar-thumb {
                border-radius: 0 8px 8px 0;
                background-color: $border-2;
            }

        }

        .main--registration &,
        .footer & {
            border-radius: 8px;
            box-shadow: 0px 0px 16px rgb(0 0 0 / 16%);
        }
    }

    // &__dropdown-icon {
    //     .footer & {
    //         display: none;
    //     }
    // }

    &__menu {
        // .main--receipts &,
        // .pagination &,
        // .main--obligation &,
        // .main--e-currency & {
        //     border-radius: 8px;
        //     background-color: $secondary;
        //     border: 2px solid $border-2;
        //     // border-bottom: 2px solid $border-2;
        //     // border-left: 2px solid $border-2;
        //     // border-right: 2px solid $border-2;
        //     // color: $color-4;
        // }  

        .tabs-form__field & {
            border-radius: 8px;
            background-color: $secondary;
            box-shadow: 0px 0px 24px -10px rgba(34, 60, 80, 0.2);
        }

        .footer & {
            width: auto;
            background-color: $secondary;
            box-shadow: 0 0 4px rgb(0 0 0 / 10%);
            border-radius: 8px;
        }

        .main--registration & {
            background-color: $secondary;
            border-radius: 8px;
            box-shadow: 0px 0px 24px -10px rgba(34, 60, 80, 0.2);
        }
    }

    &__item {
        .receipt__field &,
        .receipt__label &,
        .tabs-form__field &, 
        .pagination & {
            @include font(16px, 34px, 500);
            color: $tertiary;
            transition: 0.2s ease-in-out;

            &:active {
                background-color: $custom-select-bg;
            }
        }

        .main--registration & {
            &:active {
                background-color: $custom-select-bg;
            }
        }

        .footer & {
            justify-content: space-between;
            padding: 8px;

            &:not(:last-child) {
                border-bottom: 1px solid $border-4;
            }
        }
    }

    &__name {
        .pagination & {
            @include font(14px, 15px, 500);
            color: $receipt-button;
        }

        .footer .custom-select__selected &,
        .footer .custom-select__item & {
            @include font(12px, 18px, 500);
            
        }

        .footer .custom-select__selected & {
            color: $label;
        }

        .footer .custom-select__item & {
            color: $tertiary;
        }

        .main--registration & {
            @include font(16px, 20px, 500);
            color: $primary;
        }
    }
}

//модифицированные элементы
.custom-select--show {
    .custom-select__selected {
        &::after {
            transform: rotate(-180deg);
        }
    }

    .main--receipts &,
    .main--obligation &,
    .main--e-currency & {
        .custom-select__selected {
            border: 2px solid $border-2;
        }
    } 

    .custom-select__dropdown {
		// display: block;
        z-index: 10000;
        visibility: visible;
        opacity: 1;
	}

	// .custom-select__backdrop {
	// 	display: block;
	// }
}

.custom-select--disabled {
    .custom-select__selected,
    .custom-select__icon {
        opacity: 0.3;
        pointer-events: none;
    }

    .custom-select__text {
        color: $disabled-2;
    }
}

//состояния на разных разрешениях
@include media-breakpoint-up(sm) { 
    .custom-select {
        .info__block--replenishment & {
            width: auto;
            flex-basis: 200px;
        }

        .receipt__field & {
            min-width: auto;
        }

        &__selected {
            .receipt__label & {
                min-width: 155px;
                padding: 16px;
            }
        }

        &__icon {
            width: 32px;
            height: 32px;
        }

        &__item {
            .receipt__field &,
            .receipt__label &,
            .tabs-form__field &, 
            .pagination & {
                font-size: 20px;
            }
        }

        &__name {
            .main--receipts &,
            .main--obligation &,
            .main--e-currency & {
                font-size: 20px;
            }

            .pagination & {
                font-size: 18px;
                line-height: 24px;
            }

            .footer & {
                min-width: 48px;
            }
        }
    }
}

@include media-breakpoint-up(md) { 
    .custom-select {
        .info__block--replenishment & {
            width: auto;
            flex-basis: 200px;
        }
    }
}

@include media-breakpoint-up(lg) { 
    .custom-select {
        &__item {
            .receipt__field &,
            .receipt__label &,
            .main--registration &, 
            .pagination & {
                color: $tertiary;
                transition: 0.2s ease-in-out;

                &:active, 
                &:hover {
                    background-color: $custom-select-bg;
                }
            } 
        }

        .footer & {
            margin-left: 8px;
        }
    }
}