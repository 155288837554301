.table {
    padding: 16px;
    border-radius: 24px;
    border-spacing: 4px;
    border-collapse: separate;
    width: 100%;

    .main--issue & {
        margin-bottom: 0;
    }
    
    &--issues {
        margin-top: 24px;
        border: 1px solid $border-1;
    }

    &--information {
        max-width: 440px;
        margin: 32px auto 0 auto;

        &:not(:first-of-type) {
            margin-bottom: 64px;
        }

        .section__container--1 & {
            max-width: none;
            margin: 0;

            &:not(:first-of-type) {
                margin-bottom: 0;
            }
        }
    }

    &__cell {
        padding: 0;
        border: none;
        vertical-align: middle;

        .table__row--head & {
            .table--issues & {
                text-align: center;
                border-radius: 8px;
                background-color: $input-bg;
            }
            
            .table--information & {
                padding-bottom: 24px;
                @include font(16px, 28px, 700);
            }
        }

        .table--issues & {
            @include font(12px, 20px, 500);

            &:not(:first-child) {
                text-align: center;
            }
        }

        .table--information & {
            @include font(10px, 20px, 500);
            padding-bottom: 0;
            
            &:first-child {
                white-space: nowrap;
            }
        }

        // &:first-child:not(.table__row--head &) {
        //     text-align: left;
        // }
    }

    &__link {
        color: $heading;
        transition: 0.2s ease-in-out;
        
        &:active,
        &:visited {
            color: $heading;
        }

        &:active {
            opacity: 0.6;
        }
    }
}

@include media-breakpoint-up(sm) { 
    .table {
        &__cell {
            .table--information &,
            .table--issues & {
                font-size: 13px;
                line-height: 20px;
            }

            .table__row--head & {
                .table--information & {
                    font-size: 20px;
                }
            }
        }
    }
}

@include media-breakpoint-up(md) { 
    .table {
        // .main--issue & {
        //     margin-bottom: 24px;
        // }
        .main--issue & {
            margin-bottom: 24px;
        }

        &--information {
            &:not(:first-of-type) {
                margin-bottom: 64px;
            }
        }
    }
}

@include media-breakpoint-up(lg) { 
    .table {
        &__cell {
            .table__row--head & {
                .table--information & {
                    font-size: 26px;
                }
            }
        }

        &__link {
            &:hover {
                color: $primary-button-hover;
            }
        }
    }
}