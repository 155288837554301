.toggles {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto; 
    // padding: 0 16px 24px 16px;
    padding: 0;
    border-bottom: 2px solid $separator;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    &__item {
        @include font(20px, 25px, 500);
        display: flex;
        white-space: nowrap;
        // padding: 12px 24px 12px 24px;
        padding: 20px 24px 20px 24px;
        color: $primary;
        border-top: 4px solid transparent;
        transition: 0.2s ease-in-out;
        cursor: pointer;

        &:active {
            color: $menu;
        }

        &.active {
            color: $menu;
            border-top: 4px solid $menu;
        }
    }
}

@include media-breakpoint-up(md) { 
    .toggles {
        &--modified {
            flex-direction: column;
            margin: 40px 0;
            border-right: 1px solid $separator;
            border-bottom: none;
        }

        &__item {
            .toggles--modified & {
                border-top: none;
                border-left: 4px solid transparent;
                margin-top: 8px;
                white-space: normal;
                
                &.active {
                    color: $menu;
                    border-top: none;
                    border-left: 4px solid $menu;
                    margin-left: 12px;
                }
            }

            
        }
    }
}

@include media-breakpoint-up(lg) { 
    .toggles {

        &__item {
            &:hover {
                color: $menu;
            }
        }
    } 
}