.tabs-form {
    width: 90%;
    margin: auto;
    box-shadow: $box-shadow;
    border-radius: 24px;
    padding: 32px 16px;
    background-color: $secondary;

    &__title {
        @include font(24px, 30px, 500);
        margin-bottom: 48px;
    }

    &__field {
        display: flex;
        margin-top: 8px;
        // align-items: flex-end;
        // flex-wrap: wrap;
        
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }

        .info__block--repayment & {
            margin-bottom: 48px;
        }
    }

    &__label {
        @include font(16px, 20px, 500);
        display: flex;
        flex-direction: column;
        margin-top: 24px;
        // width: 100%;
        // margin-bottom: 8px; 

        // .info__block--replenishment & {
        //     flex-grow: 1;
        //     // width: 40%;
        // }

        // &:not(:first-of-type) {
        //     margin-top: 24px;
        // }
    }

    &__input {
        padding: 16px;
        width: 100%;
        border-radius: 8px;
        border: 2px solid $separator;
        margin-top: 8px;

        &::placeholder {
            @include font(14px, 18px, 500);
        }
    }

    // &__capcha {
    //     border-radius: 8px;
    // }
}

@include media-breakpoint-up(sm) { 
    .tabs-form {
        padding: 32px;
        
        &__label {

        }
    }
}