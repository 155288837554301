@keyframes blink {
    0% {
        opacity: 0.2; 
    }
    
    25% {
        opacity: 0.5; 
    }

    50% {
        opacity: 1; 
    }

    75% {
        opacity: 0.5; 
    }
    
    100% {
        opacity: 0.2; 
    }
}
