.header {
    padding: 8px 0;
    background-color: $secondary;
    border-bottom: 2px solid $menu;

    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;  
    }

    &__link {
        color: inherit;

        &--faq {
            display: flex;
            align-items: center;
            margin: 0 8px 0 auto;
            border: 0;
            max-width: none;
        }

        &:visited {
            color: $primary;
        }

        &:hover {
            color: $menu-active;
            opacity: 0.7;
        }

        &:active {
            opacity: 1;
        }
    }

    &__trigger {
        padding: 8px 16px;
        font-size: 0;
        border-radius: 20px;
        border: 2px solid $primary;
        outline: none;
        width: 60px;
        height: 40px;
        background: transparent url("../images/icons/burger.svg") no-repeat 50%;
        background-size: 24px;
        transition: 0.2s ease-in-out;

        &.active {
            background: $menu url("../images/icons/close.svg") no-repeat 50%;
            border: 2px solid $border-2;
        }
    }
}

@include media-breakpoint-up(md) { 
    .header {
        &__container {
            justify-content: flex-start;
        }

        &__link {
            display: none;

            &:active {
                color: $menu-active;
                opacity: 0.7;
            }
        }

        &__trigger {
            display: none;
        }
    }
}