.user {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 8px;
    position: relative;

    &__avatar {
        border-radius: 50%;
        width: 40px;
        height: 40px;
    }

    &__identification {
        @include font(16px, 20px, 600);
        display: flex;
        align-items: center;

        justify-content: flex-start;
        // flex-flow: column wrap;
        // height: 60px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 10px 0;
        transition: 0.2s ease-in-out;
    }

    &__button {
        @include font(14px, 20px, 500);
        background: none;
        outline: none;
        border: none;
        padding: 0;
        transition: 0.2s ease-in-out;
        
        &--confirm {
            display: flex;
            align-items: center;
            border: 2px solid $link;
            color: $secondary;
            border-radius: 24px;
            padding: 5px 6px 5px 12px;
            background-color: $menu;
            color: $secondary;
            text-align: left;
            // width: calc(100% - 35px);
            margin-left: 8px;

            &:active {
                background-color: $menu-active;
                color: $primary;
                border: 2px solid $primary
            }
        }

        &--confirmed {
            position: relative;

            &::before {
                @include pseudo-element;
                background: $menu url("../images/icons/key-rounded.svg") no-repeat 50%;
                width: 20px;
                height: 20px;
                right: 0;
                bottom: -4px;
                background-size: cover;
                border-radius: 50%;
            }
        }

        &--logout {
            display: none;
        }
    }

    &__menu {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        background-color: $secondary;
        width: 290px;
        right: 0;
        top: 100%;
        opacity: 0;
        transform: translateY(-200%);
        z-index: 1000;
        transition: 0.3s transform, opacity 0.3s ease-in-out;
        box-shadow: 0 8px 40px rgba(0, 0, 0, 0.1);
        padding: 16px;
        border-radius: 8px;

        .active + & {
            transform: translateY(10px);
            opacity: 1;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &__item {
        padding: 10px 0;
    }

    &__link {
        @include font(14px, 20px, 500);
        display: block;
        color: $primary;
        transition: color 0.2s ease-in-out;
        max-width: calc(100% - 35px);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        
        &:visited {
            color: $primary;
        }

        &:active {
            color: $menu-active;
        }
    }
}

@include media-breakpoint-up(md) { 
    .user {
        order: 1;
        margin-right: 0;
        margin-left: 8px;

        &__button {
            &--confirmed {
                &::before {
                    width: 24px;
                    height: 24px;
                }
            }

            &--logout {
                display: flex;
                align-items: center;
                flex-wrap: nowrap;
                color: $link;
                border: 2px solid $link;
                border-radius: 24px;
                padding: 11px 13px;
                transition: color 0.2s, border-color 0.2s ease-in-out;
                margin-left: 8px;
                
                &:active {
                    color: $primary;
                    border-color: $primary;
                }
            }
        }

        &__avatar {
            margin-right: 8px;
        }

        &__menu {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            position: static;
            box-shadow: none;
            transform: none;
            padding: 0;
            opacity: 1;
            transition: transform 0s;
            width: auto;

            .active + & {
                transform: none;
            }
        }

        &__identification {
            padding: 0;
            height: 46px;
        }

        &__list {
            display: none;
        }

        &__link {
            max-width: 134px;
        }
    }
}

@include media-breakpoint-up(lg) { 
    .user {
        margin-left: 24px;

        &__menu {
            flex-wrap: nowrap;
        }

        &__button {
            font-size: 16px;
            cursor: default;
            
            &--confirm {
                cursor: pointer;
                margin-left: 24px;

                &:hover {
                    background-color: $menu-active;
                    color: $primary;
                    border: 2px solid $menu-active;
                    opacity: 1;
                }

                &:active {
                    background-color: $menu-active;
                    color: $primary;
                    border: 2px solid $primary;
                }
            }



            &--logout {
                cursor: pointer;
                margin-left: 24px;
    
                &:hover {
                    color: $menu-active;
                    opacity: 0.7;
                }
    
                &:active {
                    color: $primary;
                    border-color: $primary;
                    opacity: 1;
                }
            }
        }

        &__link {
            font-size: 16px;
            .user & {
                &:hover {
                    color: $menu-active;
                    opacity: 0.7;
                }
                
                &:active {
                    color: $menu;
                    opacity: 1;
                }
            }
        }
    }
}