@import "bootstrap/scss/bootstrap.scss";

@import "global/mixins.scss";
@import "global/keyframes.scss";
@import "global/fonts.scss";
@import "global/icons.scss";
@import "global/normalize.scss";
@import "global/variables.scss";

@import "blocks/header.scss";
@import "blocks/menu.scss";
@import "blocks/section.scss";
@import "blocks/form.scss";
@import "blocks/tabs.scss";
@import "blocks/tabs-form.scss";
@import "blocks/info.scss";
@import "blocks/footer.scss";
@import "blocks/toggles.scss";
@import "blocks/receipt.scss";
@import "blocks/notification.scss";
@import "blocks/pagination.scss";
@import "blocks/popup.scss";
@import "blocks/table.scss";
@import "blocks/user.scss";

// @import "elements/dropdown.scss";
@import "elements/checkbox.scss";
@import "elements/link.scss";
@import "elements/button.scss";
@import "elements/icon.scss";
@import "elements/custom-select.scss";
@import "elements/input.scss";
@import "elements/radio.scss";
@import "elements/capcha.scss";

@import "libs/vanillajs-datepicker/datepicker.scss";
//cтили для элемента на тестовом стенде
@import "elements/file-input.scss";

.page {
    display: flex;
    flex-direction: column;
    background-color: $bg;
    min-width: $min-width-page;

    &--fixed {
        overflow: hidden;
    }

    &--headers {
        justify-content: space-between;
    }
}

.main {
    flex: 1;

    &--receipts {
        background-color: $bg-receipts;
        padding-bottom: 130px;
    }

    &__container {
        // min-height: 100vh;
        // background-color: $bg;
        // max-width: 1440px;
        margin: 0 auto;
        // box-shadow: $box-shadow;
    }

    &__content {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        margin: auto;
        max-width: 500px;
        padding: 64px;
    }
}

.logo {
    display: block;
    width: max-content;

    .receipt & {
        margin: 0 auto;
    }

    .receipt__currency & {
        margin: 0;
    }
}

.reminder {
    @include font(14px, 20px, 500);
    display: block;
    color: $primary-button-default;
    background: none;
    border: none;
    outline: none;
    width: 115px;
    padding: 0;
    margin-top: 8px;
    margin-left: auto;
    margin-bottom: 24px;
    transition: 0.2s ease-in-out;
    
    &:active {
        color: $receipt-button;
        opacity: 0.6;
    }
}

.logo {
    .header &,
    .footer & {
        padding-right: 14px;
        border-right: 1px solid $separator;
    }
}

.animation-blink {
    animation: blink 4s ease-in infinite;
}

@include media-breakpoint-up(md) { 
    .logo {
        .header &,
        .footer & {
            padding-right: 14px;
        }
    }
}

@include media-breakpoint-up(lg) { 
    .logo {
        .header &,
        .footer & {
            padding-right: 24px;
        }
    }
}