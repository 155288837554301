.icon {
    display: inline-block;

    .section__features & {
        color: $primary;
        margin-bottom: 24px;
    }

    // .menu--section-1 & {
    //     width: 20px;
    // }

    .menu--section & {
        margin-left: auto;
    }

    .menu__item--faq & {
        margin-left: 4px;
    }

    .footer__item &:not(.logo &) {
        margin-left: 12px;
    }

    .info__content & {
        margin-right: 16px;
    }

    .receipt & {
        display: block;
        margin: 0 auto;
    }

    .link & {
        margin: 0 4px;
    }

    .receipt__currency & {
        color: $brand;
        margin-bottom: 4px;
    }

    .checkbox__label & {
        position: absolute;
        top: 50%;
        left: -4px;
        transform: translateY(-50%);
        color: $secondary;
        z-index: 2;
    }

    .menu--receipt & {
        margin-bottom: 8px;
    }

    .pagination & {
        margin: 0 auto;
        width: 13px;
        height: 16px;
    }

    .section--4 &:not(.receipt &) {
        color: $primary;
        z-index: 1;
    }

    .main--business .section__tab & {
        color: $brand;
    }

    .section__heading & {
        width: 48px;
        height: 48px;
    }

    // .button &:not(.button--show &),
    // .receipt__button &  {
    //     margin-right: 8px;
    // }
    .receipt__button &,
    .button--primary &  {
        margin: 0 4px;
    }

    .form__button & {
        display: block;
        margin: auto;
    }
    
    .user__menu & {
        margin-right: 8px;
    }

    .user__button--confirm & {
        margin: 0;
    }

    .button & {
        margin-right: 4px;
    }
    // .pagination .custom-select & use {
    //     display: block;
    //     width: 20px;
    //     height: 15px;
    // }

    &--1 {
        color: $icon-1;
    }

    &--2 {
        color: $icon-2;
    }

    &--3 {
        color: $icon-3;
    }

    &--4 {
        color: $icon-4;
    }
}

@include media-breakpoint-up(md) { 
    .icon {
        .main--index .section__heading & {
            position: absolute;
            left: 0;
            top: -35px;
            width: 90px;
            height: 90px;
        }

        .menu--section & {
            margin-left: 0;
        }

        .user__menu & {
            margin-right: 0;
        }

        .user__identification > & {
            display: none;
        }
    }
}

@include media-breakpoint-up(lg) { 
    .icon {
        .main--index .section__heading & {
            top: -45px;
            width: 100px;
            height: 100px;
        }
    }
}

// @include media-breakpoint-up(xxl) { 
//     .icon {
//         .main--index .section__heading & {
//             top: -45px;
//             width: 105px;
//             height: 120px;
//         }
//     }
// }