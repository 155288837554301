.pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .main--receipts & {
        width: 90%;
        margin: 24px auto 0 auto;
    }

    .main--issue & {
        margin: 24px auto;
    }
    
    &__block,
    &__list {
        display: flex;
        align-items: center;
    }

    &__block {
        &--left {
            flex-grow: 1;
        }
    }

    &__item {
        &:not(:last-of-type){
            margin-right: 3px;
        }

        &--more-previous,
        &--more-next {
            display: none;
        }

        &--show {
            display: block;
        }
    }

    &__link {
        @include font(14px, 15px, 600);
        display: block;
        border-radius: 8px;
        padding: 8px 0;
        border: 2px solid $receipt-button;
        color: $receipt-button;
        text-align: center;
        cursor: pointer;
        transition: background-color 0.2s, color 0.2s ease-in-out;
        user-select: none;
        min-height: 37px;

        &:visited {
            color: $receipt-button;
        }

        &:active {
            background-color: $pagination-button-active;
            color: $secondary;
            border-color: $pagination-button-active;
        }

        .main--receipts & {
            min-width: 34px;
        }

        .main--issue & {
            min-width: 30px;
        }

        .pagination__item--disabled & {
            color: $disabled-2;
            border-color: $disabled-2;
            cursor: auto;
            pointer-events: none;

            &:active {
                background: none;
                color: $disabled-2;
            }
        }

        .pagination__item--selected & {
            background-color: $pagination-button-active;
            border-color: $pagination-button-active;
            color: $secondary;

            &:active {
                background-color: $pagination-button-active;
                border-color: $pagination-button-active;
            }
        }

        .pagination__item--reset & {
            padding-top: 13px;
            margin: 0;
            border: none;
            pointer-events: none;
        }
    }

    &__total {
        @include font(14px, 22px, 500);
        color: $receipt-button;
        margin-right: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 118px;
        user-select: none;
    }
}

@include media-breakpoint-up(sm) { 
    .pagination {
        .main--receipts & {
            width: 100%;
        }

        .main--issue & {
            margin: 32px 0 32px auto;
        }

        &__link {
            .main--receipts & {
                font-size: 18px;
                line-height: 24px;
                padding: 8px 14px;
                min-width: 46px;
            }      

            .pagination__item--disabled & {
                &:hover {
                    background: none;
                    color: $disabled-2;
                }
            }
        }

        &__total {
            font-size: 18px;
            line-height: 23px;
        }
    }
}

@include media-breakpoint-up(md) { 
    .pagination {
        .main--receipts & {
            width: 100%;
            margin: 24px auto 0 auto;
        }
        
        .main--issue & {
            margin: auto;
        }

        &__link {
            .main--authorization & {
                font-size: 12px;
                line-height: 15px;
                padding: 8px;
            }
        }
    }
}

@include media-breakpoint-up(lg) { 
    .pagination {
        .main--issue & {
            margin: auto 0;
        }

        &__item {
            &:not(:last-of-type){
                margin-right: 8px;
            }
        }

        &__link {
            &:hover {
                background-color: $pagination-button-active;
                border-color: $pagination-button-active;
                color: $secondary;
            }

            &:active {
                background-color: $pagination-button-active;
                color: $secondary;
            }
        }
    }
}

@include media-breakpoint-up(xl) { 
    .pagination {
        &__link {
            .main--issue & {
                font-size: 18px;
                line-height: 24px;
            }

            &:not(.pagination__item--reset &) {
                padding: 8px 16px;
            }
            // .main--issue &:not(.pagination__item--reset &) {
            //     padding: 8px 16px;
            // }
        }
    }
}