$light-blue:        #B3D5DD;
$blue:              #0D6EFD;
$brand-blue:        #006CB4;
$skyblue:           #2266CC;
$indigo:            #2E3951;
$cobalt:            #273145;
$sapphire:          #1C2432;
$shadow-blue:       #10151D;
$lavender:          #E8EFF8;
$light-cornflower:  #899AEC;
$cornflower:        #6E80E8;
$light-purple:      #C5CDF6;
$purple:            #9747FF;
$grapefruit:        #df6c78;
$red:               #DC3545;
$tomato:            #F55624;
$pink:              #FFD2D7;
$orange:            #FFA500;
$yellow-green:      #ABEC24;
$lime:              #64900C;
$yellow-lime:       #AAEE11;
$apple:             #9EF0B5;
$light-green:       #32BB44;
$green:             #26A17B;
$aqua:              #009F9F;

$light-aquamarine:  #6CD0BF;
$aquamarine:        #057777;
$shadow-green:      #005555;
$emerald:           #005655;
$teal-light: #d0f2eb;
$teal:              #A1E3D8;
$cyan:              #33CCC3;
$light-cyan:        #CCECF0;
$linear-gradient:   linear-gradient(180deg, rgba(255, 255, 255, 0.64) 0%, rgba(255, 255, 255, 0) 100%);
$mint:              #A1E3D8;
$peach:             #FFE4B3;
$kraiola:            #E8F0F1;

$white:    #ffffff;
$gray-100: #EEEEEE;
$gray-200: #D9D9D9;
$gray-300: #C4C4C4;
$gray-400: #CED4DA;
$gray-500: #BABCBF;
$gray-600: #B8BABB;
$black:    #000000;

// components
$brand:         $brand-blue;  
$primary:       $sapphire;
$secondary:     $white;
$tertiary:      $cobalt;
$error:         $grapefruit;
$error-active:  $red;
$error-bg:      $pink;
$warning:       $orange;
$warning-bg:    $peach;
$cover:        rgba(39, 49, 69, 0.3);
$bg:            $secondary;
$bg-index:      $lavender;
$bg-business:   $light-blue;
$bg-receipts:   $kraiola;
$bg-secondary:  $aqua;
$bg-tertiary:   $teal;
$bg-footer:     $kraiola;

$heading:       $aqua;
$heading-2:     $shadow-green;
$label:         $gray-600;
$link:          $aqua;
$button-link:   $aqua;
$success-button:  $mint;
$notification-bg: $teal;
$receipt-table:   $teal;
$receipt-button: $aqua;
$pagination-button-active: $aquamarine;
$separator:     $gray-300;
$search:        $gray-100;

$primary-registration-button-default: $teal;
$primary-registration-button-hover: $aqua;
$primary-registration-button-active: $aqua;

$primary-button-default: $aqua;
$primary-button-hover: $aquamarine;
$primary-button-active: $teal;

$rounded-button-default: $teal;
$rounded-button-hover: $aquamarine;
$rounded-button-active: $aqua;

$green-input: $aqua;

$tile-border-1: $teal;
$tile-border-2: $gray-100;
$tile-border-3: $lavender;
$tile-border-4: $gray-600;

$input-border:  $gray-100;
$button-border: $gray-100;
$checkbox-border: $gray-500;
$secondary-button: $gray-500;
$disabled:      $gray-100;
$disabled-2:    $gray-600;
$disabled-3:    $teal-light;
$input-bg:      $gray-100;
$required: $tomato;
$custom-select-bg: $teal;
$border:        rgba(255, 255, 255, 0.24);
$border-1:      $gray-100;
$border-2:      $teal; 
$border-3:      $white; 
$border-4:      $gray-200;

$menu:          $aqua;
$menu-active: $light-aquamarine; 
$menu-button-1: $light-blue;
$menu-button-2: $light-cyan;
$menu-button-3: $light-purple;
$menu-button-4: $mint;
$menu-button-5: $apple;
$mark:          $aqua;
$box-shadow:    0 8px 40px rgba(0, 0, 0, 0.1);
$linear-gradient: linear-gradient(100.81deg, #E8EFF8 0%, #FFFFFF 100%);
$min-width-page: 320px;
$linear-gradient-2: linear-gradient(180deg, 
                    #10151D 0%, #1C2432 100%);
$linear-gradient-section-index: linear-gradient(180deg, rgba(255, 255, 255, 0.64) 0%, rgba(255, 255, 255, 0) 100%);
$linear-gradient-section-separate: radial-gradient(55.39% 191.09% at 46.04% 50.2%, #009F9F 0%, #20B4AB 100%);
$linear-gradient-section-features: linear-gradient(100.81deg, #E8EFF8 0%, #FFFFFF 100%);
$linear-gradient-section-business: linear-gradient(112.95deg, rgba(255, 255, 255, 0.88) 0%, rgba(255, 255, 255, 0) 62.96%);
$linear-gradient-section-business-2: linear-gradient(180deg, #10151D 0%, #1C2432 100%);
$tabs:  rgba(255, 255, 255, 0.8);
$tabs-active: linear-gradient(100.81deg, #009F9F 0%, #FFFFFF 100%);
$icon-1: $yellow-lime;
$icon-2: $tomato;
$icon-3: $cyan;
$icon-4: $white;
$brand: $skyblue;
$radio-receipt-active: $aqua;
$radio-receipt-default: $gray-200;
