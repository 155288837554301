@font-face {
	font-family: "icons";
	src: url('fonts/icons.eot?1693234294');
	src: url('fonts/icons.eot?1693234294#iefix') format('eot'),
		url('fonts/icons.woff2?1693234294') format('woff2'),
		url('fonts/icons.woff?1693234294') format('woff'),
		url('fonts/icons.ttf?1693234294') format('truetype'),
		url('fonts/icons.svg?1693234294#icons') format('svg');
}

.icon:before {
	font-family: "icons";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	/* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
	text-decoration: none;
	text-transform: none;
}


.icon-accept:before {
	content: "\E001";
}

.icon-api:before {
	content: "\E002";
}

.icon-arrow-double-left:before {
	content: "\E003";
}

.icon-arrow-double-right:before {
	content: "\E004";
}

.icon-arrow-left:before {
	content: "\E005";
}

.icon-arrow-right:before {
	content: "\E006";
}

.icon-arrow:before {
	content: "\E007";
}

.icon-bitcoin:before {
	content: "\E008";
}

.icon-burger:before {
	content: "\E009";
}

.icon-check-payment-thin:before {
	content: "\E00A";
}

.icon-check-payment:before {
	content: "\E00B";
}

.icon-check:before {
	content: "\E00C";
}

.icon-clip:before {
	content: "\E00D";
}

.icon-close:before {
	content: "\E00E";
}

.icon-copy-1:before {
	content: "\E00F";
}

.icon-copy:before {
	content: "\E010";
}

.icon-creation:before {
	content: "\E011";
}

.icon-diagram:before {
	content: "\E012";
}

.icon-dots:before {
	content: "\E013";
}

.icon-dropdown:before {
	content: "\E014";
}

.icon-english:before {
	content: "\E015";
}

.icon-ethereum:before {
	content: "\E016";
}

.icon-exchange:before {
	content: "\E017";
}

.icon-eye:before {
	content: "\E018";
}

.icon-gift:before {
	content: "\E019";
}

.icon-google:before {
	content: "\E01A";
}

.icon-guard-default:before {
	content: "\E01B";
}

.icon-guard:before {
	content: "\E01C";
}

.icon-income:before {
	content: "\E01D";
}

.icon-info-2:before {
	content: "\E01E";
}

.icon-info-default:before {
	content: "\E01F";
}

.icon-info-error:before {
	content: "\E020";
}

.icon-info-warning:before {
	content: "\E021";
}

.icon-info:before {
	content: "\E022";
}

.icon-key-rounded:before {
	content: "\E023";
}

.icon-key:before {
	content: "\E024";
}

.icon-lightcoin:before {
	content: "\E025";
}

.icon-lock:before {
	content: "\E026";
}

.icon-login:before {
	content: "\E027";
}

.icon-logo-default:before {
	content: "\E028";
}

.icon-logo:before {
	content: "\E029";
}

.icon-logout:before {
	content: "\E02A";
}

.icon-message:before {
	content: "\E02B";
}

.icon-multiswap:before {
	content: "\E02C";
}

.icon-obligation:before {
	content: "\E02D";
}

.icon-pay:before {
	content: "\E02E";
}

.icon-payment:before {
	content: "\E02F";
}

.icon-plus:before {
	content: "\E030";
}

.icon-profile:before {
	content: "\E031";
}

.icon-question-1:before {
	content: "\E032";
}

.icon-question:before {
	content: "\E033";
}

.icon-receipt:before {
	content: "\E034";
}

.icon-receive:before {
	content: "\E035";
}

.icon-robot:before {
	content: "\E036";
}

.icon-russian:before {
	content: "\E037";
}

.icon-save:before {
	content: "\E038";
}

.icon-search:before {
	content: "\E039";
}

.icon-shield:before {
	content: "\E03A";
}

.icon-simple:before {
	content: "\E03B";
}

.icon-simplicity:before {
	content: "\E03C";
}

.icon-success:before {
	content: "\E03D";
}

.icon-swap-default:before {
	content: "\E03E";
}

.icon-swap:before {
	content: "\E03F";
}

.icon-tether:before {
	content: "\E040";
}

.icon-time:before {
	content: "\E041";
}

.icon-transfer:before {
	content: "\E042";
}

.icon-union:before {
	content: "\E043";
}

.icon-user:before {
	content: "\E044";
}

.icon-webmoney:before {
	content: "\E045";
}

.icon-wmz:before {
	content: "\E046";
}
