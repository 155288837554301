.section {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0 auto;
    
    &__cover {
        display: flex;
        // height: 180px;
        width: 100%;
        min-height: 180px;

        .main--faq &,
        .main--authorization &, 
        .main--registration &,
        .main--settings &,
        .main--issue &,
        .main--obligation &,
        .main--e-currency &,
        .main--check & {
            background: url("../images/cover.jpg") no-repeat 0 0;
            background-size: cover;
            padding: 32px 0;
        }
        
        .main--receipts & {
            background: url("../images/receipts-cover.jpg") no-repeat 0 0;
            background-size: cover;
            padding: 32px 0;
        }
    }

    &--1 {
        .main--index & {
            padding-top: 150px;
            padding-bottom: 150px;
            // background-color: $bg-index;
            background: $linear-gradient-section-index, url("../images/index-bg.png") no-repeat;
            background-color: $bg-index;
            // background-image: $linear-gradient;
            background-position: 50% 100%;
            background-size: cover;
        }

        .main--business & {
            padding: 88px 0;
            background: $linear-gradient-section-business, url("../images/business-bg-1.png") no-repeat;
            background-color: $bg-business;
            border-bottom: 1px solid $secondary;
            background-size: cover;
            background-position: 100% 100%;
        }

        .main--receipts & {
            background: $primary url("../images/business-bg-1.png") no-repeat;
            padding-bottom: 16px;
            border-bottom: 1px solid $secondary;
            background-size: cover;
            background-position: -754px 0;
        }
    }

    &--2 {     
        .main--index & {
            padding-top: 64px;
            padding-bottom: 40px;
            margin-bottom: 40px;
        }

        .main--business & {
            padding-top: 80px;
            padding-bottom: 90px;
        }
        
    }

    &--separate {
        padding: 32px;
        overflow: hidden;
        background-image: $linear-gradient-section-separate;
        background-position: 100%;
        // margin-bottom: 80px;

        &::before, 
        &:after {
            @include pseudo-element();
            width: 200px;
            height: 100%;
        }

        &::before {
            left: -50px;
            top: -46px;
            background: url("../images/separate-block-bg-1.svg") no-repeat 50%;
            background-size: contain;
        }

        &::after {
            background: url("../images/separate-block-bg-2.svg") no-repeat 50%;
            background-size: contain;
            right: -50px;
            bottom: -46px;
        }
    }

    &--3 {
        padding-top: 80px;
        padding-bottom: 125px;
        // margin-bottom: 80px;
        // background: $linear-gradient-section-features;

        .main--index & {
            background: $linear-gradient-section-features;
        }

        .main--business & {
            background: url("../images/business-bg.png") no-repeat 50%;
            background-size: cover;
        }
    }

    &--4 {
        padding-top: 80px;
        padding-bottom: 80px;
        overflow: hidden;
        
        .main--index & {
            background-color: $bg-secondary;
        }
        
        .main--business & {
            row-gap: 64px;
        }
        
    }

    &__title {
        @include font(32px, 41px, 800);
        margin-bottom: 56px;
        
        .section--1 & {
            color: $primary;
            margin-bottom: 35px;
        }

        .main--index .section--2 &,
        .main--index .section--3 & {
            color: $primary;
            text-align: center;
        }

        .main--business .section--3 &,
        .main--index .section--4 & {
            color: $secondary;
        }

        // .main--index .section--4 & {
        //     margin-top: 70px;
        // }

        .main--business .section--3 & {
            text-align: center;
        }

        // .main--business & {
        //     text-align: left;
        // }

        .main--faq &,
        .main--operations & {
            display: block;
            margin: auto 0 auto;
        }

        .main--authorization .section__cover &,
        .main--registration .section__cover &,
        .main--settings .section__cover &,
        .main--authorization .section__content &,
        .main--issue .section__cover &,
        .main--issue .section__content &,
        .main--obligation .section__cover &,
        .main--obligation .section__content &,
        .main--e-currency .section__cover &,
        .main--e-currency .section__content &,
        .main--check .section__cover &,
        .main--check .section__content &    {
            @include font(26px, 31px, 700);
        }

        .main--authorization .section__cover &,
        .main--registration .section__cover &,
        .main--settings .section__cover &,
        .main--issue .section__cover &,
        .main--obligation .section__cover &,
        .main--e-currency .section__cover &,
        .main--check .section__cover &,
        .main--check .section__content & {
            margin: auto 0;
            max-width: 810px;
        }

        .main--authorization .section__content &,
        .main--issue .section__content & {
            margin-bottom: 8px;
        }

        .main--obligation .section__content &,
        .main--e-currency .section__content &,
        .main--check .section__content & {
            margin-bottom: 32px;
        }
    }

    &__link {
        &--button {
            @include font(20px, 26px, 500);
            display: block;
            width: max-content;
            padding: 14px 16px;
            background-color: $primary-button-default;
            color: $secondary;
            border-radius: 8px;
            transition: 0.2s ease-in-out;

            &:active {
                background-color: $primary-button-active;
            }

            &:visited {
                color: $secondary;
            }
        }

        &--inline {
            margin-right: 4px;
            text-decoration: underline;

            .main--index & {
                color: $primary;
            }

            .main--business & {
                color: $link;
            }
        }

        // .section--1 & {
        //     margin-left: 4px;
        // }

        .section__paragraph--small & {
            position: relative;
            padding-left: 32px;

            &::before {
                @include pseudo-element();
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                background: url("../images/icons/question-1.svg") no-repeat 50%;
                background-size: cover;
                width: 24px;
                height: 24px;
            }
        }
    }

    &__block {
        .main--index & {
            position: relative;
            // border: 1px solid $border;
            backdrop-filter: blur(12px);
            background-color: $bg-secondary;
            overflow: hidden;
            // box-shadow: $box-shadow;
            border-radius: 24px; 
            // background-image: $linear-gradient;
            position: relative;
            // margin: 80px 0 -242px 0;
            z-index: 2;
            // padding: 24px;
            margin-bottom: 64px;
        }

        .main--business .section--1 &,
        .main--business .section--4 &  {
            display: flex;
            flex-direction: column;
            row-gap: 16px;
            border-radius: 24px;
        }

        .main--business .section--1 & {
            margin: 40px auto 0 auto;
        }

        .main--business .section--4 & {
            background: $linear-gradient-section-business-2;
            position: relative;
            overflow: hidden;
            max-width: 1892px;
            margin: 0 auto;
            
            &::before,
            &::after {
                @include pseudo-element();
                width: 202px;
                height: 233px;
                background: url("../images/shape-4.svg") no-repeat 50%;
            }

            &::before {
                top: -130px;
                left: -60px;
            }

            &::after {
                top: 0px;
                left: -139px;
            }

            // backdrop-filter: blur(12px);
            // box-shadow: $box-shadow;
            // background-image: $linear-gradient-2;
            // border-radius: 24px; 
        }
    }

    &__row {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
    }

    &__paragraph {
        // margin-top: 24px;
        .section--1 & {
            margin-bottom: 40px;
        }

        .section--2 &,
        .section--3 & {
            margin-bottom: 40px;
        }

        .section--4 &  {
            color: $secondary;

            &:not(:last-child) {
                margin-bottom: 24px;
            }
        }

        .section--separate & {
            color: $secondary;
            max-width: 875px;
            margin: 0 auto;
            text-align: center;
        }

        .section__tile & {
            margin-top: 0;
            margin-bottom: 0;
            color: $primary;
        }

        .section__block & {
            position: relative;
            color: #ffffff;
            margin: 0;
            z-index: 2;
            

            &:first-of-type {
                margin-bottom: 24px;
            }
        }

        .main--authorization &, 
        .main--issue & {
            margin-bottom: 32px;
        }

        &--small {
            @include font(14px, 20px, 500);

            .section__block & {
                padding: 0 24px 24px 24px
            }
        }

        &--medium {
            @include font(18px, 24px, 400);

            .main--business .section--3 & {
                @include font(24px, 30px, 500);
                color: $secondary;
                text-align: center
            }
        }

        &--large {
            @include font(24px, 30px, 600);

            .section__block & {
                padding: 24px;
            }

            .main-index .section--3 & {
                margin-bottom: 80px;
            }
            
            
        }
    }

    &__tab {
        display: flex;
        align-items: center;
        padding: 24px;
        color: $primary;
        border-radius: 24px;
        cursor: pointer;
        // transition: 0.2s ease-in-out;

        .main--index & {
            @include font(24px, 32px, 500);
            justify-content: center;
            column-gap: 8px;
            background-color: $secondary;
            // border: 1px solid $border-3;

            &:nth-of-type(1) {
                margin-top: 32px;
                margin-bottom: 32px;
                color: $brand;
            }
        }

        .main--business & {
            @include font(20px, 25px, 400);
            background-color: $tabs;
            justify-content: flex-start;
            color: $primary;
            backdrop-filter: blur(12px);
            // transition: 0.2s ease-in-out;

            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }

        .main--authorization & {
            border-radius: 8px;
            padding: 10px;
            justify-content: center;
            border: 1px solid $border-4;
        }

        &:last-child {
            flex-wrap: wrap;
        }

        &:hover {
            // color: $secondary;
            // backdrop-filter: blur(12px);
            // background-color: $menu;
            // background-image: none;
        }
    }

    &__text {
        
        // text-align: center;
        // color: $primary;
        // .section__paragraph > & {
        //     margin: 24px 0;
        // }
        // .section__paragraph > & {
        //     display: block;
        //     @include font(32px, 41px, 800);
        //     color: $secondary;

        //     &:nth-of-type(2) {
        //         text-align: center;
        //         margin: 24px 0;
        //     }
        // }

        // .main--index .section__tab:nth-of-type(1) & {
        //     margin-right: 8px;
        //     color: $brand;
        // }

        .main--index .section__tab:nth-of-type(2) &,
        .main--authorization & {
            font-size: 22px;
            color: $primary;
            margin-bottom: 8px;
            width: 100%;
            text-align: center;
        }

        .main--business & {
            margin-left: 16px;
        }

        .section--4 & {
            font-size: 18px;
            font-weight: 700;
        }

        .section__tile & {
            display: block;
            @include font(18px, 24px, 600);
            color: $link;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        
        &--1 {
            .main--faq & {
                padding: 0 16px;
            }   
            
            .main--authorization &,
            .main--issue & {
                margin-top: 32px;
                
            } 

            .main--authorization & {
                padding-right: 14px;
            }

            .main--issue & {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-content: flex-start;
                padding-bottom: 32px;
                // padding-right: 0;
            } 
        }

        &--2,
        &--4,
        &--6 {
            .main--index & {
                position: relative;
                margin-bottom: 64px;
    
                &::before {
                    @include pseudo-element();
                    height: 100%;
                }
            }
        }

        &--2 {
            .main--index &::before {
                left: 0;
                background: url("../images/shape-1.svg") no-repeat 50%;
                background-size: contain;
                width: 100%;
            }

            .main--authorization &,
            .main--issue & {
                padding: 24px 14px;
                margin-top: 32px;
                border: 1px solid $border-4;
                border-radius: 24px;
            } 
        }

        &--4 {
            &::before {
                // left: -4px;
                // background: url("../images/shape-2.svg") no-repeat 100% 50%;
                right: 0;
                background: url("../images/shape-2.svg") no-repeat 0 50%;
                width: calc(100vh + 100%);
                background-size: 100% 75%;
            }
        }

        &--6 {
            &::before {
                left: -4px;
                background: url("../images/shape-3.svg") no-repeat 55% 50%;
                background-size: contain;
                width: 100%;
            }
        }

        &--7 {
            margin: 40px auto;
            background: linear-gradient(180deg, rgb(255, 255, 255) 20%, rgb(233, 236, 239) 68%);
            border-radius: 24px;
            overflow: hidden;
        }

        .main--index .section--4 & {
            position: relative;
            // overflow: hidden;
            // background: url("../images/shape-5.svg") no-repeat 50%,
            //             url("../images/shape-4.svg") no-repeat 50%;
            &::before,
            &::after {
                @include pseudo-element();
            }

            &::before {
                width: 278px;
                height: 321px;
                background: url("../images/shape-5.svg") no-repeat 50%;
                background-size: contain;
                right: -80px;
                bottom: 406px;
            }

            &::after {
                top: 50px;
                left: -139px;
                width: 406px;
                height: 469px;
                background:url("../images/shape-4.svg") no-repeat 50%;
                background-size: contain;
            }
        }

        .main--receipts & {
            // max-width: 452px;
            margin: 0 auto;
        }

        .section__block & {
            position: absolute;
            bottom: -42px;
            right: -3px;
            opacity: 0.6;
            // margin-top: 24px;
        }

        .main--obligation &:not(.section__cover &),
        .main--e-currency &:not(.section__cover &),
        .main--check &:not(.section__cover &) {
            margin-top: 32px;
            margin-bottom: 32px;
        }
    }

    &__image {
        width: 100%;
        margin: auto;
        z-index: 1;
        
        .main--index .section__block & {
            max-width: 392px;
        }

        .main--index .section--2 & {
            max-width: 321px;
        }

        // .section__features & {
        //     margin: 16px 0 40px;
        // }

        .section__wrapper & {
            max-width: none;
        }

        .section__container--7 & {
            margin: 0 0 0 auto;
        }
    }

    &__tile {
        position: relative;
        border-radius: 24px;
        padding: 24px;
        margin: 0 auto;
        margin-bottom: 24px;
        // box-shadow: $box-shadow;

        &--1, 
        &--2,
        &--3 {
            &::before {
                @include pseudo-element();
                left: 50%;
                transform: translateY(-50%);
                width: 4px;
            }

            &:first-of-type {
                &::before {
                    height: 24px;
                    bottom: -37px;
                }
            }
    
            &:last-of-type {
                &::before {
                    height: 80px;
                    bottom: -120px;
                }
            }
        }

        &--1 {
            border: 1px solid $tile-border-1;

            &::before {
                background-color: $tile-border-1;
            }
        }

        &--2 {
            border: 1px solid $tile-border-2;

            &::before {
                background-color: $tile-border-2;
            }
        }

        &--3 {
            border: 1px solid $tile-border-3;

            &::before {
                background-color: $tile-border-3;
            }
        }

        &--4 {
            border: 1px solid $tile-border-3;

            &::before {
                background-color: $tile-border-3;
            }
        }

        .main--index .section--2 & {
            width: 100%;
            max-width: 390px;
        }

        .main--index .section--2 &, 
        .main--index .section--3 & {
            background-color: $secondary;
        }

        .main--index .section--4 & {
            background-color: $bg-tertiary;
        }
    }

    &__heading {
        display: flex;
        align-items: center;
        column-gap: 20px;
        margin-bottom: 24px;

        .section--3 & {
            @include font(18px, 24px, 700);
        }

        .section--4 & {
            @include font(24px, 30px, 700);
        }

        .main--index .section--3 &,
        .main--business .section--2 & {
            color: $heading;
        }

        .main--index .section--4 & {
            color: $primary;
        }
        // &--colored {
        //     color: $link;
        // }
    }

    &__item {
        display: flex;
        align-items: center;
        margin-top: 56px;
    }

    &__inline {
        width: calc(100% - 64px);
        margin-left: 16px;
    }

    &__wrapper {
        display: flex;
        height: 100%;
    }

    &__content {
        .main--index & {
            margin: auto;
            width: 100%;
        }

        .main--business .section--4 & {
            // padding: 24px;
            padding: 40px 40px 0 40px;
        }

        .main--registration &,
        .main--authorization & {
            padding: 40px 0 80px 0;
            width: 100%;
        }

        .main--settings & {
            padding: 40px 0;
        }

        .main--issue & {
            width: 100%;
        }
    }
}

@include media-breakpoint-up(sm) { 
    .section {
        &__features {
            display: flex;
            flex-direction: column;
            row-gap: 40px;
        }

        &__paragraph {
            &--small {
                .section__block & {
                    font-size: 14px;
                    max-width: 330px;
                }
            }

            .main--index .section--2  & {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }
        }

        &__text {
            .main--index .section__block & {
                font-size: 31px;
                max-width: 282px;
                line-height: 42px;
            }

            .main--index .section__tab:nth-of-type(2) & {
                width: 100%;
            }
        }

        &__container {
            align-items: flex-start;

            &--1 {
                .main--authorization &,
                .main--issue & {
                    height: calc(100% - 64px);
                }

                .main--authorization & {
                    border-right: 1px solid $border-4;
                    padding-top: 24px;
                }
            }

            &--2,
            &--4,
            &--6 {
                .main--index & {
                    margin-bottom: 0;
                }
            }
            
            &--3 {
                order: 2;
            }

            &--7 {
                height: 100%;
            }

            .section__block & {
                opacity: 1;
                right: -158px;
                top: 11px;
            }

            .main--obligation &:not(.section__cover &),
            .main--e-currency &:not(.section__cover &),
            .main--check &:not(.section__cover &) {
                padding-top: 24px;
            }
        }

        &__tile {
            margin: auto;
            margin-bottom: 20px;
            height: calc(100% - 24px);

            &--1, 
            &--2, 
            &--3 {
                &::before {
                    left: auto;
                    top: 50%;
                    transform: translateY(-50%);
                    
                }

                &:first-of-type,
                &:last-of-type  {
                    &::before {
                        height: 4px;
                    }
                }
            }

            &--1,
            &--3  {
                &::before {
                    left: 100%;
                }
            }

            &--1,
            &--2  {
                &::before {
                    width: 210px;
                }
            }

            &--2 {
                &::before {
                    left: auto;
                    right: 100%;
                }
            }

            &--3 {
                &::before {
                    width: 190px;
                }
            }

            .main--index &:not(.section--3 &):not(.section--4 &) {
                max-width: 364px;
            }
        }

        &__image {
            .main--index .section__block & {
                max-height: none;
                max-width: 479px;
            }

            .section__features & {
                margin: auto;
                max-width: 366px;
            }
            
        }

        &__tab {
            width: 100%;
            // .main--index &:nth-of-type(1) {
            //     margin: 0;
            // }

            // .main--index &:nth-of-type(2) {
            //     width: calc(100% - 279px);
            // }
        }
    }
}

@include media-breakpoint-up(md) { 
    .section {
        &--1 {
            .main--business & {
                background-position: 100% 100%;
                background-size: contain;
            }

            .main--index & {
                background-position: 100%;
            }

            .main--issue & {
                padding-right: 14px;
            }
        }

        &--2 {
            .main--index & {
                padding-top: 120px;
                // padding-bottom: 96px;
                margin-bottom: 0;
            }

            // .main--business & {
            //     margin-bottom: 80px;
            // }
        }

        &--separate {
            padding: 85px;

            &::before, 
            &:after {
                @include pseudo-element();
                width: 500px;
                height: 500px;
            }

            &::before {
                left: -50px;
                top: auto;
                bottom: -135px;
            }

            &::after {
                top: -179px;
                right: -50px;
                bottom: auto;
            }
        }

        &--3 {
            // margin-bottom: 80px;
            padding-bottom: 80px;

            .main--business & {
                max-width: none;
            } 
        }

        &--4 {
            padding-top: 100px;
            
        }

        &--2,
        &__content {
            max-width: 1100px;
        }

        &__content {
            margin: 0 auto;
            width: 100%;

            .main--business .section--4 & {
                padding: 64px 64px 0 64px;
            }
        }

        // &__features {
        //     margin-top: 60px;
        // }

        &__tile {
            padding: 24px;
            max-width: 528px;
        }

        &__heading {
            position: relative;
            font-size: 24px;
            line-height: 30px;
            margin-bottom: 40px;

            .section--3 & {
                padding-left: 109px;
            }
        }

        &__title {
            
            // max-width: 298px;

            .main--index & {
                margin-top: 0;
            }
            
            // .section--2 &,
            // .section--3 &,
            // .section--4 & {
            //     text-align: left;
            // }

            .main--business .section--3 & {
                text-align: center;
                max-width: 772px;
                margin: 0 auto 48px auto;
            }
        }

        &__paragraph {
            .section--1 & {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: center;
            }

            .section--2 & {
                margin: 0;
            }

            .section--3 > & {
                @include font(32px, 40px, 600);
                margin-bottom: 160px;
            }

            &--small {
                font-size: 16px;
                line-height: 22px;
    
                .section__block & {
                    padding: 0 24px 24px 24px
                }
            }
            
            // &--large {
            //     @include font(24px, 32px, 500);
            // }
        }

        &__block {
            .main--index & {
                overflow: unset;
                // border-radius: 16px;
                // margin-bottom: 40px;
            }

            .main--business .section--3 & {
                // padding: 80px 0 0 140px;
                max-width: 1392px;
                // margin: 0 auto;
            }
        }

        &__container {
            .section__block & {
                z-index: 1;
                top: -74px;
                right: -3px;
            }

            .main--index .section--4 & {
                &::before {
                    right: -80px;
                    bottom: 279px;
                }
    
                &::after {
                    top: 50px;
                    left: -139px;
                }
            }

            .main--authorization & {
                &--1,
                &--2 {
                    padding: 40px;
                }
            } 

            .main--issue & {
                &--1 {
                    justify-content: space-between;
                    border-right: 1px solid $border-4;
                    padding-right: 14px;
                    padding-top: 24px;
                }
            }

            .main--obligation &:not(.section__cover &)
            .main--e-currency &:not(.section__cover &) {
                padding-top: 40px;
                padding-bottom: 40px;
            }
        }

        &__row {
            flex-direction: row;
        }

        &__text {
            
            &:nth-of-type(1) {
                width: 100%;
                text-align: left;
            }

            &:nth-of-type(2) {
                width: max-content;
                margin: 0 8px;
            }

            .section__tab & {
                width: max-content;
                margin: 0 0 0 16px;
            }

            .main--index .section__tab:nth-of-type(2) & {
                color: $primary;
                margin: 0;
                width: auto;
            }

            
        }

        &__tab {
            width: max-content;
            // padding: 12px 10px;

            .main--index &:nth-of-type(2) {
                width: max-content;
                flex-direction: row;
                flex-wrap: nowrap;
            }

            .main--business & {
                width: 100%;
            }
        }
    }
}

@include media-breakpoint-up(lg) { 
    .section {
        &--1 {
            .main--business & {
                
            }

            .main--index & {
                height: 100vh;
            }
        }

        &__title {
            .section--1 & {
                // @include font(42px, 124%, 800);
                max-width: 520px;
            }
        }

        &__tile {
            .section--3 & {
                padding: 32px 24px 32px 32px;
            }

            .main--index &:not(.section--3 &):not(.section--4 &) {
                max-width: none;
                width: 364px;
            }
        }

        &__heading {
            .main--index & {
                font-size: 26px;
                line-height: 32px;
            }
        }

        &__link {
            &--button {
                &:hover {
                    color: $secondary;
                    background-color: $primary-button-hover;
                }
    
                &:active {
                    color: $primary;
                    background-color: $primary-button-active;
                }
            }
        }

        &__paragraph {
            .section--1 & {
                justify-content: flex-start;
            }

            &--small {
                font-size: 20px;
                line-height: 25px;

                .section__block & {
                    padding: 24px 0 70px 115px;
                    max-width: 356px;
                }
                
            }

            &--large {
                .section__block & {
                    padding: 48px 0 0 115px;
                }
            }
        }

        &__tab {
            padding: 24px;
        }

        &__container {
            // &--4 {
            //     &::before {
            //         right: -5px;
            //     }
            // }

            .main--index .section--4 & {
                &::before {
                    right: -162px;
                    bottom: 380px;
                }
    
                &::after {
                    top: -50px;
                    left: -217px;
                }
            }
            
            .section__block & {
                top: -53px;
                
            }

            .main--authorization & {
                &--1 {
                    margin-top: 32px;
                    padding: 40px 40px 0 0;
                    height: 100%;
                }
            } 

            .main--issue & {
                &--1 {
                    padding-right: 40px;
                    padding-top: 40px;
                }

                &--2 {
                    padding: 40px;
                }
            }
        }

        &__text {

            .main--index .section__block & {
                font-size: 42px;
                max-width: 386px;
                line-height: 51px;
            }

            .section--4 & {
                display: block;
                margin-top: 56px;
                font-size: 30px;
                line-height: 38px;
            }

            &:nth-of-type(2) {
                margin: 0 24px;
            }
        }

        &__content {
            .main--business .section--4 & {
                padding: 64px 0 64px 64px;
            }

            .main--settings & {
                height: 100vh;
            }

            .main--obligation &,
            .main--e-currency & {
                padding-bottom: 230px;
            }

            // .main--authorization & {
            //     padding-bottom: 32px;
            // }
        }

        &__image {
            .section__wrapper & {
                margin: auto 0 0 0;
            }
                
            .main--index .section__block & {
                max-height: none;
                max-width: 561px;
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .section {
        &__title {
            font-size: 56px;
            line-height: 72px;
        }

        &__content {
            .main--business .section--4 & {
                padding: 56px 0 56px 132px;
            }
        }

        &__heading {
            .main--index & {
                font-size: 30px;
                line-height: 36px;
            }
        }

        &__image {
            .section__block & {
                max-width: none;
            }
        }

        &__container {
            .main--index .section--4 & {
                &::after {
                    top: -50px;
                    left: -510px;
                }
            }
        }

        // &__text {
        //     .main--index .section__block & {
        //         font-size: 56px;
        //         line-height: 70px;
        //     }
        // }
    }
}
// @include media-breakpoint-up(xlg) { 
//     .section {
//         &--1 {
//             .main--business & {
//                 background-size: cover;
//             }
//         }
//     }
// }



// .tabs {
// 	display: flex;
// 	flex-direction: column;

// 	>#content-1 {
// 		&:target~.tabs__links {
// 			>a[href="#content-1"] {
//                 background: none;
//                 color: $heading-2;
//                 border-top: 2px solid $border-2;
// 				cursor: default;
// 			}
// 		}
// 	}
// 	>#content-2 {
// 		&:target~.tabs__links {
// 			>a[href="#content-2"] {
// 				background: none;
//                 color: $heading-2;
//                 border-top: 2px solid $border-2;
// 				cursor: default;
// 			}
// 		}
// 	}
// 	>#content-3 {
// 		&:target~.tabs__links {
// 			>a[href="#content-3"] {
// 				background: none;
//                 color: $heading-2;
//                 border-top: 2px solid $border-2;
// 				cursor: default;
// 			}
// 		}
// 	}
//     >#content-4 {
// 		&:target~.tabs__links {
// 			>a[href="#content-4"] {
// 				background: none;
//                 color: $heading-2;
//                 border-top: 2px solid $border-2;
// 				cursor: default;
// 			}
// 		}
// 	}
//     >#content-5 {
// 		&:target~.tabs__links {
// 			>a[href="#content-5"] {
// 				background: none;
//                 color: $heading-2;
//                 border-top: 2px solid $border-2;
// 				cursor: default;
// 			}
// 		}
// 	}
// 	>div {
// 		&:not(.tabs__links) {
// 			display: none;
// 			order: 1;
// 		}
// 		&:target {
// 			display: block;
// 		}
// 	}
// }

// .tabs__links {
// 	display: flex;
// 	width: 100%;
// 	overflow-x: auto;
// 	overflow-y: hidden;
// 	margin-left: auto;
// 	margin-right: auto;
// 	margin-bottom: 40px;
// 	order: 0;
// 	white-space: nowrap;
//     scrollbar-width: none;
//     border-bottom: 2px solid $separator;

//     &::-webkit-scrollbar {
//         width: 0;
//         height: 0;
//     }

	
//     >a {
//         @include font(20px, 26px, 500);
// 		display: inline-block;
// 		text-decoration: none;
// 		padding: 20px 12px;
// 		text-align: center;
//         color: $primary;
//         border-top: 2px solid $white;
//         transition: 0.2s ease-in-out;

// 		&:hover {
// 			background-color: rgba(227, 242, 253, 0.3);
// 		}
// 	}
// }
