.receipt {
    position: relative;
    width: 97%;
    // height: 732px;
    height: auto;
    // min-height: 732px;
    max-width: 452px;
    padding: 32px;
    box-sizing: border-box;
    // margin: -80px auto 120px auto;
    // background-color: $secondary;
    border-radius: 24px 24px 0 0;
    background: $secondary url("../images/receipt-bg.png") no-repeat 50% 50%;
    background-size: 100% 100%;
    z-index: 2;

    &::before {
        @include pseudo-element();
        bottom: -18px;
        left: 0;
        width: 100%;
        height: 20px;
        background: url("../images/side.png") no-repeat 50% 0;
        background-size: contain;
        z-index: 1;
    }

    &--operation {
        padding-top: 64px;
    }

    .main--index & {
        pointer-events: none;
        min-height: 663px;
        margin: 64px auto;
    }

    .main--receipts & {
        margin: -130px auto 19px auto;
    }

    &__operation {
        @include font(18px, 22px,600);
        color: $secondary;
        background-color: $heading;
        position: absolute;
        top: -14px;
        left: 50%;
        transform: translateX(-50%);
        padding: 10px 30px;
        text-align: center;
        border-radius: 4px;
    }

    &__title {
        @include font(22px, 26px, 700);
        text-align: center;
        margin-top: 32px;
        margin-bottom: 0;
    }

    &__container {
        position: relative;
        margin-top: 45px;
        
        &:not(:first-of-type) {
            padding-top: 40px;

            &::before {
                @include pseudo-element();
                top: -15px;
                left: 50%;
                transform: translateX(-50%);
                width: calc(100% + 78px);
                height: 30px;
                background: url("../images/line.svg") no-repeat 50% 50%;
                background-size: contain;
                z-index: 1;
            }
        }
    }

    &__label {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        flex-grow: 1;
        @include font(14px, 20px, 700);
        color: $label;
        text-align: center;
        margin-top: 8px;
        margin-bottom: 24px;

        // .receipt__container & {
        //     &:last-child {
        //         margin-bottom: 0;
        //     }
        // }

        &--in-line {
            @include font(12px, 20px, 500);
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
            margin-top: 24px;
            
            .receipt & {
                color: $primary;
            }
        }
    }

    &__link {
        @include font(20px, 24px, 600);
        display: flex;
        flex-direction: column;
        color: $primary-button-default;
        margin: 8px auto 0 auto;
        transition: 0.2s ease-in-out;

        // &--warning {
        //     position: relative;

        //     &::after {
        //         @include font(12px, 24px, 500);
        //         @include pseudo-element(block, absolute, "Не обеспечен");
        //         left: 105%;
        //         top: 50%;
        //         transform: translateY(-50%);
        //     }
        // }

        &:visited,
        &:active {
            color: $primary-button-default;
        }

        &:hover {
            color: $primary-button-hover;
        }
    }

    &__status {
        .receipt__link & {
            @include font(14px, 24px, 500);
            white-space: nowrap;
            color: $error;
            background-color: $error-bg;
            border-radius: 4px;
            padding: 0 6px;
            margin-top: 8px;
        }
    }

    &__field {
        position: relative;
        display: flex;
        margin-top: 8px;
        // color: $label;
    }

    // &__show {
    //     position: absolute;
    //     top: 50%;
    //     right: 20px;
    //     transform: translateY(-50%);
    //     transition: 0.1s ease-in-out;
    //     cursor: pointer;
    //     background: none;
    //     outline: none;
    //     border: none;
    //     color: $label;
    //     padding: 0;

    //     &:active {
    //         color: $receipt-button;
    //         opacity: 0.6;
    //     }
    // }

    &__balance {
        @include font(56px, 67px, 600);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        // width: max-content;
        margin: 0 auto;
        text-align: center;
        gap: 8px;
        padding-bottom: 24px;
        margin-top: 24px;
        margin-bottom: 40px;

        &::after {
            @include pseudo-element();
            bottom: -15px;
            width: calc(100% + 90px);
            height: 30px;
        }

        .main--receipts & {
            &::after {
                background: url("../images/line.svg") no-repeat 50% 50%;
                background-size: contain;
            }
        }

        .main--index & {
            &::after {
                background: url("../images/line-index.svg") no-repeat 50% 50%;
                background-size: contain;
            }
        }
    }

    &__item {
        
    }

    &__date,
    &__transaction,
    &__sum {
        @include font(14px, 20px, 400);
    }

    &__date {
        font-weight: 700;
        color: $receipt-button;
    }

    &__table {
        margin-top: 24px;
        width: 100%;

        &--details {
            display: none;
            padding: 20px;
            background-color: $receipt-table;
            border-radius: 8px;
        }

        &--show {
            display: block;
        }
    }

    &__table-body {
        display: block;
        // width: 100%;
    }

    &__row {
        display: flex;
        justify-content: space-between;

        .receipt__cell--details & {
            &:not(:last-of-type) {
                margin-bottom: 20px;
            }
        }
    }

    &__cell {
        &--details {
            display: block;
            width: calc(100% + 39px);
        }

        &:nth-of-type(2) {
            text-align: right;
        }
    }

    &__sum {
        font-weight: 500;
    }

    &__count {
        
        // width: 100%;
        
        // word-break: break-all;
    }

    &__currency {
        display: block;
        @include font(22px, 26px, 500);
        width: 60px;
    }

    &__actions {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        row-gap: 8px;
        margin-top: 24px;
    }

    &__button {
        color: $receipt-button;
        background: none;
        outline: none;
        border: none;
        padding: 0;

        &--bordered {
            padding: 8px 16px;
            border-radius: 8px;
            border: 1px solid $receipt-button;
        }

        .receipt__table & {
            @include font(12px, 15px, 400);
            text-decoration: underline;
        }

        .receipt__actions & {
            @include font(14px, 16px, 500);
            display: flex;
            align-items: center;
            transition: 0.2s ease-in-out;

            &:not(:last-child) {
                margin-right: 20px;
            }

            &:active {
                opacity: 0.7;
            }     
        }
    }

    &__note {
        @include font(14px, 20px, 700);
        text-align: center;
        padding: 0 16px;
        margin-bottom: 24px;
    }

    &__textarea {
        @include font(20px, 25px, 500);
        background-color: $input-bg;
        border-radius: 8px;
        border: 2px solid $input-bg;
        transition: 0.2s ease-in-out;
        outline: none;
        width: 100%;
        resize: none;
        padding: 16px;
        margin-top: 8px;
        overflow: hidden;
        
        &:focus {
            border: 2px solid $border-2;
        }
    }

    &__value {
        @include font(20px, 24px, 600);
        color: $tertiary;
        margin-top: 8px;

        .receipt__field & {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            line-height: 36px;
            color: $tertiary;
            // background-color: $input-bg;
            // border-radius: 8px;
            padding: 16px;
            margin-left: 8px;
            margin-top: 0;
        }

        .receipt__container--invalid & {
            color: $label;
        }
    }

    &__wrapper {
        display: flex;
        flex-wrap: nowrap;
    }
}

@include media-breakpoint-up(sm) { 
    .receipt {  
        width: 100%;
        
        &__label {
            font-size: 16px;
            
            &--in-line {
                font-size: 14px;
            }
        }

        &__link {
            position: relative;
        }

        &__status {
            .receipt__link & {
                position: absolute;
                left: 103%;
                top: 50%;
                transform: translateY(-50%);
                margin-top: 0;
            }
        }
    }
}

@include media-breakpoint-up(md) { 
    .receipt {  
        .main--index & {
            margin: 0 auto 89px auto;
        }
    }
}


// @include media-breakpoint-up(sm) { 
//     .receipt {
//         box-sizing: content-box;
//     }
// }

@include media-breakpoint-up(lg) { 
    .receipt {  
        width: 452px;

        &__button {
            &:hover {
                color: darken($receipt-button, 10%);
            }

            &:active {
                opacity: 0.7;
            } 
        }
        // &__show {
        //     &:hover {
        //         color: $receipt-button;
        //     }
        // }
    }
}