.notification {
    position: relative;
    border-radius: 8px;
    width: 100%;
    text-align: left;
    // width: max-content;
    // margin: auto;
    
    .receipt > & {
        margin: 24px auto;
    } 

    .form + &,
    .section__container + & {
        margin-top: 32px;
    }

    &--default {
        font-weight: 400;
        padding: 16px 24px;
        background-color: $notification-bg;
        color: $tertiary;

        .form__title + & {
            margin: 0 0 32px 0;
        }

        .main--receipts &,
        .main--authorization &,
        .main--registration &  {
            margin: 32px 0;
        }

        .main--e-currency &,
        .main--obligation & {
            margin: 16px 0;
        }

        .main--issue & {
            margin-top: 32px;
        }
    }

    &--info {
        padding: 16px 16px 16px 48px;

        &::before {
            @include pseudo-element();
            left: 16px;
            top: 18px;
            background: url("../images/icons/info-2.svg") no-repeat 50%;
            width: 20px;
            height: 20px;
            background-size: contain;
        }
    }

    &--error {
        padding: 16px 16px 16px 48px;
        background-color: $error-bg;
        color: $error-active;

        .receipt & {
            margin-top: 8px;
        }

        .main--e-currency & {
            margin: 16px 0;
        }

        .main--check & {
            margin-bottom: 24px;
        }

        &::before {
            @include pseudo-element();
            left: 16px;
            top: 16px;
            background: url("../images/icons/info-error.svg") no-repeat 50%;
            width: 20px;
            height: 20px;
            background-size: contain;
        }
    }

    &--mark {
        color: $mark;
        font-weight: 500;

        .receipt__container & {
            margin-bottom: 12px;
        }

        .receipt & {
            margin-top: 8px;
        }

        .user & {
            user-select: none;
            cursor: auto;
        }
    }

    &--warning {
        font-weight: 400;
        padding: 16px 24px 16px 48px;
        background-color: $warning-bg;
        color: $warning;

        .main--e-currency &,
        .receipt & {
            margin: 16px 0;
        }

        &::before {
            @include pseudo-element();
            left: 16px;
            top: 16px;
            background: url("../images/icons/info-warning.svg") no-repeat 50%;
            width: 20px;
            height: 20px;
            background-size: contain;
        }
    }

    &--simple {
        .main--e-currency &  {
            margin-bottom: 32px;
        }
    }

    &__text {
        display: block;
        @include font(16px, 20px, 500);

        .notification--error & {
            text-align: left;
            font-weight: 800;
            max-width: 270px;
        } 

        .notification--mark & {
            font-style: italic;

            .receipt__container & {
                display: block;
                text-align: center;
                font-weight: 600;
            }
        } 

        .notification--simple & {
            .main--e-currency & {
                text-align: left;
            }

            .main--receipts & {
                text-align: center;
            }
        }
    }

    &__value {
        font-weight: 700;
    }

    &__link {
        color: $link;

        .main--registration &,
        .main--e-currency &  {
            color: $primary;
            font-weight: 700;
            text-decoration: underline;
        }

        .notification--warning & {
            color: $warning;
            font-weight: 700;
            text-decoration: underline;
            margin: 0 4px;
        }
    }

    &__item {
        ul.notification__list & {
            list-style: disc;
        }

        ol.notification__list & {
            list-style: decimal;
        }
        
        margin-top: 16px;
        margin-left: 16px;

        &:last-child {
            margin-bottom: 16px;
        }
    }
}

@include media-breakpoint-up(xl) {
    .notification {
        max-width: 330px;
        
        &--simple {
            .main--receipts & {
                text-align: center;
                padding: 0 16px;
            }
        }

        &--default {
            margin: 0;
            // &:not(.main--authorization &):not(.main--registration &):not(.main--issue &):not(.main--obligation &):not(.main--e-currency &) {
            .main--receipts & {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                // bottom: 34px;
                width: 80%;
            }
            
            .receipt > & {
                left: 102%;
            } 

            .receipt__label > & {
                left: 113%;
            }
        }

        &--info {
            .button + & {
                position: relative;
                margin-top: 24px;
            }
        }

        &--error {
            .main--receipts & {
                position: absolute;
                top: 31px;
                left: 112%;
            }
        }

        &--mark {
            .main--receipts & { 
                position: static;
            }
        }

        &--warning {
            
            .main--receipts & {
                position: absolute;
                top: 0;
                left: 112%;
                width: max-content;
                margin: 0;
            }

            // &:not(.main--authorization &):not(.main--issue &) {
            //     position: absolute;
            //     top: 0;
            //     left: 112%;
            // }
        }

        &__link {
            cursor: pointer;

            &:hover,
            &:active {
                color: $link;
                text-decoration: underline;

                .main--registration & {
                    color: $primary;
                }
            }
        }
    }
}

